import React from "react";
import {isSuperSet, parseDate} from "../util/FormatUtil";
import Select from "react-select";
interface AppointmentProps{
    schedMap:any[]
    onChange:(e) => void
    passClearStateFunc?
    serviceLabels
}

interface AppointmentState{
    location:{
        // service?:{label:string, value:number}
        service?:{label:string, value:number}[]
        day?: {label:string, value:{day:string, times:{remaining:number, time:string}[], schedID:number}};
        facility:{label:string, value:{
                FacilityName?: string
                Address?:string
                days?:{day:string}[]}}
        ,
        time?:{label:string, value:any}
    }
    schedule
    services
}
export class AppointmentForm extends React.Component<AppointmentProps, AppointmentState>{

    constructor(props) {
        super(props);
        this.state = {
            location:{
                facility:null,
                service:null
            },
            schedule:[],
            services:[]
        }
        this.clearState = this.clearState.bind(this);
    }


    shouldComponentUpdate(nextProps: Readonly<AppointmentProps>, nextState: Readonly<AppointmentState>, nextContext: any): boolean {
        if(this.props.passClearStateFunc) {
            this.props.passClearStateFunc(this.clearState);
        }
        return true;
    }

    updateParent(){
        this.props.onChange(JSON.parse(JSON.stringify(this.state.location)))
    }

    componentDidMount(): void {
        document.title = 'Appointment Form';
    }

    clearState = () =>{
        this.setState({location:{facility:null, service:null, day:null, time:null}})
    }


    render() {

        let schedMap = this.props.schedMap;
        let facilities = [];
        let days = [];
        let times = [];

        let mapValues = schedMap ? Object.values(schedMap) : [];

        let selectedServiceIDs = [];
        this.state.location.service && this.state.location.service.map(f => {
            return selectedServiceIDs.push(f.value)
        })

        mapValues = mapValues.sort((a:any,b:any) => a.FacilityName ?  a.FacilityName.localeCompare(b.FacilityName) : 0);


        if(schedMap && this.state.location.service){
            facilities = mapValues.map(v => ({label:v.FacilityName + " - " + v.Address, value:v}));
            
            let temp = [];
            
            //getting facilites that have pods that off services that are included in selectServiceIDs
            facilities.map(f => {
                let included = false
                for(let i = 0; i<f.value.days.length; i++){
                    if(f.value.days[i].services){
                        let podServices = JSON.parse(f.value.days[i].services)
                        
                        if(podServices.length > 0 ){
                            included = isSuperSet(new Set(podServices), new Set(selectedServiceIDs))
                            if(included){
                                break
                            }
                        }
                    }
                }
                if(included){
                    temp.push(f)
                }
            })
            
            facilities = temp;
            
        }



        // below get days from selected facilities
        if(this.state.location.facility &&  this.state.location.facility.value && this.state.location.facility.value.days){
            days = this.state.location.facility.value.days.map(d => ({label:parseDate(d.day), value:d}));

            let temp = [];

            //return only days that selectedServiceIDs are included in that day
            days.map(d => {
                let included = false
                if(d.value.services){
                    let dayServices = JSON.parse(d.value.services)
                    if(dayServices.length > 0){
                        included = isSuperSet(new Set(dayServices), new Set(selectedServiceIDs))
                        if(included){
                            temp.push(d)
                        }
                    }
                }
            })

            days = temp
        }

        let timeMessage = "No option";

        //get times for chosen day
        if(this.state.location.day){
            times = this.state.location.day.value.times.map(t => ({label: t.time + " " + t.remaining + " slots remaining", value:t.time}));
            if(times.length === 0){
                timeMessage = "No more appointments are available.";
            }
        }

        return(
            <main id="main-content" tabIndex={-1} aria-label="Appointment Form">
                <div className="card mb-2">
                    <div className="card-header verlag-bold">
                        <h4>Appointment</h4>
                    </div>
                    <div className="card-body">
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">Service</label>
                            <div className="col-sm-8  p-0 m-0">

                                <Select
                                    isMulti={true}
                                    isSearchable={ true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={()=>  "No option"}
                                    className={"state_select"}
                                    options={this.props.serviceLabels}
                                    onChange={(e) => {this.setState({location:{service: e as any, facility:null, time:null, day:null}}, () => this.updateParent())}}
                                    value={this.state.location.service as any}
                                    aria-label="Service"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">Site Location</label>
                            <div className="col-sm-8  p-0 m-0">
                                <Select
                                    isSearchable={ true}
                                    isDisabled={!(this.state.location && this.state.location.service)}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={()=>  "No option"}
                                    className={"state_select"}
                                    options={facilities}
                                    onChange={(e) => this.setState({location:{service: this.state.location.service, facility:e, time:null, day:null}}, () => this.updateParent())}
                                    value={this.state.location.facility}
                                    aria-label="Site Location"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">Day</label>
                            <div className="col-sm-8  p-0 m-0">

                                <Select
                                    isSearchable={ true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    isDisabled={!(this.state.location && this.state.location.facility)}
                                    noOptionsMessage={()=>  "No option"}
                                    className={"state_select"}
                                    options={days}
                                    onChange={(e) => this.setState({location:{service:this.state.location.service, facility:this.state.location.facility, day:e, time:null}}, () => this.updateParent())}
                                    value={this.state.location.day}
                                    aria-label="Day"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">Time</label>
                            <div className="col-sm-8  p-0 m-0">

                                <Select
                                    isSearchable={ true}
                                    isDisabled={!(this.state.location && this.state.location.day)}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={()=>  timeMessage}
                                    className={"state_select"}
                                    options={times}
                                    onChange={(e) => this.setState({location:{service:this.state.location.service, facility:this.state.location.facility, day:this.state.location.day, time:e}}, () => this.updateParent())}
                                    value={this.state.location.time}
                                    aria-label="Time"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}