import React from "react";
import ModalMessage from "./ModalMessage";


class ConfirmationMessage extends React.Component<{
    email: string
    fname: string
    lname: string
    DOB: string
    phone: string
    onSubmit: any
    alertMessage?: string
}, any> {

    public static readonly ID = "confirmation"

    getMessage() {
        return (<React.Fragment>
            <div className="responsive-p ">
                <h5>This is not a appointment. You must confirm the following information before your appointment is
                    made:</h5>
                <p>First Name: {this.props.fname}</p>
                <p>Last Name: {this.props.lname}</p>
                <p>Date of Birth: {this.props.DOB}</p>
                <p>Email: {this.props.email}</p>
                <p>Phone Number: {this.props.phone}</p>
                <h5>
                    <b>{this.props.alertMessage ? this.props.alertMessage : "Incorrect information, typos, and misspellings will delay check-in."}</b>
                </h5>
            </div>

        </React.Fragment>)
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<ModalMessage cancelButton={true} title={"Appointment Confirmation"} ID={ConfirmationMessage.ID}
                              buttonText={"Submit Appointment"} message={this.getMessage()}
                              onSubmit={this.props.onSubmit}/>)
    }

}

export default ConfirmationMessage;

