import React from "react";
import { calculateAge } from "../../util/ValidationUtil";
import { sweetalert } from "../../App";
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import Overlay from "../Overlay";
import { hideModal, buildRow, getLabel} from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Select from "react-select";
import { FilterPart } from "../FilterCard";
import PhoneInput from 'react-phone-number-input';
import PatientAPI from "../../network/PatientAPI";
import { isEmptyObject } from "jquery";
import { AuthContext } from "../../context/AuthContext";

interface FamilyGroupModalProps {
    submit: (e) => void,
    attachClearStateFunc?: (func) => void
    attachClearStateFuncGuardian?: (func) => void
    states: any
    gender: any
    ethnicity: any
    race: any
    countries: any
    selectedMember
}

interface FamilyGroupModalState {
    showLoading: boolean
    showInBetween: boolean
    memberInfo?,
    selectedMember: {
        firstName
        middleName
        lastName
        dateOfBirth
        email
        confirmEmail
        genderID
        ethnicityID
        raceID
        phoneNumber
        streetAddress
        streetAddress2
        city
        state
        zipcode
        county
        country
        guardianFirstName
        guardianLastName
        customerID
        FamilyGroupID
        FamilyCanView
        FacilityIDs
        uid
    }
}
export class FamilyGroupModal extends React.Component<FamilyGroupModalProps, FamilyGroupModalState>{
    public static ID = "familygroup";
    static contextType = AuthContext;
    static UserContext;

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedMember: {
                firstName: "",
                middleName: "",
                lastName: "",
                dateOfBirth: "",
                email: "",
                confirmEmail: "",
                genderID: "",
                ethnicityID: "",
                raceID: "",
                phoneNumber: "",
                streetAddress: "",
                streetAddress2: "",
                city: "",
                state: null,
                zipcode: "",
                county: "",
                country: "",
                guardianFirstName:"",
                guardianLastName:"",
                customerID: null,
                FacilityIDs: null,
                FamilyGroupID: null,
                FamilyCanView: false,
                uid: -1,
            }
        }
        this.assignClearState = this.assignClearState.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    handleSave() {
        this.setState({ showLoading: true }, async () => {
            const getProfileByUID = await PatientAPI.getUserProfileByUID(FamilyGroupModal.UserContext.uid)
            const userProfile = getProfileByUID.data;

            let tempDate = this.state.memberInfo.dateOfBirth ? this.state.memberInfo.dateOfBirth : null;

            let memberInfoCopy = JSON.parse(JSON.stringify(this.state.memberInfo))
            memberInfoCopy.firstName = memberInfoCopy.firstName ? memberInfoCopy.firstName.trim() : null
            memberInfoCopy.middleName = memberInfoCopy.middleName ? memberInfoCopy.middleName.trim() : null
            memberInfoCopy.lastName = memberInfoCopy.lastName ? memberInfoCopy.lastName.trim() : null
            memberInfoCopy.email = memberInfoCopy.email ? memberInfoCopy.email.trim() : null
            memberInfoCopy.dateOfBirth = tempDate ? tempDate : null
            memberInfoCopy['confirmEmail'] = memberInfoCopy['confirmEmail'] ? memberInfoCopy['confirmEmail'].trim() : null
            memberInfoCopy.streetAddress = memberInfoCopy.streetAddress ? memberInfoCopy.streetAddress.trim() : null
            memberInfoCopy.streetAddress2 = memberInfoCopy.streeetAddress2 ? memberInfoCopy.streetAddress2.trim() : null
            memberInfoCopy.city = memberInfoCopy.city ? memberInfoCopy.city.trim() : null
            memberInfoCopy.zipcode = memberInfoCopy.zipcode ? memberInfoCopy.zipcode.trim() : null
            memberInfoCopy.county = memberInfoCopy.county? memberInfoCopy.county.trim() : null
            memberInfoCopy.phoneNumber = memberInfoCopy.phoneNumber ? memberInfoCopy.phoneNumber.trim() : null

            // if member is < 18, guardianFirstName & guardianLastName will be the first/last of UserContext
            // we are no longer displaying the fields on the form
            memberInfoCopy.guardianFirstName = calculateAge(tempDate) < 18 ? userProfile[0].firstName.trim() : ""
            memberInfoCopy.guardianLastName = calculateAge(tempDate) < 18 ? userProfile[0].lastName.trim() : ""

            // this.setState({ memberInfo: memberInfoCopy })

            //validate patient info
            if (!FamilyGroupModal.validatePatientProfile(memberInfoCopy)) {
                this.setState({ showLoading: false })
                return
            }

            // pass back to parent -- if parent is to handle the logic of adding member to family maybe?
            this.props.submit({ memberInfo: memberInfoCopy });

            hideModal(FamilyGroupModal.ID);// close modal
            this.setState({ showLoading: false })
        });
    }

    public static validatePatientProfile(s): boolean {
        let patientValidation = {
            firstName: s.firstName,
            middleName: s.middleName,
            lastName: s.lastName,
            dateOfBirth: s.dateOfBirth,
            email: s.email,
            confirmEmail: s['confirmEmail'],
            genderID: s.genderID,
            streetAddress: s.streetAddress,
            streetAddress2: s.streetAddress2,
            city: s.city,
            zipcode: s.zipcode,
            state: s.state,
            country: s.country,
            county: s.county,
            phoneNumber: s.phoneNumber,
            ethnicityID: s.ethnicityID,
            raceID: s.raceID,
            guardianFirstName: s.guardianFirstName,
            guardianLastName: s.guardianLastName
        }

        let validator = new Validator<any>()
            .withValidation("firstName", Validators.requireNotBlankAndLength(50, "First Name"))
        if (s.middleName && s.middleName.trim().length > 0) {
            validator = validator.withValidation("middleName", Validators.requireNotBlankAndLength(50, "Middle Name"))
        }
        if (calculateAge(s.dateOfBirth) < 18) {
            validator = validator.withValidation("guardianFirstName", Validators.requireNotBlankAndLength(50, "Guardian First Name")).withValidation("guardianLastName", Validators.requireNotBlankAndLength(50, "Guardian Last Name"))
        }
        validator = validator.withValidation("lastName", Validators.requireNotBlankAndLength(50, "Last Name"))
            .withValidation("dateOfBirth", Validators.requireDOB(150, "Date of Birth"))
            .withComposedValidation("email", new ValidationEntry(Validators.requireValidEmail("Email")), Validators.requireNotBlankAndLength(100, "Email"), new ValidationEntry<any>((key, attr, obj) => {
                if (obj.email !== s['confirmEmail']) {
                    return { success: false, error: "Email and Confirmation Email do not match" };
                }
                return { success: true, error: null }
            }))
            .withSimpleValidation("genderID", Validators.requireNonNullValidator("Gender"))
            .withValidation("streetAddress", Validators.requireNotBlankAndLength(500, "Address"))
        if (s.PatientAddres2) {
            validator = validator.withValidation("streetAddress2", Validators.requireNotBlankAndLength(500, "Address2"))
        }
        validator = validator.withValidation("city", Validators.requireNotBlankAndLength(100, "City"))
            .withComposedValidation("zipcode", new ValidationEntry(Validators.requireNotBlankValidator("Zipcode")), new ValidationEntry(Validators.requireZip("Zipcode")))
            .withSimpleValidation("state", Validators.requireNonNullValidator("State"))
            .withSimpleValidation("country", Validators.requireNonNullValidator("Country"))
            .withValidation("county", Validators.requireNotBlankAndLength(100, "County"))
            .withComposedValidation("phoneNumber", new ValidationEntry(Validators.requireNonNullValidator("Phone")), new ValidationEntry(Validators.requirePhone("Phone")))
            .withSimpleValidation("ethnicityID", Validators.requireNonNullValidator("Ethnicity"))
            .withSimpleValidation("raceID", Validators.requireNonNullValidator("Race"))
        let validationResponse = validator.validate(patientValidation);
        if (!validationResponse.success) {
            sweetalert.fire({ icon: 'error', title: '', text: validationResponse.error });
            return false
        }

        return true;
    }

    clearFilterState: () => void = null;

    assignClearState(func) {
        this.clearFilterState = func;
    }

    clearState = () => {
        this.clearFilterState();
    }

    componentDidMount(): void {
        $(`#${FamilyGroupModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    componentWillReceiveProps(nextProps: Readonly<FamilyGroupModalProps>, nextContext: any): void {
        FamilyGroupModal.UserContext = this.context;

        if(!isEmptyObject(nextProps.selectedMember)){

            this.setState({
                memberInfo: {
                    firstName: nextProps.selectedMember.firstName ? nextProps.selectedMember.firstName : "",
                    middleName: nextProps.selectedMember.middleName ? nextProps.selectedMember.middleName : "",
                    lastName: nextProps.selectedMember.lastName ? nextProps.selectedMember.lastName : "",
                    dateOfBirth: nextProps.selectedMember.dateOfBirth ? nextProps.selectedMember.dateOfBirth : "",
                    email: nextProps.selectedMember.email ? nextProps.selectedMember.email : "",
                    confirmEmail: nextProps.selectedMember.email ? nextProps.selectedMember.email : "",
                    genderID: nextProps.selectedMember.genderID ? nextProps.selectedMember.genderID : null,
                    ethnicityID: nextProps.selectedMember.ethnicityID ? nextProps.selectedMember.ethnicityID : null,
                    raceID: nextProps.selectedMember.raceID ? nextProps.selectedMember.raceID : null,
                    phoneNumber: nextProps.selectedMember.phoneNumber ? nextProps.selectedMember.phoneNumber : "",
                    streetAddress: nextProps.selectedMember.streetAddress ? nextProps.selectedMember.streetAddress : "",
                    streetAddress2: nextProps.selectedMember.streetAddress2 ? nextProps.selectedMember.streetAddress2 : "",
                    city: nextProps.selectedMember.city ? nextProps.selectedMember.city : "",
                    state: nextProps.selectedMember.state ? nextProps.selectedMember.state : null,
                    zipcode: nextProps.selectedMember.zipcode ? nextProps.selectedMember.zipcode : "",
                    county: nextProps.selectedMember.county ? nextProps.selectedMember.county : "",
                    country: nextProps.selectedMember.country ? nextProps.selectedMember.country: "",
                    guardianFirstName: nextProps.selectedMember.guardianFirstName ? nextProps.selectedMember.guardianFirstName : "",
                    guardianLastName: nextProps.selectedMember.guardianLastName ? nextProps.selectedMember.guardianLastName : "",
                    customerID: nextProps.selectedMember.customerID ? nextProps.selectedMember.customerID : null,
                    FamilyGroupID: nextProps.selectedMember.FamilyGroupID ? nextProps.selectedMember.FamilyGroupID : null,
                    FamilyCanView: nextProps.selectedMember.FamilyCanView ? nextProps.selectedMember.FamilyCanView : null,
                    FacilityIDs: nextProps.selectedMember.FaciliyIDs ? nextProps.selectedMember.FacilityIDs : null,
                    uid: nextProps.selectedMember.uid ? nextProps.selectedMember.uid : -1,
                }
            })
        } else {
            this.setState({
                memberInfo: {
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    dateOfBirth: "",
                    email: "",
                    confirmEmail: "",
                    genderID: "",
                    ethnicityID: "",
                    raceID: "",
                    phoneNumber: "",
                    streetAddress: "",
                    streetAddress2: "",
                    city: "",
                    state: null,
                    zipcode: "",
                    county: "",
                    country: "",
                    guardianFirstName: "",
                    guardianLastName: "",
                    customerID: null,
                    FamilyGroupID: null,
                    FamilyCanView: null,
                    uid: -1,
                }
            })
        }
    }

    shouldComponentUpdate(nextProps: Readonly<FamilyGroupModalProps>, nextState: Readonly<FamilyGroupModalState>, nextContext: any): boolean {
        if (this.props.attachClearStateFunc)
            this.props.attachClearStateFunc(this.clearState)
        return true;
    }

    closeInBetween() {
        this.setState({ showInBetween: false })
    }

    render() {
        // console.log('FamilyGroupModal props', this.props)
        // console.log('FamilyGroupModal lstate', this.state)

        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading} zIndex={100005} />
                <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100003} />
                <div className="modal fade form_modal" id={FamilyGroupModal.ID} tabIndex={-1} role="dialog"
                    aria-label="Family Member Information"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid">
                                    <div className={"row"}>
                                        <div className="col-12 pt-2">
                                            <div className="card mt-2">
                                                <div className="card-header verlag-bold">
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <h4>
                                                                Family Member Information
                                                            </h4>
                                                        </div>
                                                        <div className="col-2">
                                                            <button style={{ outline: 'none' }} type="button" className="close pr-4"
                                                                aria-label="Close" onClick={() => hideModal(FamilyGroupModal.ID)}>
                                                                <span aria-hidden="true" style={{ fontSize: '1.5em', fontWeight: 'bold' }}>&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className={""}>
                                                        <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'firstName'}>
                                                            <label htmlFor={'firstName'} className="col-12 col-sm-4 col-form-label text-center text-md-left">{'Name'}</label>
                                                            <div className="col-12 col-md-8 p-0 m-0" id={'firstName'}>
                                                                <input
                                                                    id={'firstName'}
                                                                    className={"form-control"}
                                                                    maxLength={50}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'firstName'}
                                                                    onChange={(e) => {
                                                                        this.setState((prevState) => ({
                                                                            memberInfo: {
                                                                                ...prevState.memberInfo,
                                                                                firstName: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.memberInfo?.firstName}
                                                                />
                                                            </div>
                                                        </div>

                                                        {buildRow('Middle Name',
                                                            <input
                                                                id={'MiddleName'}
                                                                className={"form-control"}
                                                                maxLength={50}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'middleName'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            middleName: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.middleName}
                                                            />, 'Middle Name'
                                                        )}
                                                        {buildRow('Last Name',
                                                            <input
                                                                id={'LastName'}
                                                                className={"form-control"}
                                                                maxLength={50}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'lastName'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            lastName: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.lastName}
                                                            />, 'Last Name'
                                                        )}
                                                        {buildRow("Date of Birth",
                                                            <input
                                                                id="DateofBirth"
                                                                min={'1000-01-01'}
                                                                max={'9999-12-31'}
                                                                className={"form-control"}
                                                                autoComplete={"off"}
                                                                type={"date"}
                                                                name={"DOB"}
                                                                value={this.state.memberInfo && this.state.memberInfo.dateOfBirth ? new Date(this.state.memberInfo.dateOfBirth).toISOString().split('T')[0] : ''}
                                                                onChange={(e) => {
                                                                    if (Date.parse(e.target.value)) {
                                                                        this.setState((prevState) => ({
                                                                            memberInfo: {
                                                                                ...prevState.memberInfo,
                                                                                dateOfBirth: new Date(e.target.value)
                                                                            }
                                                                        }));
                                                                    }
                                                                }}
                                                            />, "Date of Birth"
                                                        )}
                                                        {buildRow('Email to Receive Results',
                                                            <input
                                                                id={'Email'}
                                                                className={"form-control"}
                                                                maxLength={100}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'Email'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            email: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.email}
                                                            />, 'Email'
                                                        )}
                                                        {buildRow('Confirm Email',
                                                            <input
                                                                id={'ConfirmEmail'}
                                                                className={"form-control"}
                                                                maxLength={100}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'confirmEmail'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            confirmEmail: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.confirmEmail}
                                                            />, 'Confirm Email'
                                                        )}
                                                        {buildRow('Gender',
                                                            <Select
                                                                key={'genderID'}
                                                                id={'genderID'}
                                                                isSearchable={true}
                                                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                noOptionsMessage={() => "No option"}
                                                                aria-label="Gender"
                                                                value={this.state.memberInfo ? getLabel(this.state.memberInfo.genderID, this.props.gender) : null}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            genderID: e.value
                                                                        }
                                                                    }))
                                                                }}
                                                                className={'state_select'}
                                                                options={this.props.gender}
                                                            />, 'Gender'
                                                        )}
                                                        {buildRow('Ethnicity',
                                                            <Select
                                                                key={'ethnicityID'}
                                                                id={'ethnicityID'}
                                                                isSearchable={true}
                                                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                noOptionsMessage={() => "No option"}
                                                                aria-label="Ethnicity"
                                                                value={this.state.memberInfo ? getLabel(this.state.memberInfo.ethnicityID, this.props.ethnicity) : null}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            ethnicityID: e.value
                                                                        }
                                                                    }))
                                                                }}
                                                                className={'state_select'}
                                                                options={this.props.ethnicity}
                                                            />, 'Ethnicity'
                                                        )
                                                        }
                                                        {buildRow('Race',
                                                            <Select
                                                                key={'raceID'}
                                                                id={'raceID'}
                                                                isSearchable={true}
                                                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                noOptionsMessage={() => "No option"}
                                                                aria-label="Race"
                                                                value={this.state.memberInfo ? getLabel(this.state.memberInfo.raceID, this.props.race) : null}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            raceID: e.value
                                                                        }
                                                                    }))
                                                                }}
                                                                className={'state_select'}
                                                                options={this.props.race}
                                                            />, 'Race'
                                                        )
                                                        }

                                                        {buildRow('Phone',
                                                            <PhoneInput
                                                                id={'PhoneNumber'}
                                                                placeholder={"Enter phone number"}
                                                                onChange={(e) =>
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            phoneNumber: e
                                                                        }
                                                                    }))}
                                                                defaultCountry="US"
                                                                value={this.state.memberInfo?.phoneNumber} />, "Phone Number"
                                                        )}

                                                        {buildRow('Street Address',
                                                            <input
                                                                id={'StreetAddress'}
                                                                className={"form-control"}
                                                                maxLength={100}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'streetAddress'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            streetAddress: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.streetAddress}
                                                            />, 'Street Address'
                                                        )}
                                                        {buildRow('Street Address cont.',
                                                            <input
                                                                id={'StreetAddresscont'}
                                                                className={"form-control"}
                                                                maxLength={100}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'streetAddress2'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            streetAddress2: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.streetAddress2}
                                                            />, 'Street Address cont'
                                                        )}

                                                        {buildRow('City',
                                                            <input
                                                                id={'City'}
                                                                className={"form-control"}
                                                                maxLength={100}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'city'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            city: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.city}
                                                            />, 'City'
                                                        )}
                                                        {buildRow('State',
                                                            <Select
                                                                key={'state'}
                                                                id={'State'}
                                                                isSearchable={true}
                                                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                noOptionsMessage={() => "No option"}
                                                                aria-label="State"
                                                                value={this.state.memberInfo ? getLabel(this.state.memberInfo.state, this.props.states) : null}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            state: e.value
                                                                        }
                                                                    }))
                                                                }}
                                                                className={'state_select'}
                                                                options={this.props.states}
                                                            />, 'State'
                                                        )
                                                        }

                                                        {buildRow('County',
                                                            <input
                                                                id={'County'}
                                                                className={"form-control"}
                                                                maxLength={100}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'county'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            county: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.county}
                                                            />, 'County'
                                                        )}
                                                        {buildRow('Zip',
                                                            <input
                                                                id={'Zipcode'}
                                                                className={"form-control"}
                                                                maxLength={100}
                                                                autoComplete={"off"}
                                                                type={"search"}
                                                                name={'zipcode'}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            zipcode: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.memberInfo?.zipcode}
                                                            />, 'Zipcode'
                                                        )}


                                                        {buildRow('Country',
                                                            <Select
                                                                key={'country'}
                                                                id={'country'}
                                                                isSearchable={true}
                                                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                noOptionsMessage={() => "No option"}
                                                                aria-label="Country"
                                                                value={this.state.memberInfo ? getLabel(this.state.memberInfo.country, this.props.countries) : null}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        memberInfo: {
                                                                            ...prevState.memberInfo,
                                                                            country: e.value
                                                                        }
                                                                    }))
                                                                }}
                                                                className={'state_select'}
                                                                options={this.props.countries}
                                                            />, 'Country'
                                                        )
                                                        }
                                                        {/* hide inputs, populate guardian first and last with UserContext if member is under 18*/}
                                                        <div className={"d-none"}>
                                                            {buildRow("Guardian First Name",
                                                                <input 
                                                                    id="GuardianFirstName"
                                                                    className={"form-control"}
                                                                    maxLength={50}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={"Guardian First Name"}
                                                                    value={this.state.memberInfo?.guardianFirstName}
                                                                />
                                                                , 'Guardian First Name'
                                                            )}

                                                            <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Guardian Last Name'}>
                                                                <label htmlFor={'guardianLastName'} className="col-sm-4 col-form-label">{'Guardian Last Name'}</label>
                                                                <div className="col-12 col-md-8 p-0 m-0" id={'guardianLastName'}>
                                                                    <input
                                                                        id="guardianLastName"
                                                                        className={"form-control"}
                                                                        maxLength={50}
                                                                        autoComplete={"off"}
                                                                        type={"search"}
                                                                        name={"Guardian Last Name"}
                                                                        value={this.state.memberInfo?.guardianLastName}
                                                                    />
                                                                    <div className="row">
                                                                        <div className="col-12 pr-1 mb-2 m-0 verlag-bold" style={{ fontSize: '1rem' }}>*guardian info required for patients under the age of 18</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
                                        <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}