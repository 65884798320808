import React from "react";
import Select from "react-select";
import {Result} from "../../types/Result";
import {sweetalert} from "../../App";
import {isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import {buildRow, getBoolSelectOptions, getBoolSelectVal} from "../../util/FormatUtil";
import {isEmptyObject} from "jquery";
import GetField from "../../customfields/GetField";
import { CustomFieldBuilder } from "../../customfields/CustomFieldBuilder";

interface TasksModalState{
    selectedTask
}
interface TasksModalProps{
    selectedTask
    onSubmit
}
export class TasksModal extends React.Component<TasksModalProps, TasksModalState>{
    public static ID = "tasks";
    constructor(props) {
        super(props);
        this.state = {
            selectedTask: {
                ID: null,
                EmployeeID: null,
                Title: null,
                Description: null,
                Priority: null,
                DueDate: null,
                ReminderDate: null,
                Repeat: null,
                Frequency: null,
                CompletedDate: null,
            }
        } as TasksModalState
    }

    componentWillReceiveProps(nextProps: Readonly<TasksModalProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedTask)){
            let task = nextProps.selectedTask;
            this.setState({
                selectedTask: {
                    ID: task.ID,
                    EmployeeID: task && task.EmployeeID ? task.EmployeeID : null,
                    Title: task && task.Title ? task.Title : null,
                    Description: task && task.Description ? task.Description : null,
                    Priority: task && task.Priority ? task.Priority : null,
                    DueDate: task && task.DueDate ? task.DueDate : null,
                    ReminderDate: task && task.ReminderDate ? task.ReminderDate : null,
                    Repeat: task && task.Repeat ? task.Repeat : null,
                    Frequency: task && task.Frequency ? task.Frequency : null,
                    CompletedDate: task && task.CompletedDate ? task.CompletedDate : null,
                }
            });
       
        }
    }

    render(){
        return(
            <div className="modal fade form_modal" id={TasksModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Task Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {buildRow("Title",
                                <input className={"form-control"}
                                       readOnly={true}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Title"}
                                       value={this.state.selectedTask.Title}
                                />
                            )}
                            {buildRow("Description",
                                <input className={"form-control"}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"Description"}
                                    value={this.state.selectedTask.Description}
                                />
                            )}
                            {buildRow("Priority",
                                <input className={"form-control"}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"Priority"}
                                    value={this.state.selectedTask.Priority}
                                />
                            )}
                            {buildRow("Due Date",
                                <input className={"form-control"}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"Due Date"}
                                    value={this.state.selectedTask.DueDate}
                                />
                            )}
                            {buildRow("Completed Date",
                                <input className={"form-control"}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"Completed Date"}
                                    value={this.state.selectedTask.CompletedDate}
                                />
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={this.state.selectedTask && this.state.selectedTask.CompletedDate ? "d-none" : "btn btn-primary"} onClick={() => {
                                this.props.onSubmit(this.state.selectedTask);
                            }}>
                                Complete
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}