import NetworkUtil from "./NetworkUtil";
import TestType from "../types/TestType"
import {ServerResponse} from "../types/ServerResponse";
import RecordType from "../types/RecordType";


export default class VaccineRecordsAPI {
    
    public static async filterRecord(filter):Promise<{success:boolean, data:RecordType[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/records/filter", filter);
    }

    public static async queryRecord(body):Promise<{success:boolean, record:RecordType}>{
        return await NetworkUtil.makePost("/api/admin/records/details", body);
    }

    public static async editRecord(body, insuranceInformation, insuranceCards):Promise<ServerResponse>{
      // console.log(JSON.stringify(body))
      let data = new FormData()
      data.set('insuranceInformation', JSON.stringify(insuranceInformation))
      data.set('patientInformation', JSON.stringify(body))
      if(insuranceCards){
          for(let i = 0; i < insuranceCards.length; i++){
              let ext = insuranceCards[i].type.split('/').pop();
              data.append('insuranceCards', insuranceCards[i], `${i === 0 ? 'front' : 'back'}.${ext}`)
          }
      }
      // for (let [key, value] of data.entries()) {
      //   console.log(key, value);
      // }
      return NetworkUtil.postForm("/api/admin/records/edit", data);
    }

    public static async newRecord(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/records/new", body);
  }
}