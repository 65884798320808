import React from 'react';
import {AdminPages} from "./AdminNav";

interface SidebarProps 
{
    items?
    authPages?
    onChange: (str: string) => void
    backgroundColor:string
    showSidebar
}

class SidebarItems extends React.Component<SidebarProps, SidebarItems> {

    private routes = [
        {label: "Appointment Form", value: "AppointmentForm"},
        {label: "Profile Management", value: "ProfileManagement"},
        {label: "Vaccine Records", value: "VaccineRecords"}, // Vaccine Records
        {label: "Evaluation Records", value: "TreatmentRecords"}, // Treatment Records
        {label: "Tests", value: "Tests"}, // Treatment Records
        {label: "Family Group", value: "FamilyGroup"},
        {label: "Password Reset", value: "PasswordReset"},
        {label: "Tasks", value: "Tasks"}
    ]

    private appointmentPages = [AdminPages.AppointmentForm];
    // private profilePages = [AdminPages.ProfileManagement, AdminPages.FamilyGroup, AdminPages.PasswordReset ];
    private profilePages = [AdminPages.ProfileManagement, AdminPages.PasswordReset ];
    private recordsPages=[AdminPages.VaccineRecords, AdminPages.TreatmentRecords/*, AdminPages.Tests*/];
    private tasksPages=[AdminPages.Tasks];

    itemBelongsUnderTab(pageName, tabName){
        if(
            this.props.authPages.includes(pageName) &&
            tabName.includes(pageName)
        ){
            let route = this.routes.find(f => f.label === pageName)
            return (
                <li className={"sidebar-list-item"} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                    <a className={'navSelect white'} tabIndex={!this.props.showSidebar ? -1 : 0} aria-hidden={!this.props.showSidebar ? "true" : "false"} href={`/admin/${route.value}`}
                       onClick={() => this.props.onChange(pageName)}>
                        {pageName}
                    </a>
                </li>
            );
        }

        return (<React.Fragment />);
    }

    render(){
        const buildAppointmentTab = this.props.authPages.some(authPage => this.appointmentPages.includes(authPage));
        const buildProfileTab = this.props.authPages.some(authPage => this.profilePages.includes(authPage))
        const buildRecordsTab = this.props.authPages.some(authPage => this.recordsPages.includes(authPage))
        const buildTasksTab = this.props.authPages.some(authPage => this.tasksPages.includes(authPage))
        

        return (
            <div className={"sidebar-wrapper"} >
                <ul className={"list-unstyled"} id={"menu"}>
                    { buildAppointmentTab ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"patients-list"}>
                            <a href={'#'} className={"navTag white"} id={"appointment-tab"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/appointment.png"} alt="" className='navIcon'/>
                                </span>
                                Appointments
                                <i id={"patients-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.appointmentPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildRecordsTab ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"samples-list"}>
                            <a href={"#"} className={"navTag primary-black-bg white"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"samples-tab"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/samples.png"} alt="" className='navIcon'/>
                                </span>
                                Records
                                <i id={"samples-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.recordsPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    {buildProfileTab ?
                        <li className={"sidebar-header"} id={"profile-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"}>
                            <a href={'#'} className={"navTag primary-black-bg white"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"patients-tab"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/people.png"} alt="" className='navIcon'/>
                                </span>
                                    Profile 
                                <i id={"patients-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.profilePages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    {buildTasksTab ?
                        <li className={"sidebar-header"} id={"tasks-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"}>
                            <a href={'#'} className={"navTag primary-black-bg white"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"tasks-tab"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/clipboard.png"} alt="" className='navIcon'/>
                                </span>
                                    Tasks 
                                <i id={"patients-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.tasksPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                </ul>
            </div>
        );
    }
}

export default SidebarItems