import { UserContext } from './../types/UserContext';
import NetworkUtil from "./NetworkUtil";
import { Patient } from "../types/Patient";
import {ServerResponse} from "../types/ServerResponse";

export default class PatientAPI {

    public static async getPatientsFromPatientPortal():Promise<{patients: [], success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/patient/getPatientsFromPatientPortal");
    }

    public static async filterPatients(filter):Promise<{success:boolean, data:Patient[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/patient/patientfilter", filter);
    }

    public static async editPatient(body):Promise<ServerResponse>{
        return await NetworkUtil.makePost("/api/admin/patient/edit", body);
    }

    public static async getUserProfileByUID(uid):Promise<{success:boolean, data:Patient, reason:string}>{
        return await NetworkUtil.makePost("/api/admin/patient/profile",{uid: uid})
    }
    // FAMILY_GROUP
    public static async getFamilyGroupMembers():Promise<{success:boolean,reason:string,data:{familyGroup,members}}>{
        return NetworkUtil.makeGet("/api/admin/patient/familyGroup/members");
    }
    public static async createFamilyGroup(groupName:string):Promise<{success:boolean,reason:string,data}>{
        return NetworkUtil.makePost("/api/admin/patient/familyGroup/create", {groupName: groupName});
    }
    public static async editFamilyMember(member):Promise<{success:boolean,reason:string, data}>{
        return NetworkUtil.makePost("/api/admin/patient/familyGroup/edit", member)
    }
    public static async ageOutFamilyMember(member):Promise<{success:boolean,reason:string,data}>{
        return NetworkUtil.makePost("/api/admin/patient/familyGroup/ageout",{member:member});
    }
    public static async getPatientsInFamilyGroup():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/admin/patient/familypatients");
    }
}