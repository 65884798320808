import React from "react";
import CancelAppointment from "./modals/CancelAppointment";
import Overlay from "./Overlay";
import {AppointmentSelectionModal} from "./modals/AppointmentSelectionModal";
import Banner from "../types/Banner";
import SystemAPI from "../network/SystemAPI";

interface NavState{
    showLoading:boolean
    banner?:Banner
    logo:string
    brandingImage:string
    adminNavBackgroundColorHexValue:string
    productName
}
class Nav extends React.Component<{}, NavState>{

    constructor(props) {
        super(props);
        this.state = {
            showLoading:false,
            logo:"",
            brandingImage:"",
            adminNavBackgroundColorHexValue:"",
            productName: ""
        }
        this.getBannerElem = this.getBannerElem.bind(this);
    }

    componentDidMount() {
        this.setState({ showLoading: true }, () => {
            let currentURL = window.location.href;
            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let ProductID = data.ProductID;
                let backgroundImageURL = data.ProductBackgroundImageURL;

                //@ts-ignore
                document.body.style.backgroundImage = `url(${backgroundImageURL}`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";

                this.setState({
                    brandingImage: data.ProductAdminNavLogoURL,
                    productName: data.Name,
                    adminNavBackgroundColorHexValue: data.ProductAdminNavBackgroundColorHexValue
                });
                SystemAPI.getBanner(ProductID).then(data => {
                    if (data.show) {
                        this.setState({ banner: data.banner, showLoading: false })
                    } else {
                        this.setState({ showLoading: false })
                    }
                });
            });
        });
    }

    getBannerElem() {
        if(!this.state.banner)
            return null;
        return (
            <div className="alert alert-danger m-0" role="alert">
                <p className="primary-black m-0">
                    {this.state.banner.Text}
                </p>
            </div>
        )
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <AppointmentSelectionModal/>
                <Overlay show_loading={this.state.showLoading}/>
                <CancelAppointment/>

                <div className="headerWrapper">
                    {this.getBannerElem()}
                    <div className="container-fluid p-1" style={{backgroundColor: this.state.adminNavBackgroundColorHexValue ? this.state.adminNavBackgroundColorHexValue : 'black'}}>
                        {/* <div className="row m-0"> */}
                            <nav className="navbar navbar-expand-lg navbar-dark p-0">
                                {/* <div className={"col-xs-8"}>
                                    <div className={"row"}> */}
                                        {/* <div className={"col-md-auto"}> */}
                                            <a className={!this.state.brandingImage || this.state.brandingImage.length < 1 ? 'd-none' : 'navbar-brand nav-href verlag-bold'} 
                                                href={"/"}> 
                                                <img src={this.state.brandingImage}
                                                    style={{maxHeight: '3rem'}} 
                                                    className={'admin-nav-logo pb-1 pl-1'} 
                                                    alt={this.state.productName}/>
                                            </a>
                                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                                    data-target="#navbarText"
                                                    aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                        {/* </div>  */}
                                    {/* </div>
                                </div> */}
                                <div className="collapse navbar-collapse float-right" id="navbarText">
                                    <ul className="navbar-nav mr-auto text-center">
                                        <li className={"nav-item"}>
                                            <a className="nav-link" href="/appointment" role="button">
                                                Create Appointment
                                            </a>
                                        </li>

                                        <li className={"nav-item"}>
                                            <button className="nav-link accordionButton" style={{background: "none"}} onClick={() =>  // @ts-ignore
                                                window.$("#" + CancelAppointment.ID).modal('show')}>
                                                Cancel Appointment
                                            </button>
                                        </li>

                                        {/* <li className={"nav-item"}>
                                            <a className="nav-link" href={"/results"} role="button">
                                                Get Testing Results
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </nav>
                        {/* </div> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Nav;