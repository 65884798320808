import React from "react";
import "../App.css";
import InformedConsentModal from "./modals/InformedConsentModal";
import Overlay from "./Overlay";
import ConfirmationMessage from "./modals/ConfirmationMessage";
import { AppointmentForm } from "./AppointmentForm";
import { ConsentSummary } from "./ConsentSummary";
import { Sample } from "../types/Sample";
import { getToken } from "../util/CaptchaUtil";
import { sweetalert } from "../App";
import SubmissionFileUploadModal from "./modals/SubmissionFileUploadModal";
import {
  getSelectLabels,
  getNameFromID,
  hideModal,
  parseDate,
  showModalNoOutsideClick,
} from "../util/FormatUtil";
import SubmissionAPI from "../network/SubmissionAPI";
import Validator, { ValidationEntry } from "../validation/Validator";
import { Validators } from "../validation/Validators";
import { CustomerAPI } from "../network/CustomerAPI";
import { EventAPI } from "../network/EventAPI";
import SystemAPI from "../network/SystemAPI";
import { CustomFieldType } from "../types/CustomFieldType";
import ServicesAPI from "../network/ServicesAPI";
import { ServiceFormModal } from "./modals/ServiceFormModal";
import { PatientInfoModal } from "./modals/PatientInfoModal";
import { InsuranceModal } from "./modals/InsuranceModal";
import { isEmptyObject } from "jquery";
import { ROIModal } from "./modals/ROIModal";
import { ImportantInformationModal } from "./modals/ImportantInformationModal";
import InBetweenOverlay from "./InBetweenOverlay";
import { AuthContext } from "../context/AuthContext";
import PatientAPI from "../network/PatientAPI";

export interface PatientFormState {
  test?;
  location: {
    day?: {
      label: string;
      value: {
        day: string;
        times: { remaining: number; time: string }[];
        schedID: number;
        testType;
      };
    };
    facility: {
      label: string;
      value: {
        id: number;
        FacilityName?: string;
        Address?: string;
        days?: { day: string }[];
      };
    };
    time?: { label: string; value: any };
  };
  patientInformation;
  insuranceInformation;
  schedMap?: any[];
  acknowledged;
  showLoading: boolean;
  release?: any;
  token?: string;
  patientsInFamilyGroup?
  consentEntities: {
    ID: number;
    Name: string;
    FacilityID: number;
    AccessCode: string;
  }[];
  legalName: string;
  imageSaved: boolean;
  frontCardImgFile?: File;
  backCardImgFile?: File;
  allCustomQuestions: CustomFieldType[];
  currentCustomQuestions;
  serviceIDs: number[];
  showInsurance: boolean;
  allowMultipleTests: boolean;
  savedServices?;
  savedROIServices?;
  servicesObjectsArray?;
  roiObjectsArray?;
  selectedServices;
  states?;
  race?;
  gender?;
  ethnicity?;
  countries?;
  services;
  serviceLabels;
  guardianInfo?;
  savedPatientInformation?;
  savedInsuranceInformation?;
  savedImportantInformation?;
  selectedServiceID?: number;
  serviceSpecificCustomQuestions?;
  declineConsent:boolean;
  patientProfile?
  patientOptions?
  submissionFiles?
  savedFileUpload?
  showFileUpload?
}

class PatientForm extends React.Component<
  { isPrivate?; schedMap?; fetchFacilities? },
  PatientFormState
> {
  private roiRef: any;
  private submissionFileRef: any;
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      serviceLabels: [],
      services: [],
      showInsurance: false,
      showFileUpload: false,
      allowMultipleTests: false,
      patientInformation: {
        PatientDOB: "",
        PatientFirstName: "",
        PatientGenderID: "",
        PatientLastName: "",
        PatientMiddleName: "",
        PatientAddress: "",
        PatientCounty: "",
        PatientEmail: "",
        PatientEthnicityID: "",
        PatientPhone: "",
        PatientRaceID: "",
        PatientZip: "",
      } as Sample,
      insuranceInformation: {},
      location: { facility: null },
      acknowledged: "false",
      showLoading: false,
      test: { label: "", value: 1 },
      consentEntities: [],
      legalName: "",
      imageSaved: false,
      allCustomQuestions: [],
      currentCustomQuestions: [],
      serviceIDs: [],
      savedServices: [],
      savedROIServices: [],
      servicesObjectsArray: [],
      roiObjectsArray: [],
      selectedServices: [],
      states: [],
      gender: [],
      ethnicity: [],
      race: [],
      countries: [],
      guardianInfo: {},
      savedImportantInformation: false,
      declineConsent:false,
      patientProfile:null,
    };
    this.submit = this.submit.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.assignClearState = this.assignClearState.bind(this);
    this.assignClearPatient = this.assignClearPatient.bind(this);
    this.assignClearService = this.assignClearService.bind(this);
    this.assignClearGuardian = this.assignClearGuardian.bind(this);
    this.submissionFileRef = React.createRef();
    this.roiRef = React.createRef();
  }

  fetchFacilities() {
    if (this.props.fetchFacilities) return this.props.fetchFacilities();

    this.setState({ showLoading: true }, () => {
      EventAPI.getEvents().then((data) => {
        this.setState({ schedMap: data.schedMap });
      });
      
    });
  }

  componentDidMount(): void {
    if (!this.props.isPrivate) this.fetchFacilities();
    CustomerAPI.getPolicyName().then((data) => {
      this.setState({ legalName: data.name });
    });
    SystemAPI.getCustomQuestions("Create Appointment").then((res) => {
      this.setState({
        allCustomQuestions: res.questions.sort((a, b) => a.Order - b.Order),
      });
    });
    SystemAPI.getAllStates().then((data) => {
      this.setState({ states: data });
    });
    SystemAPI.getAllRaces().then((data) => {
      this.setState({ race: data });
    });
    SystemAPI.getAllGenders().then((data) => {
      this.setState({ gender: data });
    });
    SystemAPI.getAllEthnicities().then((data) => {
      this.setState({ ethnicity: data });
    });
    SystemAPI.getAllCountries().then((data) => {
      this.setState({ countries: data });
    });
    SystemAPI.getConsentEntities().then((data) => {
      this.setState({ consentEntities: data });
    });

    if(this.context && this.context.uid){
      PatientAPI.getFamilyGroupMembers().then((response) => {
        let patientOptions = [];
        if(response.data.members && response.data.members.length > 0){
          response.data.members.map((member) => {
            patientOptions.push({ label: member.displayName, value: member.id })
          });
        }

        this.setState({ patientsInFamilyGroup: response.data.members, patientOptions: patientOptions })
      });
    }

    ServicesAPI.getServicesForWalkinForm().then((data) => {
      this.setState({
        services: data.data,
        serviceLabels: getSelectLabels(data.data),
        showLoading: false,
      });
    });

    if (this.context !== null) {
      PatientAPI.getUserProfileByUID(this.context.uid).then((response) => {
        this.setState({patientProfile: response.data[0]})
      })
    }

  }

  async submit() {

    if (!this.state.savedImportantInformation) {
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: "Important Information must be reviewed.",
      });
    }

    let patientFormValidation = {
      Facility: this.state.location.facility,
      Time: this.state.location.time,
      Day: this.state.location.day,
      Acknowledged: this.state.acknowledged,
    };

    let validator = new Validator<any>()
      .withSimpleValidation(
        "Facility",
        Validators.requireNonNullValidator("Collection Site")
      )
      .withSimpleValidation("Day", Validators.requireNonNullValidator())
      .withSimpleValidation("Time", Validators.requireNonNullValidator());
    validator = validator.withComposedValidation(
      "Acknowledged",
      new ValidationEntry(Validators.requireNonNullValidator()),
      new ValidationEntry<any>((key, attr, obj) => {
        if (obj.Acknowledged === "false" || !obj.Acknowledged) {
          return {
            success: false,
            error: "You must acknowledge all policies listed in this form",
          };
        }
        return { success: true, error: null };
      })
    );
    let validationResponse = validator.validate(patientFormValidation);
    if (!validationResponse.success) {
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: validationResponse.error,
      });
    }

    if (
      isEmptyObject(this.state.patientInformation) ||
      !PatientInfoModal.validatePatient(this.state.patientInformation)
    ) {
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: "Patient Information is not complete",
      });
    }

    if (this.state.currentCustomQuestions.length > 0) {
      for (let j = 0; j < this.state.currentCustomQuestions.length; j++) {
        if (this.state.currentCustomQuestions[j].length === 0) {
          continue;
        } else {
          for (let i = 0; i < this.state.selectedServices.length; i++) {
            if (
              this.state.selectedServices[i].ID ===
              this.state.currentCustomQuestions[j][i]?.ServiceID
            ) {
              if (this.state.servicesObjectsArray?.length < 1) {
                return sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: "Service Specific Infomation is not complete",
                });
              } else {
                for (
                  let k = 0;
                  k < this.state.servicesObjectsArray.length;
                  k++
                ) {
                  if (
                    this.state.servicesObjectsArray[k].serviceID ===
                    this.state.selectedServices[i].ID
                  ) {
                    if (
                      isEmptyObject(
                        this.state.servicesObjectsArray[k].submittedAnswers
                      )
                    ) {
                      return sweetalert.fire({
                        icon: "error",
                        title: "",
                        text: "All Required Service Specific Informatin must be complete",
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    let serviceObjArrayCopy = JSON.parse(
      JSON.stringify(this.state.servicesObjectsArray)
    );

    let servicesWithQuestions = [];
    let servicesWithoutQuestions = []
    for (let i = 0; i < this.state.serviceIDs.length; i++) {
      let found = serviceObjArrayCopy.find(s => s.serviceID === this.state.serviceIDs[i])
      if(found){
        servicesWithQuestions.push(found)
      }
      else{
        servicesWithoutQuestions.push(this.state.serviceIDs[i])
      }
    }

    let allServices = servicesWithQuestions

    if(servicesWithoutQuestions?.length > 0){
      for (let i = 0; i < servicesWithoutQuestions.length; i++) {
        allServices.push({
          serviceID: servicesWithoutQuestions[i],
          submittedAnwers: null,
          sampleInfo: null,
        });
        
      }
    }

    this.setState({ servicesObjectsArray: allServices });

    showModalNoOutsideClick(ConfirmationMessage.ID);
  }

  async sendForm() {
    hideModal(ConfirmationMessage.ID);
    this.setState({ showLoading: true }, async () => {
      let insuranceInfo = {};
      let insuranceCards = [];
      let token: string = await getToken();

      //set up patientInfo
      let patientInfo = JSON.parse(
        JSON.stringify(this.state.patientInformation)
      );
      // remove unecessary data from object before posting form
      delete patientInfo["confirmEmail"];
      delete patientInfo["errors"];
      //delete patientInfo["UID"];

      patientInfo.FacilityID = this.state.location.facility
        ? this.state.location.facility.value.id
        : null;
      patientInfo.PatientCountry =  patientInfo.PatientCountry ? patientInfo.PatientCountry : "United States";
      patientInfo.PatientCounty = patientInfo.PatientCounty
        ? patientInfo.PatientCounty.trim()
        : null;
      patientInfo.PatientMiddleName = patientInfo.PatientMiddleName
        ? patientInfo.PatientMiddleName.trim()
        : null;
      patientInfo.GuardianFirstName = patientInfo.GuardianFirstName.trim().length > 0
        ? patientInfo.GuardianFirstName.trim()
        : null;
      patientInfo.GuardianLastName = patientInfo.GuardianLastName.trim().length > 0
        ? patientInfo.GuardianLastName.trim()
        : null;

      patientInfo.location = {
        facilityID: this.state.location.facility.value.id,
        podID: this.state.location.day.value.schedID,
        day: this.state.location.day.value,
        time: this.state.location.time.value,
      };
      patientInfo.token = token;

      //set insuranceCards if provided
      if (!isEmptyObject(this.state.insuranceInformation)) {
        insuranceInfo = JSON.parse(
          JSON.stringify(this.state.insuranceInformation)
        );
        insuranceCards = [
          this.state.insuranceInformation.FrontCardImgFile,
          this.state.insuranceInformation.BackCardImgFile,
        ];
        delete insuranceInfo["FrontCardImgFile"];
        delete insuranceInfo["BackCardImgFile"];
        delete insuranceInfo["errors"];
      }

      let serviceObjectArrayCopy = JSON.parse(
        JSON.stringify(this.state.servicesObjectsArray)
      );

      let roiObjectsArray = JSON.parse(
        JSON.stringify(this.state.roiObjectsArray)
      );

      console.log(patientInfo.UID)
      patientInfo.PatientPortalUID = patientInfo["UID"] ? patientInfo["UID"] : ""; // this needs to be our uid from the patient form not logged in user

      let currentURL = window.location.href;
      let submissionFiles = this.state.submissionFiles && this.state.submissionFiles.length > 0 ? this.state.submissionFiles : null;

      try {
        let result = await SubmissionAPI.patientForm(
          patientInfo,
          insuranceInfo,
          serviceObjectArrayCopy,
          roiObjectsArray,
          insuranceCards,
          submissionFiles,
          this.state.selectedServices,
          currentURL
        );
        if (result.success) {
          let serviceParam = `services=${this.state.serviceIDs}`;
          let loggedInURL = this.context === null ? `` : `/admin`

          sweetalert
            .fire({
              icon: "success",
              title: "",
              text: "An email has been sent confirming your appointment",
            })
            .then(function () {
              let linkedApptIDParam = "apptCode=" + result.LinkedApptsID;
              let codeParam = "code=" + result.ReqNums;
              let urlString = loggedInURL+"/confirmation?" + linkedApptIDParam + "&" + codeParam + "&" + serviceParam;
              window.location = urlString as any; // TODO THIS DIDN'T REDIRECT PROPERLY - landed at /admin from setting a publid appointment
            });
        } else {
          this.setState({ showLoading: false }, () =>
            sweetalert.fire({ icon: "error", title: "", text: result.reason })
          );
          if (result.timeTaken) {
            this.clearAppointmentState();
            this.fetchFacilities();
            this.setState({
              location: { facility: null, time: null, day: null },
              showLoading: false
            });
          }
        }
      } catch (e) {
        sweetalert.fire({
          icon: "error",
          title: "",
          text: "We are unable to process your request at this time. Please try again later. Your appointment was not saved",
        });
        this.setState({ showLoading: false });
        console.log(e);
      }
    });
  }
  clearAppointmentState: () => void = null;

  assignClearState(func) {
    this.clearAppointmentState = func;
  }

  clearGuardianForm = null;

  assignClearGuardian(func) {
    this.clearGuardianForm = func;
  }

  clearPatientForm = null;

  assignClearPatient(func) {
    this.clearPatientForm = func;
  }

  clearServiceForm = null;

  assignClearService(func) {
    this.clearServiceForm = func;
  }

  clearInsuranceForm = null;

  assignClearInsurance(func) {
    this.clearInsuranceForm = func;
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState({ [state]: value } as any);
    } else {
      this.setState({ [state]: null } as any);
    }
  }

  handleServiceChange(event) {
    let tempServices = [];
    let serviceIDs = [];
    let tempCustomQuestions = [];
    let showInsurance = false;
    let showFileUpload = false;
    let allowMultipleTests = false;
    let currentServiceIDsLength = this.state.serviceIDs.length

    if (event?.service?.length > 0 && this.state.services?.length > 0) {
      event.service.map((e) => {
        for (let i = 0; i < this.state.services.length; i++) {
          if (e.value === this.state.services[i].ID) {
            //selectedServices
            tempServices.push(this.state.services[i]);
            serviceIDs.push(this.state.services[i].ID);
          }
        }

        //customQuestions
        if (this.state.allCustomQuestions?.length > 0) {
          tempCustomQuestions.push(
            this.state.allCustomQuestions.filter(
              (q) => q.ServiceID === e.value && q.Options.isActive
            )
          );
        }
      });
    }

    if((event?.service?.length < currentServiceIDsLength) || !event.service){
      this.roiRef.current.clearState()
      this.setState({
        savedROIServices: [],
        roiObjectsArray: [],
        declineConsent: false,
      })
    }


    showInsurance = !!tempServices.find((s) => s.ShowInsurance);
    showFileUpload = !!tempServices.find((s) => s.ShowFileUpload);
    allowMultipleTests = !!tempServices.find((s) => s.AllowMultipleTests)

    this.setState({
      savedImportantInformation: false,
      selectedServices: tempServices,
      serviceIDs: serviceIDs,
      currentCustomQuestions: tempCustomQuestions,
      showInsurance: showInsurance,
      showFileUpload: showFileUpload,
      allowMultipleTests: allowMultipleTests,
      acknowledged: false
    });
  }

  handleSaveServiceSpecificInfo(e) {
   
    let serviceID = e.sampleInfo.serviceID ? e.sampleInfo.serviceID : e.vaccineInfo.serviceID ? e.vaccineInfo.serviceID : e.treatmentInfo.serviceID ? e.treatmentInfo.serviceID : null
    let savedServices = this.state.savedServices;
    let servicesObjectsArray = this.state.servicesObjectsArray;
    let showInsurance = this.state.services.find(s => s.ID === serviceID).ShowInsurance;
    let allowMultipleTests = this.state.services.find(s => s.ID === serviceID).AllowMultipleTests;

    

    if (serviceID) {
      if (savedServices?.length > 0) {
        savedServices.map((r, i) => {
          if (r === serviceID) {
            savedServices.splice(i, 1);
          }
        });
      }
      savedServices.push(serviceID);
    }

    if (servicesObjectsArray?.length > 0) {
      servicesObjectsArray.map((r, i) => {
        if (r.serviceID === serviceID) {
          servicesObjectsArray.splice(i, 1);
        }
      });
    }

    servicesObjectsArray.push({
      serviceID: serviceID,
      submittedAnswers: e.answeredCustomQuestions,
      sampleInfo: {
        showInsurance: showInsurance,
        allowMultipleTests: allowMultipleTests,
        isLabService: e.sampleInfo.isLabService ? e.sampleInfo.isLabService : false,
        lot: e.sampleInfo.lot ? e.sampleInfo.lot : null,
        memo: e.sampleInfo.memo ? e.sampleInfo.memo : null,
        selectedTests:
          e.sampleInfo.selectedTests?.length > 0
            ? e.sampleInfo.selectedTests
            : [],
        serviceID: e.sampleInfo.serviceID ? e.sampleInfo.serviceID : null,
        specimenID: e.sampleInfo.specimenID ? e.sampleInfo.specimenID : null,
        specimenSource: e.sampleInfo.specimenSource
          ? e.sampleInfo.specimenSource
          : null,
      },
    });

    this.setState({
      savedServices: savedServices,
      servicesObjectsArray: servicesObjectsArray,
    });
  }

  handleRelease(releaseObj) {
    let serviceID = releaseObj.serviceID ? releaseObj.serviceID : null;
    let savedROIServices = this.state.savedROIServices;
    let roiObjectsArray = this.state.roiObjectsArray;

    if (serviceID) {
      if (savedROIServices?.length > 0) {
        savedROIServices.map((r, i) => {
          if (r === serviceID) {
            savedROIServices.splice(i, 1);
          }
        });
      }
      savedROIServices.push(serviceID);
    }

    if (roiObjectsArray?.length > 0) {
      roiObjectsArray.map((r, i) => {
        if (r.serviceID === serviceID) {
          roiObjectsArray.splice(i, 1);
        }
      });
    }

    roiObjectsArray.push({
      serviceID: serviceID,
      entityID: releaseObj.entityID,
    });

    this.setState({
      savedROIServices: savedROIServices,
      roiObjectsArray: roiObjectsArray,
    });
  }

  handleRemoveRelease(releaseObj) {

    if(releaseObj === 'all'){
      this.setState({
        savedROIServices: [],
        roiObjectsArray: [],
      });
      return; 
    }


    let serviceID = releaseObj.serviceID ? releaseObj.serviceID : null;
    let savedROIServices = this.state.savedROIServices;
    let roiObjectsArray = this.state.roiObjectsArray;

    if (serviceID) {
      const index = savedROIServices.indexOf(serviceID);
      if (index > -1) {
        savedROIServices.splice(index, 1);
      }
    }

    if (roiObjectsArray?.length > 0) {
      roiObjectsArray.map((r, i) => {
        if (r.serviceID === serviceID) {
          roiObjectsArray.splice(i, 1);
        }
      });
    }

    this.setState({
      savedROIServices: savedROIServices,
      roiObjectsArray: roiObjectsArray,
    });
  }

  render() {

    // console.log('PatientForm state', this.state)

    let schedMap = this.state.schedMap;
    if (this.props.isPrivate) schedMap = this.props.schedMap;

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        { !window.location.pathname.includes('/admin') && 
          <InBetweenOverlay showInBetween={true} zIndex={-10} /> 
        }

        <ServiceFormModal
          submit={(e) => this.handleSaveServiceSpecificInfo(e)}
          service={this.state.services.find(
            (f) => f.ID === this.state.selectedServiceID
          )}
          reviewInfo={
            this.state.servicesObjectsArray?.length > 0 &&
            this.state.servicesObjectsArray.find(
              (f) => f.serviceID === this.state.selectedServiceID
            )
          }
          attachClearStateFunc={this.assignClearService}
          customQuestions={this.state.serviceSpecificCustomQuestions}
          showSampleInfo={false}
        />

        <PatientInfoModal
          patientProfile={this.state.patientProfile}
          fromWalkin={false}
          attachClearStateFunc={this.assignClearPatient}
          submit={(e) =>
            this.setState({
              patientInformation: e.patientInfo,
              savedPatientInformation: true,
            })
          }
          states={this.state.states}
          gender={this.state.gender}
          ethnicity={this.state.ethnicity}
          race={this.state.race}
          countries={this.state.countries}
          patients={this.state.patientsInFamilyGroup?.length ? this.state.patientsInFamilyGroup : null}
          patientOptions = {this.state.patientOptions?.length ? this.state.patientOptions : null}
        />

        <InsuranceModal
          submit={(e) =>
            this.setState({
              insuranceInformation: e,
              savedInsuranceInformation: true,
            })
          }
          imageSaved={this.state.imageSaved}
          handleImageSaved={(e) => this.setState({ imageSaved: e })}
          states={this.state.states}
        />
        <ROIModal
          ref={this.roiRef}
          releaseChanged={(r) => this.handleRelease(r)}
          // service={this.state.services.find(
          //   (f) => f.ID === this.state.selectedServiceID
          // )}
          selectedServices={this.state.selectedServices}
          services={this.state.services}
          consentEntities={this.state.consentEntities}
          handleRemove={(e) => this.handleRemoveRelease(e)}
          handleRemoveAll={() => this.setState({declineConsent: true}, ()=> this.handleRemoveRelease('all'))}
          reviewInfo={
            this.state.roiObjectsArray?.length > 0 &&
            this.state.roiObjectsArray.find(
              (f) => f.serviceID === this.state.selectedServiceID
            )
          }
        />
        <ImportantInformationModal
          services={this.state.services}
          selectedServices={this.state.selectedServices}
          setAccepted={() => this.setState({ savedImportantInformation: true })}
        />
        <InformedConsentModal
          legalName={this.state.legalName}
          selectedServices={this.state.selectedServices}
        />
        <ConfirmationMessage
          email={this.state.patientInformation.PatientEmail}
          fname={this.state.patientInformation.PatientFirstName}
          lname={this.state.patientInformation.PatientLastName}
          DOB={parseDate(this.state.patientInformation.PatientDOB)}
          phone={this.state.patientInformation.PatientPhone}
          onSubmit={this.sendForm}
        />
        <SubmissionFileUploadModal 
            ref={this.submissionFileRef}
            clearParentState={() => this.setState({submissionFiles: []})}
            handleSubmit={(e) => this.setState({submissionFiles: e, savedFileUpload: true})}
        />
        <div className="container-fluid  min-vh-100 ">
          <div className={"row"}>
            <div className=" col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
              <AppointmentForm
                serviceLabels={this.state.serviceLabels}
                passClearStateFunc={this.assignClearState}
                schedMap={schedMap}
                onChange={(e) => {
                  this.handleServiceChange(e);
                  this.setState({ location: e });
                }}
              />

              {/* important information section */}
              {this.state.location.time &&
                this.state.selectedServices?.length > 0 && (
                  <div className="mb-2">
                    <div className="card mt-2">
                      <div className="card-header verlag-bold">
                        <div className="row">
                          <div className="col-10">
                            <h4>
                              Important Information
                            </h4>
                          </div>
                          <div className="col-2">
                          {this.state.savedImportantInformation ? (
                            <>
                              {/* <span className="float-right pl-2">
                                <BsCheckCircle color={"#1E6111"} size={37} />
                              </span> */}
                              <button
                                aria-label={"Important Information Review"}
                                className={"btn btn-success verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showModalNoOutsideClick(
                                    ImportantInformationModal.ID
                                  )
                                }
                              >
                                Review
                              </button>
                            </>
                          ) : (
                            <button
                              aria-label={"Important Information Start"}
                              className={"btn btn-primary verlag-bold"}
                              style={{ float: "right" }}
                              onClick={() =>
                                showModalNoOutsideClick(
                                  ImportantInformationModal.ID
                                )
                              }
                            >
                              Start
                            </button>
                          )
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
                          
                        
                          

              {/* patient information section */}
              {this.state.location.time &&
                this.state.selectedServices.length > 0 && (
                  <div className="mb-2">
                    <div className="card mt-2">
                      <div className="card-header verlag-bold">
                        <div className="row">
                          <div className="col-10">
                            <h4>
                              Employee Information
                            </h4>
                          </div>
                          <div className="col-2">
                          {this.state.savedPatientInformation ? (
                            <>
                              {/* <span className="float-right pl-2">
                                <BsCheckCircle color={"#1E6111"} size={37} />
                              </span> */}
                              <button
                                aria-label={"Patient Information Review"}
                                className={"btn btn-success verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showModalNoOutsideClick(PatientInfoModal.ID)
                                }
                              >
                                Review
                              </button>
                            </>
                          ) : (
                            <button
                              aria-label={"Patient Information Start"}
                              className={"btn btn-primary verlag-bold"}
                              style={{ float: "right" }}
                              onClick={() =>
                                showModalNoOutsideClick(PatientInfoModal.ID)
                              }
                            >
                              Start
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
                          
                        
                          

              {/* insurance section */}
              {this.state.location.time && this.state.showInsurance && (
                <div className={"mt-2"}>
                  <div className="card mt-2">
                    <div className="card-header verlag-bold">
                      <div className="row">
                        <div className="col-10">
                          <h4 className="mb-0">
                            Insurance Information
                          </h4>
                        </div>
                        <div className="col-2">
                        {this.state.savedInsuranceInformation ? (
                          <>
                            {/* <span className="float-right pl-2">
                              <BsCheckCircle color={"#1E6111"} size={37} />
                            </span> */}
                            <button
                              aria-label={"Insurance Information Review"}
                              className={"btn btn-success verlag-bold"}
                              style={{ float: "right" }}
                              onClick={() =>
                                showModalNoOutsideClick(InsuranceModal.ID)
                              }
                            >
                              Review
                            </button>
                          </>
                        ) : (
                          <button
                            aria-label={"Insurance Information Start"}
                            className={"btn btn-primary verlag-bold"}
                            style={{ float: "right" }}
                            onClick={() =>
                              showModalNoOutsideClick(InsuranceModal.ID)
                            }
                          >
                            Start
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )}
              {this.state.location.time && this.state.showFileUpload && (          
              <div className="card mb-2  mt-2">
                  <div className="card-header verlag-bold">
                      <div className="row">
                          <div className="col-10">
                              <h4>File Upload</h4>
                          </div>
                          <div className="col-2">
                          {this.state.savedFileUpload ?
                          <>
                              <button 
                              aria-label={"Submission Files Review"}
                              className={"btn btn-success verlag-bold"}
                              style={{float: 'right'}}
                              onClick={() => showModalNoOutsideClick('submissionfileupload')}>
                                  Review
                              </button>
                          </>
                          :
                          <button 
                              aria-label={"Service Information Start"}
                              className={"btn btn-primary verlag-bold"}
                              style={{float: 'right'}}
                              onClick={() => showModalNoOutsideClick('submissionfileupload')}>
                              Start
                          </button>
                          }
                      
                      </div>
                      </div>
                  </div>
              </div>
              )}    

              {/* services section */}
                {this.state.location.time &&
                this.state.selectedServices?.length > 0 &&
                this.state.selectedServices.map((ss) => {
                  if (
                    this.state.allCustomQuestions.find(
                      (f) => f.ServiceID === ss.ID && f.Options.isActive
                    )
                  ) {
                    return (
                      <div className="card mt-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4>
                                {getNameFromID(ss.ID, this.state.services)}
                              </h4>
                            </div>
                            <div className="col-2">
                              {this.state.savedServices &&
                              this.state.savedServices.includes(ss.ID) ? (
                                <>
                                  <button
                                    aria-label={"Service Information Review"}
                                    className={"btn btn-success verlag-bold"}
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      this.setState(
                                        {
                                          selectedServiceID: ss.ID,
                                          serviceSpecificCustomQuestions:
                                            this.state.allCustomQuestions.filter(
                                              (f) =>
                                                f.ServiceID === ss.ID &&
                                                f.Options.isActive
                                            ),
                                        },
                                        () =>
                                          showModalNoOutsideClick(
                                            ServiceFormModal.ID
                                          )
                                      );
                                    }}
                                  >
                                    Review
                                  </button>
                                </>
                              ) : (
                                <button
                                  aria-label={"Service Information Start"}
                                  className={"btn btn-primary verlag-bold"}
                                  style={{ float: "right" }}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        selectedServiceID: ss.ID,
                                        serviceSpecificCustomQuestions:
                                          this.state.allCustomQuestions.filter(
                                            (f) =>
                                              f.ServiceID === ss.ID &&
                                              f.Options.isActive
                                          ),
                                      },
                                      () =>
                                        showModalNoOutsideClick(
                                          ServiceFormModal.ID
                                        )
                                    );
                                  }}
                                >
                                  Start
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}})}
                
              {/* ROI section */}
              {this.state.location.time &&
                this.state.selectedServices?.length > 0 && (
                  <div className="mb-2">
                    <div className="card mt-2">
                      <div className="card-header verlag-bold">
                        <div className="row">
                          <div className="col-10">
                            <h4>
                              Release of Information
                            </h4>
                          </div>
                          <div className="col-2">
                          {this.state.savedROIServices?.length > 0 || this.state.declineConsent ? (
                            <>
                              {/* <span className="float-right pl-2">
                                <BsCheckCircle color={"#1E6111"} size={37} />
                              </span> */}
                              <button
                                aria-label={"Release of Information Review"}
                                className={"btn btn-success verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showModalNoOutsideClick(
                                    ROIModal.ID
                                  )
                                }
                              >
                                Review
                              </button>
                            </>
                          ) : (
                            <button
                              aria-label={"Release of Information Start"}
                              className={"btn btn-primary verlag-bold"}
                              style={{ float: "right" }}
                              onClick={() =>
                                showModalNoOutsideClick(
                                  ROIModal.ID
                                )
                              }
                            >
                              Start
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
                          
                        
                          

                      
                        

              {this.state.location.time &&
                this.state.selectedServices.length > 0 && (
                  //pass in services so we can display summary per service on ConsentSummary

                  <ConsentSummary
                    legalName={this.state.legalName}
                    onAcknowledge={(e) => this.setState({ acknowledged: e })}
                    onSubmit={this.submit}
                    facility={
                      this.state.location.facility
                        ? this.state.location.facility.value
                        : null
                    }
                    day={
                      this.state.location.day
                        ? this.state.location.day.value.day
                        : null
                    }
                    time={
                      this.state.location.time
                        ? this.state.location.time.value
                        : null
                    }
                    services={this.state.selectedServices}
                  />
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PatientForm;
