import {getNameFromID, parseDate, toLocalDateTime} from "../../util/FormatUtil";
import React from "react";
import {Sample} from "../../types/Sample";
import Parser from 'html-react-parser';
import TestType from "../../types/TestType"
import {TestReport, TestReportAddtlInfo} from "../../types/TestReport";
import { Result } from "../../types/Result";

interface BasicReportProps{
    result?:TestReportAddtlInfo
    services?
    branding?
}
interface BasicReportState{

}

export default  class BasicReport extends React.Component<BasicReportProps, BasicReportState>{

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount(): void {
        document.body.style.backgroundImage = ``;
        document.body.style.backgroundColor = '#FFFFFF'
    }

    buildResultDescription(result){
        let foo;
        foo = result.Result.split(';').map((d) => {
            let isPositive = false;
            if(d.includes('Positive')){ isPositive = true; }
            return (
                <div>
                    <p className={(result.HighlightResult && isPositive ? result.HighlightColor : null)}>{d}</p>
                    {/* <p className={(result.HighlightResult && isPositive ? result.HighlightColor : null)}>*****</p> */}
                </div>
            )
        })
        return foo;
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        
        // console.log('props in basic report', this.props)
        let result = this.props.result;
        //console.log(result, "RESULT")
        if(result.hasOwnProperty('TestsAndResultsMap')){

        return (

            //TODO Refactor this after North Dakota DEMO!!!!!



            <>
                {result.TestsAndResultsMap.map((r, i) => {
                    let currentResult = result.Results.find(r => r.ID === result.TestsAndResultsMap[i].ResultID)
                    let currentTest = result.Tests.find(t => t.ID === result.TestsAndResultsMap[i].TestID)
                    return (
                        <>
                        <div className="row border  min-vh-100 mb-5">
                            <div className="col-12 text-center verlag-bold pt-2">
                            <img src={this.props.branding.ProductAltLogoURL}  height={90} className="d-inline-block align-top nav-href pr-2" alt=""/>
                            </div>
                            <div className="col-12 responsive-p m-3">
                                <table className="table table-borderless">
                                    <tbody>
                                    <tr>
                                    <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Test</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result.Tests && result.Tests.length > 0 ? currentTest.Name : ""}</td>
                                            </div>
                                        </div>
                                    </tr>

                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Test Report Date</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{parseDate(result.TestResultDate)}</td>
                                            </div>
                                        </div>
                                    </tr>

                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Patient Name</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result.PatientLastName.toUpperCase()} , {result.PatientFirstName.toUpperCase()}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Patient DOB</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{parseDate(result.PatientDOB)}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Gender</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result.PatientGender}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Patient Phone</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result.PatientPhone}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Patient Address</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result["PatientAddress"]}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Ordering Facility</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result.Facility}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Specimen ID</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result.SpecimenID}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Source</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result.SpecimenSource}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Collection Date</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{toLocalDateTime(result.CollectionDate)}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="row">
                                            <div className="col-3">
                                                <th></th>
                                            </div>
                                            <div className="col-3">
                                                <th style={{textAlign: 'left'}}>Ordering Physician</th>
                                            </div>
                                            <div className="col-6">
                                                <td style={{textAlign: 'left'}}>{result.ProviderFirstName + " " + result.ProviderLastName}</td>
                                            </div>
                                        </div>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div className={"col-12 col-md-12  text-center border responsive-p verlag-bold "} >
                                <h2>Result:</h2>
                                {/* { result.Result && result.Result.includes(';') ? this.buildResultDescription(result) : 
                                    <div>
                                        <p className={(result.HighlightResult ? result.HighlightColor : null)}>{result.Result}</p>
                                        <p className={(result.HighlightResult ? result.HighlightColor : null)}>*****</p>
                                    </div>
                                } */}

                                <div>
                                    <p className={(currentResult && currentResult.EnableHighlighting ? currentResult.HighlightColor : null)}>{currentResult.Value}</p>
                                    <p className={(currentResult && currentResult.EnableHighlighting ? currentResult.HighlightColor : null)}>*****</p>
                                </div>
                            </div>
                            <div className={"col-12  pt-2"}>
                                {this.props.result && this.props.result.Tests && this.props.result.Tests.length > 0
                                && currentTest.ReportDescription ? Parser(currentTest.ReportDescription) : ""}
                            </div>
                            <div className="col-6  pt-2 text-center responsive-p verlag-bold" style={{border: '1px solid', borderColor: this.props.branding.buttonColorHexValue}}>
                                <p>{this.props.result.ReportEntityName}</p>
                                <p>{this.props.result.ReportAddress}</p>
                                {this.props.result.ReportHelpEmail ? <React.Fragment>
                                    <p>E-Mail:</p>
                                    <p>{this.props.result.ReportHelpEmail}</p>
                                </React.Fragment>: null}
                            </div>
                            {this.props.result.Tests && this.props.result.Tests.length > 0 ?
                                <div className="col-6  pt-2 text-center responsive-p verlag-bold"  style={{border: '1px solid',borderColor: this.props.branding.ProductButtonColorHexValue}}>
                                    <h3>CLIA: {this.props.result.Clia}</h3>
                                    <br/>
                                    <p>Laboratory Director</p><p>
                                    {this.props.result.ReportDirector}</p>
                                </div>
                                : null}
                        </div>
                        </>
                    )
                })}
            </>
        )} else {
            return (

                //TODO Refactor this after North Dakota DEMO!!!!!
    
                
    

                            <>
                            <div className="row border  min-vh-100 mb-5">
                                <div className="col-12 text-center verlag-bold pt-2">
                                <img src={this.props.branding.ProductAltLogoURL}  height={90} className="d-inline-block align-top nav-href pr-2" alt=""/>
                                </div>
                                <div className="col-12 responsive-p m-3">
                                    <table className="table table-borderless">
                                        <tbody>
                                        <tr>
                                        <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>{result.VaccineID ? 'Vaccine' : 'Treatment'}</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{result.VaccineID ? result.vaccines[0].Name : result.treatments[0].Name}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>{result.VaccineID ? 'Vaccine' : 'Treatment'} Date</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{parseDate(result.LastUpdatedDate)}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        {result.VaccineID ? 
                                            <>
                                            <tr>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <th></th>
                                                    </div>
                                                    <div className="col-3">
                                                        <th style={{textAlign: 'left'}}>Vaccine Expiration Date</th>
                                                    </div>
                                                    <div className="col-6">
                                                        <td style={{textAlign: 'left'}}>{parseDate(result.VaccineExpDate)}</td>
                                                    </div>
                                                </div>
                                            </tr>
                                            <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>Lot Number</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{result.LotNumber}</td>
                                                </div>
                                            </div>
                                            </tr>
                                            <tr>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <th></th>
                                                    </div>
                                                    <div className="col-3">
                                                        <th style={{textAlign: 'left'}}>Injection Site</th>
                                                    </div>
                                                    <div className="col-6">
                                                        <td style={{textAlign: 'left'}}>{result.InjectionSite.Location}</td>
                                                    </div>
                                                </div>
                                            </tr>
                                            </>
                                        : <></>}
                                        <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>Patient Name</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{result.PatientLastName.toUpperCase()} , {result.PatientFirstName.toUpperCase()}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>Patient DOB</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{parseDate(result.PatientDOB)}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>Gender</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{result.PatientGender}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>Patient Phone</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{result.PatientPhone}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>Patient Address</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{result["PatientAddress"]}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>Facility</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{result.Facility}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        <tr>
                                            <div className="row">
                                                <div className="col-3">
                                                    <th></th>
                                                </div>
                                                <div className="col-3">
                                                    <th style={{textAlign: 'left'}}>{result.VaccineID ? 'Nurse' : 'Provider'}</th>
                                                </div>
                                                <div className="col-6">
                                                    <td style={{textAlign: 'left'}}>{result.ProviderFirstName + " " + result.ProviderLastName}</td>
                                                </div>
                                            </div>
                                        </tr>
                                        </tbody>
                                    </table>
    
                                </div>
                                <div className="col-12  pt-2 text-center responsive-p verlag-bold" style={{border: '1px solid', borderColor: this.props.branding.buttonColorHexValue}}>
                                    <p>{this.props.result.ReportEntityName}</p>
                                    <p>{this.props.result.ReportAddress}</p>
                                    {this.props.result.ReportHelpEmail ? <React.Fragment>
                                        <p>E-Mail:</p>
                                        <p>{this.props.result.ReportHelpEmail}</p>
                                    </React.Fragment>: null}
                                </div>
                            </div>
                </>
            )
        }
    }
}