import React from "react";
import { sweetalert } from "../../App";
import {
  hideModal,
  showModalNoOutsideClick,
} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { FaCameraRetro } from "react-icons/fa";
import BarcodeScannerComponent from "../BarcodeScannerComponent";
import FilterCard, { FilterPart } from "../FilterCard";
import GetField from "../../customfields/GetField";
import domain from "../../Domain";
import ReleaseConsent from "../modals/ReleaseConsent";
import Parser from "html-react-parser";
import InBetweenOverlay from "../InBetweenOverlay";
import { isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";

interface ROIModalProps {
  attachClearStateFunc?:(func) => void
  consenting?: any;
  releaseChanged: (release: any) => void;
  service?;
  services?;
  selectedServices?
  consentEntities;
  handleRemove?
  handleRemoveAll?
  reviewInfo?
}

interface ROIModalState {
  release?: any;
  entity?: { ID: number; FacilityID: number; Name: string; AccessCode: string };
  entities?
  accepted: boolean;
  showLoading: boolean;
  showInbetween: boolean;
  arrowClicked?
  selectedService?
}

export class ROIModal extends React.Component<ROIModalProps, ROIModalState> {
  public static ID = "roi";
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showInbetween: false,
      // consentEntities: [],
      accepted: false,
      arrowClicked: -1,
      entities: []
    };
    this.searchForAccessCode = this.searchForAccessCode.bind(this);
    this.onConfirmation = this.onConfirmation.bind(this);
  }

  clearState = () => {
    this.setState({
      release: null,
      entity: null,
      entities: [],
      accepted: false,
      showLoading: false,
      showInbetween: false,
      arrowClicked: -1,
      selectedService: null
    })
    Array.from(document.querySelectorAll("input")).forEach((input) => {
      if (input.name == "accessCodeInput"){
          return (input.value = "");
      } 
    }); 
  }



  searchForAccessCode(ss) {
    this.setState({selectedService: ss})
    let accessCode = this.state.release;
    let consentEntity = this.props.consentEntities.find(
      (entity) => entity.AccessCode === accessCode
    );

    if (accessCode && accessCode != "" && consentEntity) {
      this.setState({ entity: consentEntity }, () => {
        if (
          this.state.entity &&
          this.state.entity.AccessCode &&
          this.state.entity.AccessCode !== ""
        ) {
            this.setState({showInbetween: true})
          showModalNoOutsideClick(ReleaseConsent.ID);
        }
      });
    }
    if (accessCode && accessCode != "" && !consentEntity) {
      sweetalert.fire({
        icon: "warning",
        text: "Invalid Access Code.",
        title: "",
      });
    }
    if(!accessCode || isBlank(accessCode) || isOnlyWhitespace(accessCode)){
        sweetalert.fire({
            icon: "warning",
            text: "Access Code cannot be blank.",
            title: "",
          });
    }
  }

  onConfirmation(e) {
    if (!e.accepted) {
      this.setState({ accepted: false, showInbetween: false });
      Array.from(document.querySelectorAll("input")).forEach((input) => {
        if (input.name == "accessCodeInput") return (input.value = "");
      });
      this.props.handleRemove({entity: this.state.entity.ID, serviceID: e.serviceID});
    } else {
      this.setState({ accepted: true, showInbetween: false });
      let entities = this.state.entities;
      entities.push({entityID: this.state.entity.ID, serviceID: e.serviceID})
      this.setState({entities: entities})
      this.props.releaseChanged({
        entityID: this.state.entity.ID,
        serviceID: e.serviceID,
      });
    }
  }

  componentDidMount(): void {
    $(`#${ROIModal.ID}`).on('shown.bs.modal', function() {
      $(document).off('focusin.modal');
  });
  }


  render() {
    return (
      <React.Fragment>
        <ReleaseConsent
          onConfirmation={(didAccept) => this.onConfirmation(didAccept)}
          service={this.state.selectedService}
          entity={
            this.state.entity
              ? this.state.entity
              : { ID: null, FacilityID: null, Name: "", AccessCode: "" }
          }
        />
        <Overlay show_loading={this.state.showLoading} zIndex={100005} />
        <InBetweenOverlay
          zIndex={100006}
          showInBetween={this.state.showInbetween}
        />
        <div
          className="modal fade form_modal"
          id={ROIModal.ID}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="result_modal_label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-12 pt-2">
                      <div className="card mb-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4>
                                Release of Information <span className={"text-secondary"} style={{fontSize: '1rem'}}>*optional</span>
                              </h4>
                            </div>
                            <div className="col-2">
                              <button
                                style={{ outline: "none" }}
                                type="button"
                                className="close pr-4"
                                aria-label="Close"
                                onClick={() => hideModal(ROIModal.ID)}
                              >
                                <span
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "1.5em",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &times;
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">

                          <div className="accordion" id="roi">
                            {this.props.selectedServices && this.props.selectedServices.map((ss, i) => (

                                    <div className="card mt-2">
                                        

                                            
                                                <button
                                                    className={"card-header collapsed accordionButton w-100 verlag-bold"}
                                                    data-toggle="collapse"
                                                    data-target={'#collapse'+i}
                                                    id={'heading'+i}
                                                    onClick={() =>{
                                                        if(this.state.arrowClicked === i){
                                                            this.setState({
                                                                arrowClicked: -1
                                                            })
                                                        }
                                                        else{
                                                            this.setState({
                                                                arrowClicked: i
                                                            })
                                                        }
                                                    
                                                    }}
                                                    aria-expanded={"false"}
                                                    aria-controls={'collapse'+i}
                                                >
                                                  <div className="row d-flex justify-content-between">
                                                    <div className="px-3">
                                                      <h4>
                                                        {ss.Name}
                                                      </h4>
                                                    </div>
                                                    <div className="col-2">
                                                      {this.state.arrowClicked == i ? (
                                                          <AiOutlineUp
                                                          className={"float-right"}
                                                          size={30}
                                                          data-toggle="collapse"
                                                          data-target={'#collapse'+i}
                                                          role="button"
                                                          aria-expanded={i == 0 ? "true" : "false"}
                                                          aria-controls={'collapse'+i}
                                                          />
                                                      ) : (
                                                          <AiOutlineDown
                                                          className={"float-right"}
                                                          size={30}
                                                          data-toggle="collapse"
                                                          data-target={'#collapse'+i}
                                                          role="button"
                                                          aria-expanded={i == 0 ? "true" : "false"}
                                                          aria-controls={'collapse'+i}
                                                          />
                                                      )}
                                                    </div>
                                                  </div>
                                                </button>
                                            
                                       

                                        <div
                                        className={"collapse show verlag-light responsive-p"} style={{borderBottom: '1px solid lightgrey'}}
                                            id={'collapse'+i} aria-labelledby={'heading'+i} data-parent="#roi"
                                        >
                                            <div className="card-body">
                                                {ss.ReleaseOfInformation && Parser(ss.ReleaseOfInformation)}

                                                <div className="form-group row pr-3">
                                                  <label htmlFor="accessCode" className="col-sm-4 col-form-label">
                                                    Access Code
                                                  </label>
                                                  <div className="col-sm-8  p-0 m-0">
                                                    <input
                                                      id={'accessCode'}
                                                      readOnly={!!this.state.entities?.find(e => e.serviceID === ss.ID)}
                                                      type={"search"}
                                                      name={"accessCodeInput"}
                                                      min={10}
                                                      max={10}
                                                      className={"form-control"}
                                                      value={!!this.state.entities?.find(e => e.serviceID === ss.ID) ? 'Authorized' : this.state.release}
                                                      autoComplete="off"
                                                      onChange={(e) => {
                                                          this.setState({release: e.target.value})
                                                      }}
                                                    />
                                                    {!!this.state.entities?.find(f => f.serviceID === ss.ID) && (
                                                      <div
                                                        className="alert alert-primary m-0"
                                                        role="alert"
                                                      >
                                                        <p className="primary-black m-0">
                                                          You have authorized {this.props.consentEntities?.find(c => c.ID === this.state.entities?.find(e => e.serviceID === ss.ID).entityID).Name}{" "}
                                                          to access to your test results for this
                                                          service.
                                                        </p>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                            </div>
                                            {this.state.entities?.find(f => f.serviceID === ss.ID) ? 
                                              <div
                                                className="card-footer"
                                                style={{
                                                  backgroundColor: "transparent",
                                                  borderTop: "none",
                                                }}
                                              >
                                                <button
                                                  className={"btn btn-primary float-left mb-3"}
                                                  onClick={() => {
                                                    this.setState({accepted: false, entity: null, release: null});
                                                    if(this.state.entities.length === 1 || this.state.entities.length < 1){
                                                      this.setState({entities: []})
                                                    }
                                                    else{
                                                      let splicedEntities = this.state.entities;
                                                      for (let i = 0; i < splicedEntities.length; i++) {
                                                        if(splicedEntities[i].serviceID === ss.ID){
                                                          splicedEntities.splice(i, 1)
                                                          break;
                                                        }
                                                      }

                                                      this.setState({entities: splicedEntities})
                                                    }
                                                    Array.from(document.querySelectorAll("input")).forEach((input) => {
                                                        if (input.name == "accessCodeInput"){
                                                            return (input.value = "");
                                                        } 
                                                    }); 
                                          
                                                      this.props.handleRemove({serviceID: ss.ID})
                                                }}
                                                >
                                                  Remove
                                                </button>
                                                {/* <button
                                                  className={"btn btn-primary float-right mb-3"}
                                                  onClick={() => {this.props.releaseChanged({entity: this.state.entity, serviceID: this.props.service.ID}); hideModal(ROIModal.ID)}}
                                                >
                                                  Save
                                                </button> */}
                                              </div>
                                              :
                                              <div
                                                className="card-footer"
                                                style={{
                                                  backgroundColor: "transparent",
                                                  borderTop: "none",
                                                }}
                                              >
                                                <button
                                                  className={"btn btn-primary float-right mb-3"}
                                                  onClick={() => this.searchForAccessCode(ss)}
                                                >
                                                  Search
                                                </button>
                                              </div>
                                            }
                                        </div>
                                    </div>
                                    
                                ))}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card-footer"
                    style={{
                      backgroundColor: "transparent",
                      borderTop: "none",
                    }}
                  >
                    <button
                      className={"btn btn-primary float-left mb-3"}
                      onClick={() => {
                        this.setState({accepted: false, entity: null, release: null, entities: []});
                        this.props.handleRemoveAll()
                        hideModal(ROIModal.ID)
                        // Array.from(document.querySelectorAll("input")).forEach((input) => {
                        //     if (input.name == "accessCodeInput"){
                        //         return (input.value = "");
                        //     } 
                        // }); 
                        // sweetalert.fire({
                        //     icon: "info",
                        //     text: "Authorization declined",
                        //     title: "",
                        //   }).then(x => {
                        //     this.props.handleRemoveAll()
                        //     hideModal(ROIModal.ID)
                        //   });
                    }}
                    >
                      Decline All
                    </button>
                    <button
                      className={this.state.entities?.length > 0 ? "btn btn-primary float-right mb-3" : "invisible"}
                      onClick={() =>  hideModal(ROIModal.ID)}
                    >
                      Save
                    </button>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
