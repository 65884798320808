import React from "react";

class Footer extends React.Component<any, any>{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (

            <footer className="container-fluid p-0 m-0 ">
                <hr className={"p-0 m-0"}/>
                    <div className="container-fluid">
                        <div className="row text-center text-light">
                            <div className="col-1">
                                {/*<p>&copy; Copyright 2020 by IMMY. All Rights Reserved.</p>*/}
                            </div>
                            <div className="col-10">
                                <p>&copy; Copyright {new Date().getFullYear()} by IMMY Technology Group. All Rights Reserved.</p>
                            </div>
                            <div className="col-1">
                            </div>
                        </div>
                    </div>
            </footer>
        )
    }
}
export default Footer;