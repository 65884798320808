import React from "react";
import PatientForm from "./PatientForm";
import FilterCard, {FIELD_TYPE} from "./FilterCard";
import domain from "../Domain";
import {sweetalert} from "../App";
import Overlay from "./Overlay";

interface PrivatePodState{
    showForm:boolean,
    code:string,
    schedMap?:any,
    showLoading:boolean
}
class PrivatePod extends React.Component<{ }, PrivatePodState> {

    constructor(props) {
        super(props)
        this.state = {
            showForm:false,
            code:"",
            schedMap:{},
            showLoading:false
        }
        this.findAppointment = this.findAppointment.bind(this);
    }

    async findAppointment(){
        // let recaptcha = await load(CAPTCHA);
        // let token = await recaptcha.execute('homepage');
        this.setState({showLoading:true, showForm: false}, ()=>{
            fetch(domain + "/api/events/private", {
                method:"POST",
                body:JSON.stringify({code:this.state.code, token:""}),
                headers: {'Content-Type': 'application/json'}
            }).then(res =>res.json()).then(data =>{
                if(data.success){
                    this.setState({schedMap:data.schedMap, showForm:true, showLoading:false})
                }else{
                    this.setState({showLoading:false})
                    return sweetalert.fire({icon: 'error', text: data.reason});
                }
                // if(!data.schedMap) {
                //     this.setState({showLoading:false})
                //     return alert("No pod found. Please double check your code.");
                // }
                // if(data.schedMap){
                //     this.setState({schedMap:data.schedMap, showForm:true, showLoading:false})
                // }
            }).catch((e) =>{
                console.error(e);
                this.setState({showLoading:false});
            });
        })
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>
                <div className={"container-fluid " + (this.state.showForm ? "" : "min-vh-100")}>
                    <div className={"row"}>
                        <div className=" col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
                        <div className="card mt-2">
                            <div className="card-header verlag-bold">
                                <h4>Private Testing Appointment</h4>
                            </div>
                            <div className="card-body">
                                <p>If you were sent here by a school or employer, please use the code you were given. If you were not sent here for a private testing event, you may see the list of public appointments <a href={"/appointment"}>here</a>.</p>
                                <FilterCard fields={[{label:"Appointment Code", key:"code", type:FIELD_TYPE.TEXT}]} filterChanged={(f => this.setState(f))}/>
                            </div>
                            <div className="card-footer"><button className={"btn btn-outline-primary"} onClick={this.findAppointment}>Find</button></div>
                        </div>
                        </div>
                    </div>
                </div>
                {this.state.showForm ?  <PatientForm isPrivate={true} schedMap={this.state.schedMap}/> : null }

            </React.Fragment>
        )
    }

}
export default PrivatePod;