import React from "react";
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import Overlay from "../Overlay";
import {toLocalDateTime, getBoolSelectOptions, getBoolSelectVal, parseDate, getNumPages, getPageOptions, hideModal, showModalNoOutsideClick, slicePages, removeNull} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import SystemAPI from "../../network/SystemAPI";
import ServicesAPI from "../../network/ServicesAPI";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import {SampleTestReportModal} from "../modals/SampleTestReportModal";
import InBetweenOverlay from "../InBetweenOverlay";
import VaccineType from "../../types/VaccineType";
import VaccineRecordsAPI from "../../network/VaccineRecordsAPI";
import { VaccineRecordsModal } from "../modals/VaccineRecordsModal";
import RecordType, { RecordFilter } from "../../types/RecordType";
import VaccineRecordsFilterTabs from "./VaccineRecordsFilterTabs";
import AdminAPI from "../../network/AdminAPI";

const emptyRecordState = {
    ID: null,
    Vaccine: null,
    LotNumber: null,
    InjectionSite: null,
    NurseFirstName: null,
    RecordDate: null,
    PatientFirstName: null,
    PatientMiddleName: null,
    PatientLastName: null,
    PatientAddress: null,
    PatientZip: null,
    PatientCity: null,
    PatientState: null,
    PatientyCounty: null,
    PatientCountry: null,
    PatientPhone: null,
    PatientEmail: null,
    NurseLastName: null,
    NurseLicenseNum: null,
    PatientID: null,
    ReqNum: null,
    LastUpdatedBy: null,
    LastUpdatedDate: null,
    CreatedDate: null,
    CreatedBy: null,
    InsuredAddressLine1: null,
    InsuredAddressLine2: null,
    InsuredBackCardURL: null,
    InsuredCity: null,
    InsuredDOB: null,
    InsuredFirstName: null,
    InsuredFrontCardURL: null,
    InsuredLastName: null,
    InsuredMRN: null,
    InsuredSSN: null,
    InsuredState: null,
    InsuredZip: null,
    VaccineExpDate: null,
    VaccineModalOpen: false
}

const ITEMS_PER_PAGE = 25;

interface VaccineRecordsState {
    selectedVaccine?;
    selectedRecord?;
    vaccines
    vaccinesForLabels
    testDescription?
    name?
    shortName?
    isEnabled?:{label, value}
    showLoading?
    showInBetween?
    resultTypes:{label, value}[]
    allResults
    specimenSources:{label, value}[]
    pageCustomQuestions?
    filter?:RecordFilter
    tableData:{}[]
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    recordQueryResults:RecordType[]
    countries?
    states?
    race?
    gender? 
    ethnicity?
    injectionSites?
    services?
    VaccineModalOpen
    facilities
}
// export interface PostedVaccine extends VaccineType{
//     ResultIDs:number[]
//     SpecimenIDs:number[]
// }

export class VaccineRecords extends React.Component<any, VaccineRecordsState> {

  private VaccineRecordsFilterTabsRef: any;

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedVaccine: {} as VaccineType,
            vaccines: [], 
            resultTypes:[],
            allResults: [],
            specimenSources:[],
            pageCustomQuestions: [],
            VaccineModalOpen: false,
            filter: {
              Vaccine: "",
              LotNum: "",
              InjectionSite: "",
              NurseFirstName: "",
              PatientFirstName: "",
              PatientMiddleName: "",
              PatientLastName: "",
              PatientZip: "",
              PatientCity: "",
              PatientState: "",
              PatientCounty: "",
              PatientCountry: "",
              PatientPhone: "",
              NullPatientPhone: "",
              PatientEmail: "",
              NurseLastName: "",
              NurseLicenseNum: "",
              PatientID: "",
              ReqNum: "",
              LastUpdatedBy: "",
              LastUpdatedDate: "",
              PatientRaceID: "",
              PatientGenderID: "",
              PatientEthnicityID: "",
              PatientDOB: "",
              NullPatientDOB: "",
              AppointmentDate: "",
              VaccineExpDate: "",
              NullVaccineExpDate:"",
              BeginCreatedDate: "",
              EndCreatedDate: "",
              NullCreatedDate: "",
              ApptCanceled: "",
              ServiceID: "",
              VaccineIDs: "",
              injectionSites: "",
            },
            facilities:"",
            vaccinesForLabels: "",
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            recordQueryResults: [] as any,
        }
        this.createOrModifyRecord = this.createOrModifyRecord.bind(this);
        this.VaccineRecordsFilterTabsRef = React.createRef();
    }


    addNullSelection(data) {
        return data.concat([
          { label: "No Data", value: "*null", color: '#C31230', fontWeight: 'bold' },
          { label: "Has Data", value: "<>null", color: '#21212E', fontWeight: 'bold' },
        ]);
    }

    componentDidMount() {
        document.title = 'Vaccine Records Page';
        this.setState({showLoading: true}, () => {
            // SystemAPI.getCustomQuestions(AdminPages.TestManagement).then(res =>{
            //     this.setState({pageCustomQuestions:res.questions})
            // })
            SystemAPI.getAllVaccines().then(data => {
                this.setState({vaccines: this.addNullSelection(data), vaccinesForLabels: data})
            })
            SystemAPI.getAllInjectionSites().then(data => {
              this.setState({injectionSites: this.addNullSelection(data)})
            })
            SystemAPI.getAllCountries().then((data) => {
              this.setState({ countries: this.addNullSelection(data)});
            });
            SystemAPI.getAllStates().then((data) => {
                this.setState({ states: this.addNullSelection(data) });
            });
            SystemAPI.getAllRaces().then((data) => {
                this.setState({ race: this.addNullSelection(data) });
            });
            SystemAPI.getAllGenders().then((data) => {
                this.setState({ gender: this.addNullSelection(data) });
            });
            SystemAPI.getAllEthnicities().then((data) => {
            this.setState({ ethnicity: this.addNullSelection(data), showLoading: false });
            });
            SystemAPI.getAllInjectionSites().then(data => {
                this.setState({injectionSites: this.addNullSelection(data)})
              })
            ServicesAPI.getServicesForAppointmentForm().then((data) => {
                let mappedData = data.data.map(s => {return {label: s.Name, value: s.ID}})
                this.setState({
                  services: this.addNullSelection(mappedData),
                });
            });
            AdminAPI.getFacilitiesForAppointmentForm().then((data) => {
                let facilityNamesMapped = data.authorizedFacilities.map((fac) => {
                return {
                    label: fac.FacilityName,
                    value: fac.ID,
                };
                });

                // let facilityGroupsMapped = data.authorizedFacilities.filter((f) => {
                // let facilityIsConsentEntity = f.FacilitySeq != 1 && f.OktaID != null;
                // if (facilityIsConsentEntity || f.FacilitySeq === 1) return f;
                // });

                // facilityGroupsMapped = facilityGroupsMapped.filter(
                // (f) => f.FacilitySeq === 1
                // );
                // let facilityGroupsMappedNew = facilityGroupsMapped.map((fac) => {
                // return {
                //     label: fac.FacilityGroup,
                //     value: fac.FacilityGroup,
                // };
                // });
                // facilityGroupsMappedNew = facilityGroupsMappedNew.sort((a, b) => {
                // let firstIndex = a.value.indexOf(" ");
                // let first = a.value.slice(firstIndex);
                // let secondIndex = b.value.indexOf(" ");
                // let second = b.value.slice(secondIndex);
                // return first - second;
                // });
                this.setState({
                facilities: this.addNullSelection(facilityNamesMapped),
                // facilityGroups: this.addNullSelection(facilityGroupsMappedNew),
                //facilitiesForSampleModal: data.authorizedFacilities.map(f => {return {label: f.FacilityName, value: f.ID}}),
                showLoading: false,
                });
            });
        })
    }

    
    renderVaccineRecordsFilterFields(){
        return (
            <FilterCard fields={[
                {
                    label: "First Name",
                    "key": "PatientFirstName",
                    type: FIELD_TYPE.TEXT,
                    textType: 'text'
                }, 
                {
                  label: "Last Name",
                  "key": "PatientLastName",
                  type: FIELD_TYPE.TEXT,
                  textType: 'text'
                }, 
                {
                  label: "Confirmation Number",
                  "key": "ReqNum",
                  type: FIELD_TYPE.TEXT,
                  textType: 'text'
                }, 
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    

    queryRecord(page:number) {
        this.setState({showLoading:true}, async () => {
            try {
                let results = await VaccineRecordsAPI.filterRecord({
                    filter: this.state.filter
                })

                if (results.data.length < 1) {
                    sweetalert.fire({
                      icon: "error",
                      title: "",
                      text: "No records found",
                    });
                    this.setState({ showLoading: false, tableData: []});
                    return;
                  }

                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter record data at this time', icon: 'error'})
                }
                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    recordQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                })
            } catch (e) {
                console.log(e)
                this.setState({showLoading: false})
            }
        })
    }



    createOrModifyRecord(record:RecordType, insuranceInformation, insuranceCards){


        this.setState({showLoading:true}, async() => {
            if(record.ID === null){
                try {
                    let result = await VaccineRecordsAPI.newRecord(record);
                    if(result.success){
                        sweetalert.fire({icon: 'success', title: '', text: 'Record saved'}).then(()=>{
                            this.setState({
                                selectedRecord: emptyRecordState
                            }, () => {
                                SystemAPI.getAllVaccines().then(data => {
                                    this.setState({vaccines: data, showLoading: false})
                                })
                                this.queryRecord(this.state.selected_page.value);
                            });
                        });
                    }
                    else{
                        sweetalert.fire({icon: 'error', title: '', text: result.reason});
                        this.setState({showLoading: false})
                    }
                }catch (e) {
                    console.log(e)
                    this.setState({showLoading: false})
                }
            } else {
                try{
                  console.log(record, "record")
                    let result = await VaccineRecordsAPI.editRecord(record, insuranceInformation, insuranceCards)
                    if(result.success){
                        sweetalert.fire({icon: 'success', title: '', text: 'Record saved'}).then(()=>{
                            this.setState({
                                selectedVaccine: {
                                    ID: null,
                                    Name: '',
                                    Manufacturer: '',
                                    IsEnabled: null,
                                } as VaccineType
                            }, () => {
                                SystemAPI.getAllVaccines().then(data => {
                                    this.setState({vaccines: data, showLoading: false})
                                })
                                this.queryRecord(this.state.selected_page.value);
                            });
                        });
                    }else{
                        sweetalert.fire({icon: 'error', title: '', text: result.reason});
                        this.setState({showLoading:false})
                    }
                }
                catch (e) {
                    console.log(e)
                    this.setState({showLoading:false})
                }
            }
        });
    }

    handleExportToCSV(){
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/records/csv",
                "Vaccine Records Data.xlsx",
                {filter: this.state.filter}
            )
            this.setState({showLoading: false})
        });
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()

        this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    changePage(page:number){
        let allTests = this.state.recordQueryResults;
        let returnData = slicePages(allTests, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    getRecordDetails(val){

        this.setState({showLoading: true}, async () => {
            try {
                let result = await VaccineRecordsAPI.queryRecord({ID: val.ID})
                //console.log(result, "RESULT")
                if (result.success) {
                    let record = result.record
                    //console.log(record)
                    this.setState({selectedRecord: record, VaccineModalOpen: true,
                    showLoading: false}, () => showModalNoOutsideClick(VaccineRecordsModal.ID))
                }
            } catch (e) {
                console.error(e)
                this.setState({showLoading: false})
            }
        })

    }

    render() {

        // console.log('TestMgmt state', this.state)

        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            {this.state.VaccineModalOpen && <VaccineRecordsModal
                facilities={this.state.facilities ? removeNull(this.state.facilities) : ""}
                vaccines={this.state.vaccines ? removeNull(this.state.vaccines): ""}
                selectedRecord={this.state.selectedRecord}
                states={this.state.states ? removeNull(this.state.states) : ""}
                countries={this.state.countries ? removeNull(this.state.countries) : ""}
                pageCustomQuestions={this.state.pageCustomQuestions}
                ethnicity={this.state.ethnicity ? removeNull(this.state.ethnicity) : ""}
                race={this.state.race ? removeNull(this.state.race) : ""}
                gender={this.state.gender ? removeNull(this.state.gender) : ""}
                onClose={() => {this.setState({VaccineModalOpen: false})}}
                onSubmit={(record:RecordType, insuranceInformation, insuranceCards) => {
                    hideModal(VaccineRecordsModal.ID);
                    //console.log(record, "RECORD")
                    this.createOrModifyRecord(record, insuranceInformation, insuranceCards);
                }}
                injectionSites={this.state.injectionSites ? removeNull(this.state.injectionSites) : ""}
                
            />}
            
            <SampleTestReportModal selectedTest={this.state.selectedVaccine} handleShowInBetween={() => this.setState({showInBetween: false})} />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 pt-2">
                        <main id="main-content" tabIndex={-1} aria-label="Vaccine Records">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Vaccine Records</h4>
                                </div>
                                <div className="card-body">
                                {/* <VaccineRecordsFilterTabs 
                                //@ts-ignore
                                ref={this.VaccineRecordsFilterTabsRef}
                                injectionSites={this.state.injectionSites}
                                vaccines={this.state.vaccines}
                                states={this.state.states}
                                countries={this.state.countries}
                                ethnicity={this.state.ethnicity}
                                race={this.state.race}
                                gender={this.state.gender}
                                services={this.state.services}
                                /> */}
                                    {this.renderVaccineRecordsFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline"}
                                            onClick={ () => {
                                            //   let returnedFilteredObj = this.VaccineRecordsFilterTabsRef.current.preSubmitChecks()
                                            //   if(!returnedFilteredObj){
                                            //     return 
                                            //   }
                                            //   else{
                                                this.setState({ selected_page: { label: 1, value: 1 }, filter: this.state.filter}, () => this.queryRecord(this.state.selected_page.value))
                                            //}
                                            }}
                                    >Search</button>
                                    <button
                                        className="btn mr-md-5 float-right verlag-bold immyClearButtonOutline"
                                        onClick={() => {
                                            this.VaccineRecordsFilterTabsRef.current.clear();
                                        }}
                                        >
                                        Clear Filters
                                    </button>
                                </div>
                            </div>
                        </main>
                    </div>
                    
                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-md-12 pt-2">
                                <div className="card mt-2 mb-5">
                                    <div className="card-header verlag-bold">
                                        <h4 className="text-center text-md-left">Vaccine Records
                                        <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                            <h4 role="alert" aria-label="Total Records" className={'float-md-right'}>Total: {this.state.recordQueryResults.length}</h4>
                                            <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                            <div className=" align-middle float-right pages ">
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={"1"}
                                                    noOptionsMessage={() => "No option"}
                                                    value={this.state.selected_page}
                                                    aria-label="Table Page Number"
                                                    onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                        () => this.changePage(e.value))}
                                                    className={"state_select"}
                                                    options={this.state.page_options}
                                                />
                                            </div>
                                        </section>
                                        <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                                onClick={() => this.handleExportToCSV()}
                                        >Export to CSV
                                        </button>
                                        </h4>
                                    </div>
                                    <div className="card-body p-0 m-0 table-responsive">
                                        <SimpleTable table_style='tableFixHead' columns={[
                                            {
                                                label: "Name", key: "Name",
                                                rawFormat: (val) => {
                                                    return <a href={"#!"} className={'tableNameLinkColor'} onClick={() => {
                                                        this
                                                        this.getRecordDetails(val)
                                                    }}>{val.PatientFirstName + " " + val.PatientLastName}</a>
                                                },
                                                popoverText: "Click to sort by Name"
                                            },
                                            {
                                            label: "Phone Number", key: "PatientPhone",
                                            popoverText: "Click to sort by Phone Number"
                                            },
                                            {
                                            label: "Confirmation Number", key: "ReqNum",
                                            popoverText: "Click to sort by Confirmation Number"
                                            },
                                            {
                                                label: "Vaccine", key: "Vaccine",
                                                popoverText: "Click to sort by Vaccine",
                                                rawFormat: (val) => {
                                                    //console.log(val.Vaccines, val.Tests)
                                                    
                                                        let vaccine = this.state.vaccines.find(f => f.value === val.VaccineID);
                                                        if(vaccine){
                                                            return vaccine.label;
                                                        }
                                                        //console.log(vaccine, "VACCINE")
                                                    
                                                    return vaccine?.map(m => (
                                                        <p className="mb-0">{m}</p>
                                                    ))
                                                }
                                            },
                                            {
                                            label: "Injection Site", key: "InjectionSite",
                                            popoverText: "Click to sort by Injection Site",
                                            rawFormat: (val) => {
                                                    
                                                let injectionSite = this.state.injectionSites.find(f => f.value === val.InjectionSite);
                                                if(injectionSite){
                                                    return injectionSite.label;
                                                }
                                            
                                                return injectionSite?.map(m => (
                                                    <p className="mb-0">{m}</p>
                                                ))
                                            }
                                            },
                                            {
                                            label: "Vaccine Lot Number", key: "LotNumber",
                                            popoverText: "Click to sort by Lot Number"
                                            },
                                            {
                                            label: "Vaccine Expiration Date", key: "VaccineExpDate",
                                            popoverText: "Click to sort by Expiration Date",
                                            formatFunc:parseDate
                                            },
                                            {
                                            label: "Appoinment Date", key: "AppointmentDate", formatFunc:toLocalDateTime,
                                            popoverText: "Click to sort by Injection Date"
                                            },
                                            
                                            
                                        ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                            this.useSorter(col);
                                        })} />
                                    </div>
                                </div>
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>)
    }
}