import React from "react";
import Select from "react-select";
import ReactQuill from "react-quill";
import {
    buildRow,
    getBoolSelectVal,
    hideModal,
    getLabel,
    parseDate,
    showModalNoOutsideClick,
    getDateOrNull
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import Validator, { ValidationEntry } from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {isEmptyObject} from "jquery";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import GetField from "../../customfields/GetField";
import RecordType from "../../types/RecordType";
import { AiFillEye, AiOutlineDown, AiOutlineUp, AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloudDownload} from "react-icons/ai";
import CustomInput from "../form/CustomInput";
import CustomTextArea from "../form/CustomTextArea";
import CustomSelect from "../form/CustomSelect";
import CustomDate from '../form/CustomDate';
import CustomPhone from "../form/CustomPhone";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { InsuranceImageModal } from "./InsuranceImageModal";
import { SampleInsuranceCardModal } from "./SamplesInsuranceCardModal";
import { InsuranceCardUploadModal } from "./InsuranceCardUploadModal";
import TreatmentRecordsAPI from "../../network/TreatmentRecordsAPI";
import Overlay from "../Overlay";
import InBetweenOverlay from "../InBetweenOverlay";
import SamplesAPI from "../../network/SamplesAPI";


interface VaccineRecordsState {
    selectedRecord
    customFieldData?
    patientArrowDown?
    apptArrowDown?
    vaccArrowDown?
    addArrowDown?
    fileUploadArrowDown?
    insuranceArrowDown?
    imageSaved: boolean;
    showInBetween: boolean;
    captureClicked: boolean;
    imageCached: boolean;
    showLoadingForViewInsuranceCards: boolean;
    frontInsuranceCardImage: string;
    backInsuranceCardImage: string;
    frontCardImgFile: File;
    backCardImgFile: File;
    showLoading: boolean
    showLoadingForPhysicianReview: boolean
    facilityName
}

interface VaccineRecordsModalProps{
    selectedRecord:RecordType
    onSubmit?:(record:RecordType, insuranceInformation, insuranceCards) => void
    pageCustomQuestions?
    ethnicity?
    race?
    gender?
    states?
    countries?
    vaccines
    onClose?
    facilities
    injectionSites
}

const emptyRecordState = {
    ID: null,
    Vaccine: null,
    LotNumber: null,
    InjectionSite: null,
    NurseFirstName: null,
    RecordDate: null,
    PatientFirstName: null,
    PatientMiddleName: null,
    PatientLastName: null,
    PatientAddress: null,
    PatientZip: null,
    PatientCity: null,
    PatientState: null,
    PatientyCounty: null,
    PatientCountry: null,
    PatientPhone: null,
    PatientEmail: null,
    NurseLastName: null,
    NurseLicenseNum: null,
    PatientID: null,
    ReqNum: null,
    LastUpdatedBy: null,
    LastUpdatedDate: null,
    CreatedDate: null,
    CreatedBy: null,
    InsuredAddressLine1: null,
    InsuredAddressLine2: null,
    InsuredBackCardURL: null,
    InsuredCity: null,
    InsuredDOB: null,
    InsuredFirstName: null,
    InsuredFrontCardURL: null,
    InsuredLastName: null,
    InsuredMRN: null,
    InsuredSSN: null,
    InsuredState: null,
    InsuredZip: null,
    VaccineExpDate: null
  }
export class VaccineRecordsModal extends React.Component<VaccineRecordsModalProps, VaccineRecordsState> {
    public static ID = "VaccineRecords"

    constructor(props) {
        super(props);
        this.state = {
            selectedRecord: emptyRecordState,
            imageSaved: false,
            showInBetween: false,
            captureClicked: false,
            imageCached: false,
            showLoadingForViewInsuranceCards: false,
            frontInsuranceCardImage: "",
            backInsuranceCardImage: "",
            frontCardImgFile: null,
            backCardImgFile: null,
            showLoading: false,
            showLoadingForPhysicianReview: false,
            facilityName: ""
        }
        this.clearFields = this.clearFields.bind(this)
    }

    componentDidMount() {
      $(`#${VaccineRecordsModal.ID}`).on('shown.bs.modal', function() {
        $(document).off('focusin.modal');
    });
        if(!isEmptyObject(this.props.selectedRecord)){
            let record = this.props.selectedRecord;
            //console.log(record, this.state.selectedRecord, "Modal Data")
            this.setState({
                selectedRecord: {
                    ID: record.ID,
                    VaccineID: record.VaccineID,
                    LotNumber: record.LotNumber,
                    InjectionSite: record.InjectionSite,
                    NurseFirstName: record.NurseFirstName,
                    RecordDate: record.RecordDate,
                    PatientFirstName: record.PatientFirstName,
                    PatientMiddleName: record.PatientMiddleName,
                    PatientLastName: record.PatientLastName,
                    PatientAddress: record.PatientAddress,
                    PatientZip: record.PatientZip,
                    PatientCity: record.PatientCity,
                    PatientState: record.PatientState,
                    PatientCounty: record.PatientCounty,
                    PatientCountry: record.PatientCountry,
                    PatientRaceID: record.PatientRaceID,
                    PatientEthnicityID: record.PatientEthnicityID,
                    PatientGenderID: record.PatientGenderID,
                    PatientDOB: record.PatientDOB,
                    PatientPhone: record.PatientPhone,
                    PatientEmail: record.PatientEmail,
                    NurseLastName: record.NurseLastName,
                    NurseLicenseNum: record.NurseLicenseNum,
                    PatientID: record.PatientID,
                    FacilityID: record.FacilityID,
                    ReqNum: record.ReqNum,
                    LinkedApptsID: record.LinkedApptsID,
                    LastUpdatedBy: record.LastUpdatedBy,
                    LastUpdatedDate: record.LastUpdatedDate,
                    CreatedDate: record.CreatedDate,
                    CreatedBy: record.CreatedBy,
                    InsuredAddressLine1: record.InsuredAddressLine1,
                    InsuredAddressLine2: record.InsuredAddressLine2,
                    InsuredBackCardURL: record.InsuredBackCardURL,
                    InsuredCity: record.InsuredCity,
                    InsuredDOB: record.InsuredDOB,
                    InsuredFirstName: record.InsuredFirstName,
                    InsuredFrontCardURL: record.InsuredFrontCardURL,
                    InsuredLastName: record.InsuredLastName,
                    InsuredMRN: record.InsuredMRN,
                    InsuredSSN: record.InsuredSSN,
                    InsuredState: record.InsuredState,
                    InsuredZip: record.InsuredZip,
                    VaccineExpDate: record.VaccineExpDate,
                    countryLabel: record && record.PatientCountry && record.PatientCountry === 'United States of America' ? getLabel('United States', this.props.countries) : record && record.PatientCountry ? getLabel(record.PatientCountry, this.props.countries) : "",
                    stateLabel: record && record.PatientState ? getLabel(record.PatientState, this.props.states) : "",
                    patientRace: record && record.PatientRaceID ? getLabel(record.PatientRaceID, this.props.race) : "",
                    patientGender: record && record.PatientGenderID ? getLabel(record.PatientGenderID, this.props.gender) : "",
                    injectionSite: record && record.InjectionSite ? getLabel(record.InjectionSite, this.props.injectionSites) : "",
                    patientEthnicity: record && record.PatientEthnicityID ? getLabel(record.PatientEthnicityID, this.props.ethnicity) : "",
                    vaccineID: record && record.VaccineID ? getLabel(record.VaccineID, this.props.vaccines) : "",
                    patientDOB: record && record.PatientDOB ? parseDate(record.PatientDOB) : null,
                    AppointmentDate: record && record.AppointmentDate ? parseDate(record.AppointmentDate) : null,
                    AppointmentTime: record && record.AppointmentTime ? moment(record.AppointmentTime, "hh:mm a").format("hh:mm a"): null,
                    vaccExpDate: record && record.VaccineExpDate ? parseDate(record.VaccineExpDate) : null,
                    lastUpdatedDate: record && record.LastUpdatedDate ? parseDate(record.LastUpdatedDate) : null,
                    createdDate: record && record.CreatedDate ? parseDate(record.CreatedDate) : null,
                    insuredStateLabel: record && record.InsuredState ? getLabel(record.InsuredState, this.props.states) : "",
                    insuredDOB: record && record.InsuredDOB ? parseDate(record.InsuredDOB) : null,
                    facilityName: record && record.FacilityID ? getLabel(record.FacilityID, this.props.facilities) : "",
                    fileNames: this.props.selectedRecord && this.props.selectedRecord.FileUploadPaths ? this.getFileNames(this.props.selectedRecord.FileUploadPaths) : [],
                } as RecordType,
            });
        } else {
          this.setState({
            selectedRecord: {
                  ID: "",
                  Vaccine: "",
                  LotNumber: "",
                  InjectionSite: "",
                  NurseFirstName: "",
                  RecordDate: "",
                  PatientFirstName: "",
                  PatientMiddleName: "",
                  PatientLastName: "",
                  PatientAddress: "",
                  PatientZip: "",
                  PatientCity: "",
                  PatientState: "",
                  PatientyCounty: "",
                  PatientCountry: "",
                  PatientPhone: "",
                  PatientEmail: "",
                  NurseLastName: "",
                  NurseLicenseNum: "",
                  PatientID: "",
                  ReqNum: "",
                  LastUpdatedBy: "",
                  LastUpdatedDate: "",
                  CreatedDate: "",
                  CreatedBy: "",
                  InsuredAddressLine1: "",
                  InsuredAddressLine2: "",
                  InsuredBackCardURL: "",
                  InsuredCity: "",
                  InsuredDOB: "",
                  InsuredFirstName: "",
                  InsuredFrontCardURL: "",
                  InsuredLastName: "",
                  InsuredMRN: "",
                  InsuredSSN: "",
                  InsuredState: "",
                  InsuredZip: "",
                  VaccineExpDate: ""
            },
        });
        }
    }

    getFileNames(urlArray){
      let parsedArray = Array.from(JSON.parse(urlArray));
      let namesOnly = [];
      parsedArray.map(f => {
        //@ts-ignore
        let temp = f.split('/').pop();
        namesOnly.push(temp)
      })    
      return namesOnly
  
    } 

    treatmentInsuranceCardUpload() {
      if (!isMobile) {
        sweetalert
          .fire({
            title: "Add New Insurance Card Images",
            showCloseButton: true,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Upload",
            denyButtonText: `Capture`,
          })
          .then((result) => {
            //console.log(result, "RESULT")
            this.setState({ showInBetween: true });
            if (result.isConfirmed) {
              showModalNoOutsideClick(InsuranceCardUploadModal.ID);
            } else if (result.isDenied) {
              this.setState({ captureClicked: true });
              showModalNoOutsideClick(InsuranceImageModal.ID);
            } else if (result.isDismissed) {
              this.setState({ showInBetween: false });
            }
          });
      } else {
        this.setState({ showInBetween: true });
        showModalNoOutsideClick(InsuranceCardUploadModal.ID);
      }
    }

    getInsuranceCardImagesFromCloud() {
      if (this.state.imageCached) {
        this.setState({ showInBetween: true });
        showModalNoOutsideClick(SampleInsuranceCardModal.ID);
      } else {
        this.setState({ showLoadingForViewInsuranceCards: true }, async () => {
          try {
            let frontImage = await TreatmentRecordsAPI.getInsuranceCardImagesFromCloud({
              url: this.state.selectedRecord?.InsuredFrontCardURL,
              side: "front",
              ReqNum: this.state.selectedRecord?.ReqNum,
            });
            if (typeof frontImage === "object") {
              sweetalert.fire({
                icon: "error",
                title: "",
                text: "Insurance Card Images not found",
              });
              this.setState({ showLoadingForViewInsuranceCards: false });
              return;
            } else {
              this.setState({ frontInsuranceCardImage: frontImage });
            }
            let backImage = await TreatmentRecordsAPI.getInsuranceCardImagesFromCloud({
              url: this.state.selectedRecord?.InsuredBackCardURL,
              side: "back",
              ReqNum: this.state.selectedRecord?.ReqNum,
            });
            if (typeof backImage === "object") {
              sweetalert.fire({
                icon: "error",
                title: "",
                text: "Insurance Card Images not found",
              });
              this.setState({ showLoadingForViewInsuranceCards: false });
              return;
            } else {
              this.setState({
                backInsuranceCardImage: backImage,
                showInBetween: true,
                showLoadingForViewInsuranceCards: false,
                imageCached: true,
              });
              showModalNoOutsideClick(SampleInsuranceCardModal.ID);
            }
          } catch (e) {
            console.log(e);
            sweetalert.fire({
              icon: "error",
              title: "",
              text: "Error getting Insurance Card Images",
            });
            this.setState({ showLoadingForViewInsuranceCards: false });
          }
        });
      }
    }

    addDashes(e, stateToSet) {
      const len = e.target.value.length;
      if (len === 3 || len === 6) {
        e.preventDefault();
        this.setState((prevState) => ({
          selectedRecord: {
            ...prevState.selectedRecord,
            [stateToSet]: `${e.target.value}-${e.data}`,
          },
        }));
      }
    }

    handleChange(e, stateToSet, stateLabelToSet='', type='input'){
        if(type === 'select'){
            this.setState((prevState) => ({
              selectedRecord: {
                ...prevState.selectedRecord,
                [stateLabelToSet]: e,
                [stateToSet]: e.value,
              },
            }));
        }
        else{
          this.setState((prevState) => ({
            selectedRecord: {
              ...prevState.selectedRecord,
              [stateToSet]: e.target.value,
            },
          }));
        }
        
      }

      handleDateOnChange(value, state) {
        //console.log(value, "VALUE")
        if (value) {
          this.setState((prevState) => ({
            selectedRecord: {
              ...prevState.selectedRecord,
              [state]: value,
            },
          }));
        } else {
          this.setState((prevState) => ({
            selectedRecord: {
              ...prevState.selectedRecord,
              [state]: null,
            },
          }));
        }
      }

      onUpload = (state, e) => {
        if (e.target) {
          this.setState({ [state]: e.target.files[0] } as any);
        } else {
          this.setState({ [state]: e } as any);
        }
      };

      downloadFile = async (name) => {
        this.setState({showInBetween: true})
        let file = await SamplesAPI.downloadFilesFromCloud({ApptID: this.state.selectedRecord?.LinkedApptsID, fileName: name})
        try {
          if (typeof file === "object") {
            sweetalert.fire({
              icon: "error",
              title: "",
              text: "File not found",
            });
            this.setState({ showInBetween: false });
            return;
          } else {
            const link = document.createElement("a");
            link.href = file;
            link.download = `${name}`;
            link.click();
            this.setState({showInBetween: false})
          }
        } catch (error) {
          console.error('error downloading file', error)
          this.setState({showInBetween: false})
          return sweetalert.fire({icon: 'error', title: '', text: 'Could not download file. Please try again.'})
        }
      }
    
      clearFiles = () => {
        const frontUpload = document.querySelector("#FrontCardCapture") as any;
        frontUpload.value = "";
        const backUpload = document.querySelector("#BackCardCapture") as any;
        backUpload.value = "";
        this.setState({ frontCardImgFile: null, backCardImgFile: null, imageSaved: false });
      };


    clearFields(){

        this.setState({
            selectedRecord: emptyRecordState
        }, () => {
          //console.log(this.state.selectedRecord, "SHOULD BE EMPTY")
        })
    }


    render() {

        return(
          <>
          <SampleInsuranceCardModal
          handleLoading={() =>
            this.setState({
              showInBetween: false,
              showLoadingForViewInsuranceCards: false,
            })
          }
          closeInBetween={() => this.setState({ showInBetween: false })}
          frontImage={this.state.frontInsuranceCardImage}
          backImage={this.state.backInsuranceCardImage}
          reqNum={this.state.selectedRecord?.ReqNum}
          />
          <InsuranceCardUploadModal
          frontUpload={(e) => this.onUpload("frontCardImgFile", e)}
          backUpload={(e) => this.onUpload("backCardImgFile", e)}
          clearFiles={() => this.clearFiles()}
          handleLoading={() => this.setState({ showInBetween: false })}
          closeInBetween={() => this.setState({ showInBetween: false })}
          handleImageSavedButton={(e) => this.setState({ imageSaved: e })}
          isDupe={false}
          clearImageState={() => this.setState({ frontCardImgFile: null, backCardImgFile: null }) }
          />
          <InsuranceImageModal
            onFrontImgChange={(e) => this.onUpload("frontCardImgFile", e)}
            onBackImgChange={(e) => this.onUpload("backCardImgFile", e)}
            clearFiles={() => this.clearFiles()}
            handleLoading={() => this.setState({ showInBetween: false })}
            closeInBetween={() => this.setState({ showInBetween: false })}
            handleImageSavedButton={(e) => this.setState({ imageSaved: e })}
            captureClicked={this.state.captureClicked}
            clearImageState={() => this.setState({ frontCardImgFile: null, backCardImgFile: null }) }
          />
          <Overlay show_loading={this.state.showLoading} />
          <Overlay show_loading={this.state.showLoadingForViewInsuranceCards || this.state.showLoadingForPhysicianReview} zIndex={100005} />
          <InBetweenOverlay showInBetween={this.state.showInBetween} />
              <div className="modal fade form_modal" id={VaccineRecordsModal.ID} key={this.state.selectedRecord.RecordID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Vaccination Record</h5>
                            <button  style={{outline: 'none'}} type="button" onClick={() => {this.clearFields(); this.props.onClose()}} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body m-3">
                            {/* Patient Accordion */}
                            <div className="card mb-2">
                            <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#patientXLCollapse" onClick={() => this.setState({patientArrowDown: !this.state.patientArrowDown,})} aria-expanded="false" aria-controls="patientXLCollapse">
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Patient</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.patientArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#patientXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#patientXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                            </button>
                            <div className="card-body collapse" id={"patientXLCollapse"}>
                                <div className="row">
                                <div className="col-12 col-xl-6">
                                    <CustomInput fromFilterPage={false}  value={this.state.selectedRecord?.PatientFirstName} label={"First Name"} tooltip={"The first name of the Patient"} handleChange={(e) => this.handleChange(e, "PatientFirstName")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.PatientMiddleName} label={"Middle Name"} tooltip={"The middle name of the Patient"} handleChange={(e) => this.handleChange(e, "PatientMiddleName")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.PatientLastName} label={"Last Name"} tooltip={"The last name of the Patient"} handleChange={(e) => this.handleChange(e, "PatientLastName")} readOnly={true}/>
                                    <CustomDate fromFilterPage={false} value={this.state.selectedRecord?.patientDOB} label={"DOB"} tooltip={`The date of birth of the Patient`} handleDateOnChange={(e) => this.handleDateOnChange(e, "patientDOB")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.PatientEmail} label={"Email"} tooltip={"The email of the Patient"} maxLength={100} handleChange={(e) => this.handleChange(e, "PatientEmail")} readOnly={true}/>
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedRecord?.patientEthnicity} label={"Ethnicity"} tooltip={"The ethnicity of the Patient"} handleChange={(e) => this.handleChange(e, "PatientEthnicityID", "patientEthnicity", "select")} options={this.props.ethnicity} readOnly={true} />
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedRecord?.patientRace} label={"Race"} tooltip={"The race of the Patient"} handleChange={(e) => this.handleChange(e, "PatientRaceID", "patientRace", "select")} options={this.props.race} readOnly={true} />
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedRecord?.patientGender} label={"Gender"} tooltip={"The gender of the Patient"} handleChange={(e) => this.handleChange(e, "PatientGenderID", "patientGender", "select")} options={this.props.gender} readOnly={true} />
                                </div>
                                <div className="col-12 col-xl-6">
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.PatientPhone} label={"Phone"} tooltip={`The phone number of the Patient`} handleDateOnChange={(e) => this.handleDateOnChange(e, "PatientPhone")} readOnly={true} />
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.PatientAddress} label={"Address"} tooltip={"The address of the Patient"} maxLength={500} handleChange={(e) => this.handleChange(e, "PatientAddress")} readOnly={true} />
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.PatientCity} label={"City"} tooltip={"The city of the Patient"} maxLength={100} handleChange={(e) => this.handleChange(e, "PatientCity")} readOnly={true} />
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedRecord?.stateLabel} label={"State"} tooltip={"The state of the Patient's address"} handleChange={(e) => this.handleChange(e, "PatientState", "stateLabel", "select")} options={this.props.states} readOnly={true} />
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.PatientZip} label={"Zip"} tooltip={"The zip of the Patient"} handleChange={(e) => this.handleChange(e, "PatientZip")} readOnly={true} />          
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.PatientCounty} label={"County"} tooltip={"The county of the Patient"} maxLength={100} handleChange={(e) => this.handleChange(e, "PatientCounty")} readOnly={true} />
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedRecord?.countryLabel} label={"Country"} tooltip={"The country of the Patient's address"} handleChange={(e) => this.handleChange(e, "PatientCountry", "countryLabel", "select")} options={this.props.countries} readOnly={true} />
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.GuardianFirstName} label={"Guardian First Name"} tooltip={"The first name of the guardian if the patient is under 18"} handleChange={(e) => this.handleChange(e, "GuardianFirstName")} readOnly={true} />
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.GuardianLastName} label={"Guardian Last Name"} tooltip={"The last name of the guardian if the patient is under 18"} handleChange={(e) => this.handleChange(e, "GuardianLastName")}readOnly={true} />
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* Appt Accordion */}
                            <div className="card mb-2">
                            <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#apptXLCollapse" onClick={() => this.setState({apptArrowDown: !this.state.apptArrowDown,})} aria-expanded="false" aria-controls="patientXLCollapse">
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Appointment</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.apptArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#apptXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#apptXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                            </button>
                            <div className="card-body collapse" id={"apptXLCollapse"}>
                                <div className="row">
                                <div className="col-12 col-xl-12">
                                    <CustomDate fromFilterPage={false} value={this.state.selectedRecord?.AppointmentDate} label={"Appointment Date"} tooltip={"Date of Appointment"} handleDateOnChange={(e) => this.handleDateOnChange(e, "AppointmentDate")} readOnly={true} />
                                    <CustomDate fromFilterPage={false} value={this.state.selectedRecord?.AppointmentTime} label={"Appointment Time"} tooltip={`Time of appointment`} timeOnly={true} handleDateOnChange={(e) => this.handleDateOnChange(e, "AppointmentTime")} readOnly={true} /> 
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.ReqNum} label={"Confirmation Number"} tooltip={"Confirmation Number for Appointment"} readOnly={true} />
                                    
                                </div>
                            </div>
                            
                        </div>
                        </div>
                        {/* Vaccine Accordion */}
                        <div className="card mb-2">
                        <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#vaccXLCollapse" onClick={() => this.setState({vaccArrowDown: !this.state.vaccArrowDown,})} aria-expanded="false" aria-controls="patientXLCollapse">
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Vaccine</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.vaccArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#vaccXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#vaccXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                            </button>
                            <div className="card-body collapse" id={"vaccXLCollapse"}>
                                <div className="row">
                                <div className="col-12 col-xl-12">
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedRecord.vaccineID} label={"Vaccine"} tooltip={"Vaccine being administered"} handleChange={(e) => this.handleChange(e, "VaccineID", "vaccineID", 'select')} options={this.props.vaccines} readOnly={true}/>
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedRecord?.injectionSite} label={"Injection Site"} tooltip={"Site where vaccine was injected"} handleChange={(e) => this.handleChange(e, "InjectionSite", "injectionSite", 'select')} options={this.props.injectionSites} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.LotNumber} label={"Lot Number"} tooltip={"Lot number of vaccine"} handleChange={(e) => this.handleChange(e, "LotNumber")} readOnly={true} />
                                    <CustomDate fromFilterPage={false} value={this.state.selectedRecord?.vaccExpDate} label={"Expiration Date"} tooltip={"Expiration date of vaccine"} handleDateOnChange={(e) => this.handleDateOnChange(e, "vaccExpDate")} readOnly={true} />
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedRecord?.facilityName} label={"Facility"} tooltip={"Facility where Vaccine is being administered"} handleChange={(e) => {this.handleChange(e, "FacilityID", "facilityName", 'select')}} options={this.props.facilities} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.NurseFirstName} label={"Nurse First Name"} tooltip={"First Name of Nurse who administered vaccine"} handleChange={(e) => this.handleChange(e, "NurseFirstName")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.NurseLastName} label={"Nurse Last Name"} tooltip={"Last name of Nurse who administered vaccine"} handleChange={(e) => this.handleChange(e, "NurseLastName")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.NurseLicenseNum} label={"Nurse License Number"} tooltip={"Nurse's license number "} handleChange={(e) => this.handleChange(e, "NurseLicenseNum")} readOnly={true} />
                                    
                                </div>
                            </div>
                            </div>
                            </div>
                      {/*File Upload */}
                      {this.state.selectedRecord?.fileNames?.length > 0 ? <div className="card mb-2">
                        <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#fileUploadXLCollapse" onClick={() => this.setState({ fileUploadArrowDown: !this.state.fileUploadArrowDown })} aria-expanded="false" aria-controls="fileUploadXLCollapse" >
                          <div className="row justify-content-between px-3">
                            <div className="">
                              <h4>Files</h4>
                            </div>
                            <div className="col-2">
                              {this.state.fileUploadArrowDown ? (
                                <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#fileUploadXLCollapse" role="button" aria-expanded="false" aria-controls="fileUploadXLCollapse" />
                              ) : (
                                <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#fileUploadXLCollapse" role="button" aria-expanded="false" aria-controls="fileUploadXLCollapse" />
                              )}
                            </div>
                          </div>
                        </button>
                        <div className="card-body collapse" id={"fileUploadXLCollapse"} >
                           
                              <>
                                  
                                  {this.state.selectedRecord?.fileNames && this.state.selectedRecord?.fileNames.length > 0 && this.state.selectedRecord?.fileNames.map((fileName, index) => (
                                    <div className="form-group row">
                                      <label htmlFor={`SelectedFile${index}`} className="col-12 col-sm-4 col-form-label text-center text-md-left">Selected File #{index + 1}</label>
                                      <div className="col-8 col-sm-6 p-0 pt-2 m-0 text-center text-md-left" id={`SelectedFile${index}`}>
                                          {fileName}
                                      </div>
                                      <div className="col-2 col-sm-1">
                                        {/* {!this.state.selectedFiles || this.state.selectedFiles.length < 1 || this.state.selectedFiles.find(f => f.name != fileName) ?  */}
                                        <AiOutlineCloudDownload size={30} onClick={() => this.downloadFile(fileName)}/>
                                        {/* : 
                                        <div></div>
                                        } */}
                                      </div>
                                      {/* <div className="col-2 col-sm-1">
                                          <button  style={{outline: 'none'}} type="button" className="close" aria-label="Close" onClick={() => {
                                              this.removeFile(fileName)
                                          }}>
                                                  <span aria-hidden="true">&times;</span>
                                              </button>
                                      </div> */}
                                    </div>
                                  ))}
                                {/* {this.state.selectedRecord?.fileNames?.length >= 10 ? <div className={"d-none"}></div> : 
                                <Dropzone onDrop={this.onDrop}>
                                  {({getRootProps, getInputProps}) => (
                                    <section className="container p-0">
                                      <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                } */}
                              </>
                              
                          
                        </div>  
                      </div> : <></>}
                            {/* Insurance Accordion */}
                            <div className="card mb-2">
                            <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#insuranceXLCollapse" onClick={() => this.setState({ insuranceArrowDown: !this.state.insuranceArrowDown, }) } aria-expanded="false" aria-controls="insuranceXLCollapse" >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Insurance</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.insuranceArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#insuranceXLCollapse" role="button" aria-expanded="false" aria-controls="insuranceXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#insuranceXLCollapse" role="button" aria-expanded="false" aria-controls="insuranceXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div className="card-body collapse" id={"insuranceXLCollapse"} >
                                  {this.state.selectedRecord?.rerunCount > 0 ? (
                                    <div> Please see original record to manage insurance information. Be sure to change Rerun Flag to 'No' or blank in the filter to find original record </div>
                                  ) : (
                                    <>
                                      <div className="row mb-4 pr-1">
                                        <div className={"col-12 text-center text-xl-left"}>
                                          <div className="row justify-content-between">
                                            <div className="col-12 col-xl-3 font-weight-bold"> Insurance holder's information</div>
                                            <button className={this.state.selectedRecord?.InsuredFrontCardURL ? "d-xl-none col-12 mt-2 btn btn-success btn-sm mb-2 w-60" : "d-none"} onClick={() => this.getInsuranceCardImagesFromCloud()}> View Insurance Card Images </button>
                                            <button className={this.state.imageSaved ? "col-12 col-xl-2 pl-xl-0" : "invisible"}>
                                              <AiOutlineCheckCircle className={"ml-2"} color={"#14410B"} size={30} />
                                              <span className={"ml-1 text-success"} style={{ fontSize: "1em" }} > Images Saved </span>
                                            </button>
                                            <button className={ this.state.selectedRecord?.InsuredFrontCardURL ? "d-none d-xl-inline col-xl-3 btn btn-success btn-sm" : "d-none" } onClick={() => this.getInsuranceCardImagesFromCloud() } > View Insurance Card Images </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-xl-6">
                                          <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.InsuredFirstName} forInsurance={true} label={"First Name"} tooltip={"First name of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredFirstName")} readOnly={true}  />
                                          <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.InsuredLastName} forInsurance={true} label={"Last Name"} tooltip={"Last name of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredLastName")} readOnly={true} />
                                          <CustomInput  fromFilterPage={false} value={this.state.selectedRecord?.InsuredSSN} forInsurance={true} label={"Social Security #"} tooltip={"SSN of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredSSN")} addDashes={(e) => this.addDashes(e, "InsuredSSN")} readOnly={true}/>
                                          <CustomDate fromFilterPage={false} value={this.state.selectedRecord?.insuredDOB} forInsurance={true} label={"DOB"} tooltip={`The date of birth of the insurance holder`} showDateWarning={true} handleDateOnChange={(e) => this.handleDateOnChange(e, "insuredDOB")} readOnly={true} />
                                          <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.InsuredMRN} forInsurance={true} label={"Medical Record #"} tooltip={"Medical Record Number of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredMRN")} readOnly={true} />
                                        </div>
                                        <div className="col-12 col-xl-6">
                                          <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.InsuredAddressLine1} forInsurance={true} label={"Address"} tooltip={"Address of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredAddressLine1")} maxLength={100} readOnly={true} />
                                          <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.InsuredAddressLine2} forInsurance={true} label={"Address Cont."} tooltip={"Address Cont. of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredAddressLine2")} maxLength={100} readOnly={true}/>
                                          <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.InsuredCity} forInsurance={true} label={"City"} tooltip={"City of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredCity")} readOnly={true}/>
                                          <CustomSelect fromFilterPage={false} value={this.state.selectedRecord?.insuredStateLabel} forInsurance={true} label={"State"} tooltip={`State of insurance holder`} handleChange={(e) => this.handleChange(e, "insuredState", "insuredStateLabel", "select")} options={this.props.states} readOnly={true}/>
                                          <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.InsuredZip} forInsurance={true} label={"Zip"} tooltip={"Zipcode of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredZip")} readOnly={true}/>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                </div>
                            {/* Additional Accordion */}
                            <div className="card mb-2">
                            <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#addXLCollapse" onClick={() => this.setState({addArrowDown: !this.state.addArrowDown,})} aria-expanded="false" aria-controls="patientXLCollapse">
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Additional</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.addArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#addXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#addXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                            </button>
                            <div className="card-body collapse" id={"addXLCollapse"}>
                                <div className="row">
                                <div className="col-12 col-xl-12">
                                    <CustomDate fromFilterPage={false} value={this.state.selectedRecord?.lastUpdatedDate} label={"Last Updated Date"} tooltip={"Record Last Updated Date"} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.LastUpdatedBy} label={"Last Updated By"} tooltip={"Record last updated by this person"} readOnly={true} />
                                    <CustomDate fromFilterPage={false} value={this.state.selectedRecord?.createdDate} label={"Created Date"} tooltip={"Patient created appointment"} readOnly={true} />
                                    <CustomInput fromFilterPage={false} value={this.state.selectedRecord?.CreatedBy} label={"Created By"} tooltip={"Record created by this person"} readOnly={true} />
                                    
                                </div>
                            </div>
                            </div>
                            </div>

                            {/* if hasNonStandard is true, display ranges  */}

                            {/*Custom Field Data*/}

                            {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField 
                                                            val={val} 
                                                            selectedItem={this.state.selectedRecord ? this.state.selectedRecord : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedRecord: e})
                                                            }}  
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }


                            {/* <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Test Result Description</label>
                                <div className="col-sm-12  p-0 m-0">
                                    <ReactQuill theme="snow" value={this.state.selectedRecord.ReportDescription}
                                                onChange={(e) =>{
                                                    this.setState( (prevState) => ({
                                                        selectedRecord: {
                                                            ...prevState.selectedRecord,
                                                            ReportDescription: e
                                                        }
                                                    }))
                                                }}
                                    />
                                </div>
                            </div> */}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => {
                                  let recordInfo = {
                                    ID: this.state.selectedRecord?.sampleID,
                                    LinkedApptsID: this.state.selectedRecord?.linkedApptsID ? this.state.selectedRecord?.linkedApptsID.trim() : null,
                                    ReqNum: this.state.selectedRecord?.reqNum ? this.state.selectedRecord?.reqNum.trim() : null,
                                    SpecimenID: this.state.selectedRecord?.specimenID ? this.state.selectedRecord?.specimenID.trim() : null,
                                    InternalSpecimenID: this.state.selectedRecord?.internalSpecimenID ? this.state.selectedRecord?.internalSpecimenID.trim() : null,
                                    Memo: this.state.selectedRecord?.memo ? this.state.selectedRecord?.memo.trim() : null,
                                    PatientFirstName: this.state.selectedRecord?.patientFirstName ? this.state.selectedRecord?.patientFirstName.trim() : null,
                                    PatientMiddleName: this.state.selectedRecord?.patientMiddleName ? this.state.selectedRecord?.patientMiddleName.trim() : null,
                                    PatientLastName: this.state.selectedRecord?.patientLastName ? this.state.selectedRecord?.patientLastName.trim() : null,
                                    PatientDOB: getDateOrNull(this.state.selectedRecord?.patientDOB),
                                    PatientZip: this.state.selectedRecord?.patientZip ? this.state.selectedRecord?.patientZip.trim() : null,
                                    PatientCity: this.state.selectedRecord?.patientCity ? this.state.selectedRecord?.patientCity.trim() : null,
                                    PatientState: this.state.selectedRecord?.stateLabel ? this.state.selectedRecord?.stateLabel.value : null,
                                    PatientCounty: this.state.selectedRecord?.patientCounty ? this.state.selectedRecord?.patientCounty.trim() : null,
                                    PatientCountry: this.state.selectedRecord?.countryLabel ? this.state.selectedRecord?.countryLabel.value : null,
                                    PatientAddress: this.state.selectedRecord?.patientAddress ? this.state.selectedRecord?.patientAddress.trim() : null,
                                    PatientEmail: this.state.selectedRecord?.patientEmail ? this.state.selectedRecord?.patientEmail.trim() : null,
                                    PatientPhone: this.state.selectedRecord?.patientPhone ? this.state.selectedRecord?.patientPhone.trim() : null,
                                    PatientEthnicityID: this.state.selectedRecord?.patientEthnicity ? this.state.selectedRecord?.patientEthnicity.value : null,
                                    PatientRaceID: this.state.selectedRecord?.patientRaceID ? this.state.selectedRecord?.patientRace.value : null,
                                    PatientGenderID: this.state.selectedRecord?.patientGender ? this.state.selectedRecord?.patientGender.value : null,
                                    GuardianFirstName: this.state.selectedRecord?.guardianFirstName ? this.state.selectedRecord?.guardianFirstName.trim() : null,
                                    GuardianLastName: this.state.selectedRecord?.guardianLastName ? this.state.selectedRecord?.guardianLastName.trim() : null,
                                    ProviderFirstName: this.state.selectedRecord?.providerFirstName ? this.state.selectedRecord?.providerFirstName.trim() : null,
                                    ProviderLastName: this.state.selectedRecord?.providerLastName ? this.state.selectedRecord?.providerLastName.trim() : null,
                                    SampleReceivedID: this.state.selectedRecord?.binID ? this.state.selectedRecord?.bin.value.ID : null,
                                    BatchID: this.state.selectedRecord?.batchNum ? this.state.selectedRecord?.batchNums.value.ID : null,
                                    WellSlot: this.state.selectedRecord?.wellSlot ? this.state.selectedRecord?.wellSlot.trim() : null,
                                    CollectionDate: getDateOrNull(this.state.selectedRecord?.collectionDate),
                                    SpecimenSourceID: this.state.selectedRecord?.specimenSourceID ? this.state.selectedRecord?.specimenSourceID : null,
                                    TestIDs: this.state.selectedRecord?.testIDs ? this.state.selectedRecord?.testIDs : null,
                                    ProcessedDate: getDateOrNull(this.state.selectedRecord?.processedDate),
                                    TestOrderedDate: getDateOrNull(this.state.selectedRecord?.testOrderedDate),
                                    ApptTime: this.state.selectedRecord?.apptTime ? moment(this.state.selectedRecord?.apptTime, "hh:mm a").format("hh:mm a") : null,
                                    PodApptID: this.state.selectedRecord?.podLabel ? this.state.selectedRecord?.podLabel.value : null,
                                    ApptCanceled: this.state.selectedRecord?.apptCanceledLabel ? this.state.selectedRecord?.apptCanceledLabel.value : null,
                                    RerunCount: this.state.selectedRecord?.rerunCount ? this.state.selectedRecord?.rerunCount : null,
                                    RerunFlag: this.state.selectedRecord?.rerunFlagLabel ? this.state.selectedRecord?.rerunFlagLabel.value : null,
                                    ConsentFlag: this.state.selectedRecord?.consentFlagLabel ? this.state.selectedRecord?.consentFlagLabel.value : null,
                                    ResultIDs: this.state.selectedRecord?.resultIDs ? this.state.selectedRecord?.resultIDs : null,
                                    Reason: this.state.selectedRecord?.reason ? this.state.selectedRecord?.reason.trim() : null,
                                    TestResultDate: getDateOrNull(this.state.selectedRecord?.testResultDate),
                                    ReportedDate: getDateOrNull(this.state.selectedRecord?.reportedDate),
                                    CreatedDate: getDateOrNull(this.state.selectedRecord?.createdDate),
                                    FacilityID: this.state.selectedRecord?.facilityID ? this.state.selectedRecord?.facilityName.value : null,
                                    ResultsAccessedDate: getDateOrNull(this.state.selectedRecord?.resultsAccessedDate),
                                    Lot: this.state.selectedRecord?.lot ? this.state.selectedRecord?.lot.trim() : null,
                                    CustomFieldData: this.state.selectedRecord?.CustomFieldData ? this.state.selectedRecord?.CustomFieldData : null,
                                    IncludeInHL7: this.state.selectedRecord?.IncludeInHL7 ? this.state.selectedRecord.IncludeInHL7 : null,
                                    TestsAndResultsMap: this.state.selectedRecord?.testsAndResultsMap ? this.state.selectedRecord?.testsAndResultsMap : null,
                                  };
                                  let insuranceInfo = {
                                    FirstName: this.state.selectedRecord?.InsuredFirstName ? this.state.selectedRecord?.InsuredFirstName.trim() : null,
                                    LastName: this.state.selectedRecord?.InsuredLastName ? this.state.selectedRecord?.InsuredLastName.trim() : null,
                                    SSN: this.state.selectedRecord?.InsuredSSN ? this.state.selectedRecord?.InsuredSSN.replace(/-/g, "").trim() : null,
                                    DOB: getDateOrNull(this.state.selectedRecord?.insuredDOB),
                                    MRN: this.state.selectedRecord?.InsuredMRN ? this.state.selectedRecord?.InsuredMRN.trim() : null,
                                    AddressLine1: this.state.selectedRecord?.InsuredAddressLine1 ? this.state.selectedRecord?.InsuredAddressLine1.trim() : null,
                                    AddressLine2: this.state.selectedRecord?.InsuredAddressLine2 ? this.state.selectedRecord?.InsuredAddressLine2.trim() : null,
                                    City: this.state.selectedRecord?.InsuredCity ? this.state.selectedRecord?.InsuredCity.trim() : null,
                                    State: this.state.selectedRecord?.insuredStateLabel ? this.state.selectedRecord?.insuredStateLabel.value : null,
                                    Zip: this.state.selectedRecord?.InsuredZip ? this.state.selectedRecord?.InsuredZip.trim() : null,
                                    FrontCard: this.state.selectedRecord.InsuredFrontCardURL ? this.state.selectedRecord.InsuredFrontCardURL : null,
                                    BackCard: this.state.selectedRecord.InsuredBackCardURL ? this.state.selectedRecord.InsuredBackCardURL : null,
                                  };
                                  let insuranceCards = this.state.frontCardImgFile ? [this.state.frontCardImgFile, this.state.backCardImgFile] : null;

                              

                                    this.state.selectedRecord.AppointmentTime = moment(this.state.selectedRecord?.AppointmentTime, "hh:mm a").format("hh:mm a")

                                    let formValidation = {
                                        FirstName: this.state.selectedRecord.PatientFirstName,
                                        LastName: this.state.selectedRecord.PatientLastName,
                                        Email: this.state.selectedRecord.PatientEmail,
                                        Ethnicity: this.state.selectedRecord.PatientEthnicityID,
                                        DOB: this.state.selectedRecord.patientDOB,
                                        Race: this.state.selectedRecord.PatientRaceID,
                                        Gender: this.state.selectedRecord.PatientGenderID,
                                        Phone: this.state.selectedRecord.PatientPhone,
                                        Address: this.state.selectedRecord.PatientAddress,
                                        City: this.state.selectedRecord.PatientCity,
                                        State: this.state.selectedRecord.PatientState,
                                        ZIP: this.state.selectedRecord.PatientZip,
                                        Country: this.state.selectedRecord.PatientCountry,
                                        County: this.state.selectedRecord.PatientCounty,
                                        AppointmentDate: this.state.selectedRecord.AppointmentDate,
                                        Vaccine: this.state.selectedRecord.VaccineID,
                                        InjectionSite: this.state.selectedRecord.InjectionSite,
                                        LotNumber: this.state.selectedRecord.LotNumber,
                                        VaccineExpDate: this.state.selectedRecord.VaccineExpDate,
                                        NurseFirstName: this.state.selectedRecord.NurseFirstName,
                                        NurseLastName: this.state.selectedRecord.NurseLastName,
                                        NurseLicenseNum: this.state.selectedRecord.NurseLicenseNum,
                                        InsuredFirstName: this.state.selectedRecord.InsuredFirstName 
                                    }

                                    //console.log(formValidation, "VALIDATION OBJECT")

                                    let validator = new Validator<any>()
                                        .withValidation("FirstName", Validators.requireNotBlankAndLength(50, "First Name"))
                                        .withValidation("LastName", Validators.requireNotBlankAndLength(50, "Last Name"))
                                        .withValidation("DOB", Validators.requireDOB(150, "Patient Date of Birth"))
                                        .withComposedValidation("Email", new ValidationEntry(Validators.requireValidEmail("Patient Email")), Validators.requireNotBlankAndLength(100, "Patient Email"))
                                        .withComposedValidation("Phone", new ValidationEntry(Validators.requireNonNullValidator("Patient Phone")), new ValidationEntry(Validators.requirePhone("Patient Phone")))
                                        .withComposedValidation("ZIP", new ValidationEntry(Validators.requireNotBlankValidator("Patient Zipcode")), new ValidationEntry(Validators.requireZip("Patient Zipcode")))
                                        
                                        //.withValidation("Ethnicity", Validators.requireNotBlankAndLength(45, "Ethnicity"))
                                        //.withValidation("Race", Validators.requireNotBlankAndLength(45, "Race"))
                                        //.withValidation("Gender", Validators.requireNotBlankAndLength(45, "Gender"))
                                        .withValidation("Address", Validators.requireNotBlankAndLength(60, "Address"))
                                        .withValidation("City", Validators.requireNotBlankAndLength(60, "City"))
                                        .withValidation("State", Validators.requireNotBlankAndLength(60, "State"))
                                        .withValidation("Country", Validators.requireNotBlankAndLength(60, "Country"))
                                        .withValidation("County", Validators.requireNotBlankAndLength(60, "County"))
                                        //.withValidation("AppointmentDate", Validators.requireNotBlankAndLength(45, "Appointment Date"))
                                        // .withValidation("Vaccine", Validators.requireNotBlankAndLength(45, "Vaccine"))
                                        .withValidation("InjectionSite", Validators.requireNotBlankAndLength(100, "Injection Site"))
                                        //.withValidation("LotNumber", Validators.requireNotBlankAndLength(45, "Lot Number"))
                                        //.withValidation("VaccineExpDate", Validators.requireNotBlankAndLength(45, "Vaccine Expiration Date"))
                                        .withValidation("NurseFirstName", Validators.requireNotBlankAndLength(50, "Nurse First Name"))
                                        .withValidation("NurseLastName", Validators.requireNotBlankAndLength(50, "Nurse Last Name"))
                                        .withValidation("NurseLicenseNum", Validators.requireNotBlankAndLength(45, "Nurse License Number"))
                                       
                                    let validationResponse = validator.validate(formValidation);
                                    if(!validationResponse.success) {
                                        return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                    }

                                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedRecord.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedRecord)

                                    if(invalid){
                                        return;
                                    }

                                    hideModal(VaccineRecordsModal.ID)
                                    this.props.onSubmit(
                                        this.state.selectedRecord as RecordType,
                                        insuranceInfo,
                                        insuranceCards
                                    )
                                    this.setState({
                                        selectedRecord: emptyRecordState
                                    })
                                }}>
                                    Submit
                                </button>
                            </div>
                    </div>
                </div>
                </div>
                </div>
           </>
        )
    }
}