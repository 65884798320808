import React from "react";
import Select from "react-select";
import { sweetalert } from "../../App";
import { calculateAge, isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";
import {
    buildRow,
    getLabel,
    getLabelIDsArray,
    getSelectLabels,
    getlabelsFromIDs,
    parseDate,
    showModalNoOutsideClick,
} from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Overlay from "../Overlay";
import { Patient } from "../../types/Patient";
import PhoneInput from 'react-phone-number-input'
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import PatientAPI from "../../network/PatientAPI";
import { AuthContext } from "../../context/AuthContext";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";
import { FamilyGroupModal } from "../modals/FamilyGroupModal";
import SimpleTable from "../tables/SimpleTable";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import { AiOutlineClose } from "react-icons/ai";

interface FamilyGroupState {
    showInBetween
    showLoading
    genders
    ethnicities
    races
    states
    countries
    familyGroup 
    familyMembers
    selectedMember
    direction?: TableOrder
}
interface FamilyGroupProps {
}
export class FamilyGroup extends React.Component<FamilyGroupProps, FamilyGroupState>{
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            showInBetween: false,
            showLoading: false,
            genders: [],
            ethnicities: [],
            races: [],
            states: [],
            countries: [],
            familyGroup: null,
            familyMembers: [],
            selectedMember: {},
            direction: "asc"
        }
        this.assignClearFamilyGroup = this.assignClearFamilyGroup.bind(this);
        this.removeFamilyMember = this.removeFamilyMember.bind(this);
    }

    componentDidMount(): void {
        document.title = 'Family Group Management Page';
        this.setState({ showLoading: true }, () => {
            SystemAPI.getAllRaces().then((data) => {
                this.setState({ races: data });
            });
            SystemAPI.getAllGenders().then((data) => {
                this.setState({ genders: data });
            });
            SystemAPI.getAllEthnicities().then((data) => {
                this.setState({ ethnicities: data });
            });
            SystemAPI.getAllStates().then((data) => {
                this.setState({ states: data });
            });
            SystemAPI.getAllCountries().then((data) => {
                this.setState({ countries: data });
            });

            PatientAPI.getFamilyGroupMembers().then((response)=>{
                console.log('getFamilyGroupMembers response', response)
                this.setState({
                    showLoading:false,
                    familyMembers:response.data.members, 
                    familyGroup: response.data.familyGroup})
            });
        });
    }
    clearFamilyGroupForm = null;
    assignClearFamilyGroup(func) {
        this.clearFamilyGroupForm = func;
    }

    //TODO make sure sorting works
    useSorter(col: Column) {
        let sorter = new Sorter<any>()
            .withRawSorterFunc("DisplayName", (a, b) => {
                let aGroup: { label; value } = this.state.familyMembers.find(
                    (af) => af.value === a.FirstName
                );
                let bGroup: { label; value } = this.state.familyMembers.find(
                    (af) => af.value === b.FirstName
                );
                if (!aGroup) return -1;
                if (!bGroup) return 1;
                return aGroup && bGroup ? aGroup.label.localeCompare(bGroup.label) : 0;
            });
        this.setState({
            familyMembers: sorter.sortByKey(
                this.state.familyMembers,
                col.key as keyof any,
                this.state.direction
            ),
            direction: this.state.direction === "asc" ? "desc" : "asc",
        });
    }

    //maybe this is the ageout function?
    removeFamilyMember(obj){
        this.setState({ showLoading: true }, async () => {
            PatientAPI.ageOutFamilyMember(obj).then((response)=>{
                if(response.success){
                    sweetalert.fire("Member aged out of system.").then(()=>{
                        PatientAPI.getFamilyGroupMembers().then((response) => {
                            this.setState({
                                showLoading: false,
                                familyMembers: response.data.members,
                                familyGroup: response.data.familyGroup
                            })
                        });
                    })
                }
            })
        })
    }

    render() {
        return (
            <>
                <Overlay show_loading={this.state.showLoading} zIndex={100003} />
                <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100002} />

                <FamilyGroupModal
                    selectedMember={this.state.selectedMember}
                    attachClearStateFunc={this.assignClearFamilyGroup}
                    submit={(e) =>{
                        console.log('submit FamilyGroup member ', e)
                        // this.handleSubmitNewMember();//TODO MOVE LOGIC INTO FUNC
                        PatientAPI.editFamilyMember(e).then((response)=>{
                            if(response.success){
                                sweetalert.fire("Family Member updated").then(()=>{
                                    //reload the page
                                    PatientAPI.getFamilyGroupMembers().then((response) => {
                                        console.log('getFamilyGroupMembers response', response);
                                        this.setState({ 
                                            showLoading: false, 
                                            familyMembers: response.data.members, 
                                            familyGroup: response.data.familyGroup 
                                        });
                                    });
                                })
                            }
                            else{
                                console.error(response.reason)
                                return sweetalert.fire("Unable to update Family Member at this time. Please try again later.")
                            }
                        });
                    }}
                    states={this.state.states}
                    gender={this.state.genders}
                    ethnicity={this.state.ethnicities}
                    race={this.state.races}
                    countries={this.state.countries}
                />

                {!this.state.showLoading &&
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
                                <main id="main-content" tabIndex={-1} aria-label="Family Group Management">
                                    <div className={"card"}>
                                        <div className="card-header">
                                            <h5 className="card-title" id="result_card_label">
                                                {this.state.familyGroup && this.state.familyGroup.Name ? 
                                                    this.state.familyGroup.Name : 
                                                    "Family Group"
                                                }</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive">
                                                {this.state.familyMembers &&
                                                    this.state.familyMembers.length > 0 && 
                                                    (
                                                    <SimpleTable
                                                    columns={[
                                                        {
                                                            label: "Name",
                                                            key: "DisplayName",
                                                            rawFormat: (val) => {
                                                                return (<a
                                                                    href={"#top"}
                                                                    className={'tableNameLinkColor'}
                                                                    onClick={() =>
                                                                        this.setState(
                                                                            {
                                                                                selectedMember: JSON.parse(
                                                                                    JSON.stringify(val)
                                                                                ),
                                                                            },
                                                                            () => showModalNoOutsideClick(FamilyGroupModal.ID)
                                                                        )
                                                                    }
                                                                >
                                                                    {val.displayName}
                                                                </a>)
                                                            }
                                                        },
                                                        {
                                                            label: "Date of Birth",
                                                            key: "dateOfBirth",
                                                            formatFunc: parseDate
                                                        },
                                                        {
                                                            label: "Age Out",
                                                            key: "",
                                                            rawFormat: (val) => {
                                                                let age = calculateAge(val.dateOfBirth);
                                                                if (age < 18){ return (' Minor ') }
                                                                else {
                                                                    if(val.uid !== this.context.uid ){
                                                                        return (
                                                                            <div className="pl-2" tabIndex={0} aria-label="Age patient out of Family group" role="button" onKeyDown={(e) => {if(e.key === "Enter"){this.removeFamilyMember(val) }}}  onClick={(e) => this.removeFamilyMember(val)  } >
                                                                                {/* <AiOutlineCheckCircle color={'#14410B'} size={30} /><span className={'ml-2 text-success'} style={{ fontSize: '1em' }}>Images Saved</span></div> : <div className="invisible"></div>} */}
                                                                                <AiOutlineClose color={"red"} size={20} />
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                    table_data={this.state.familyMembers}
                                                    columnClickedCallback={(col) => {
                                                        this.useSorter(col);
                                                    }}
                                                />)}
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            { this.state.familyGroup === null &&
                                            <button type="button" className="btn btn-primary" onClick={async () => { 
                                                const { value: familyGroupName} = await sweetalert.fire({
                                                    title: 'Enter new group name',
                                                    input: 'text',
                                                    showCancelButton: true,
                                                    confirmButtonText: 'Add',
                                                    inputValidator: (value) => {
                                                        if (isBlank(value)|| isOnlyWhitespace(value)) {
                                                            return 'New Family Group cannot be blank'
                                                        }
                                                        else if (value.length > 200) {
                                                            return 'New Family Group must be 200 characters or less'
                                                        }
                                                    }
                                                })
                                                if(familyGroupName){
                                                    PatientAPI.createFamilyGroup(familyGroupName).then((response)=>{
                                                        console.log('createFamilyGroup response',response)
                                                        PatientAPI.getFamilyGroupMembers().then((response)=>{
                                                            this.setState({familyGroup: response.data.familyGroup, familyMembers: response.data.members})
                                                        })
                                                    });
                                                }
                                                // showModalNoOutsideClick(FamilyGroupModal.ID) 
                                            }}> Create Family Group </button>}

                                            { this.state.familyMembers && this.state.familyMembers.length > 0 && 
                                            <button type="button" className="btn btn-success pull-right" 
                                                onClick={() => { this.setState({selectedMember: null}, ()=>{showModalNoOutsideClick(FamilyGroupModal.ID) })
                                            }}> Add Family Member</button>}
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>}
            </>
        )
    }
}