import React, {useCallback, useState, useLayoutEffect} from "react";
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import Overlay from "../Overlay";
import {sweetalert} from "../../App";
import { isEmptyObject } from "jquery";
import AdminAPI from "../../network/AdminAPI";
import { buildRow, hideModal } from "../../util/FormatUtil";
import { isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";


type SubmissionFileUploadProps = {
    handleSubmit
    clearParentState
};

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  padding-top: 1rem;
  background-color: rgba(236, 236, 236, 0.93);
  outline: none;
  transition: border .24s ease-in-out;
`;

const SubmissionFileUploadModal = (props:SubmissionFileUploadProps, ref) => { 
    const ID = "submissionfileupload";
    const [files, setFiles] = useState([])
    const [URL, setURL] = useState('')
    const [fileToUpload, setFileToUpload] = useState<Blob | {}>({})
    const [fileDroppedBool, setFileDroppedBool] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    useLayoutEffect(() => {
        $(`#${ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });

    }, [])


    const onDrop = useCallback(acceptedFiles => {
        setShowLoading(true)
        const newFiles = acceptedFiles.filter(af => !files.some(f => f.name === af.name));
    
        if (newFiles.length < acceptedFiles.length) {
        sweetalert.fire("", `<b>${acceptedFiles.find(af => files.some(f => f.name === af.name)).name}</b> already selected.`, "error");
        }

        if (newFiles.length > 0) {
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        }
        
        setShowLoading(false);
    }, [files])


    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        onDrop, multiple: true
    });

    const removeFile = (index) => {
        setFiles(currentFiles => currentFiles.filter((_, i) => i !== index));
    }

    React.useImperativeHandle(ref, () => ({
        clearSubmissionFileModalState(){
            setFiles([])
        }
    }))

    const clear = () => {
        setFiles([])
    }


    return (
        <>
        <Overlay show_loading={showLoading} zIndex={100003}/>
        <div ref={ref} className="modal fade form_modal" id={ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-xl" role="document">
                <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Submission File Upload</h5>
                            <button  style={{outline: 'none'}} type="button" className="close" aria-label="Close" onClick={() =>{
                                if(files && files.length > 0){
                                    sweetalert.fire({
                                        icon: 'warning', 
                                        title: '', 
                                        text: 'Selected files to upload will not be saved',
                                        showCloseButton: false,
                                        showConfirmButton: true,
                                        confirmButtonText: 'Remove Files',
                                        confirmButtonColor: '#C31230',
                                        showDenyButton: true,
                                        denyButtonText: 'Go Back',
                                        denyButtonColor: 'green'
                                    }).then((result) => {
                                        if(result.isConfirmed){
                                            clear()
                                            props.clearParentState()
                                            hideModal("submissionfileupload");
                                        }
                                        else{
                                            return;
                                        }
                                    })
                                }
                                else{
                                    clear()
                                    hideModal("submissionfileupload");
                                }
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">                            
                            {files && files.length > 0 && (
                                <>
                                    {files.map((file, index) => (
                                        <div className="form-group row">
                                        <label htmlFor={`SelectedFile${index}`} className="col-12 col-sm-4 col-form-label text-center text-md-left">Selected File #{index + 1}</label>
                                        <div className="col-11 col-sm-7 p-0 pt-2 m-0 text-center text-md-left" id={`SelectedFile${index}`}>
                                                {file.name}
                                        </div>
                                        <div className="col-1">
                                            <button  style={{outline: 'none'}} type="button" className="close" aria-label="Close" onClick={() => {
                                                removeFile(index)
                                            }}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                        </div>
                                    </div>
                                    ))}
                                </>
                            )}
                            {files && files.length >= 10 ? <div className="d-none"></div>:
                            <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                                <input aria-label="FileDrop" {...getInputProps()} />
                                <p>Drop files here or click to select files</p>
                            </Container>
                            }
                        </div>  
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-primary mr-auto" onClick={() => {
                                props.handleSubmit(null);
                                hideModal("submissionfileupload")
                            }}
                            >
                            No Files
                            </button> */}
                            <button type="button" className="btn btn-primary" onClick={() => {
                                props.handleSubmit(files);
                                hideModal("submissionfileupload")
                            }}
                            >
                            Save
                            </button>
                        </div>
                    </div>           
                </div>
            </div>
        </div>
    </>
    );
}

export default React.forwardRef(SubmissionFileUploadModal);