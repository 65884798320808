import React from "react";
import { hideModal } from "../../util/FormatUtil";
import Parser from "html-react-parser";

interface ConsentProps{
    onConfirmation:(didAccept) => void
    entity:{ID:number,Name:string,FacilityID:number,AccessCode:string}
    service?
    fromRelease?
}
class ReleaseConsent extends React.Component<ConsentProps, any> {

    public static readonly ID = "release_consent"

    constructor(props) {
        super(props);
        this.confirm = this.confirm.bind(this);
    }

    confirm(didAccept){
        this.props.onConfirmation(didAccept);
        hideModal(ReleaseConsent.ID)
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="modal fade form_modal" id={ReleaseConsent.ID} style={{zIndex: 100007}} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true"
                 data-keyboard="false" data-backdrop="static">
                <div className="modal-dialog modal-lg modal-xl"  role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Informed Consent for {this.props.entity.Name}</h5>
                        </div>
                        <div className="modal-body ">
                            {this.props.service ? Parser(this.props.service.ROIConsentAuth) : ''}
                           
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => this.confirm(this.props.fromRelease ? true : {accepted: true, serviceID: this.props.service.ID})}>I Consent</button>
                            <button type="button" className="btn btn-danger" onClick={() => this.confirm(this.props.fromRelease ? false : {accepted: false, serviceID: this.props.service.ID})}>I Decline</button>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
export default ReleaseConsent
