import React from "react";
import {FIELD_TYPE} from "../FilterCard";
import ModalForm from "./ModalForm";
import {sweetalert} from "../../App";
import SubmissionAPI from "../../network/SubmissionAPI";


class ConsentingModal extends React.Component<{consenting:[], result}, any>{
    public formRef: React.RefObject<ModalForm>
    public static readonly ID = "consenting_modal"

    constructor(props) {
        super(props);
        this.state = {}
        this.formRef = React.createRef();
        this.submit = this.submit.bind(this);
    }

    componentDidMount(): void {
        $(`#${ConsentingModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    public static display(){
        window["$"]("#" + ConsentingModal.ID).modal('show');
    }

    //route not complete
    async submit(){
        if(!this.state.entity || this.state.entity === ''){
            return sweetalert.fire("You must select a facility.")
        }
        let result = await SubmissionAPI.consent(this.state)

    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <ModalForm ref={this.formRef}
                       fields={[
                           {
                               label:"Consenting Entity",
                               key:"entity",
                               type:FIELD_TYPE.SELECT,
                               options:this.props.consenting,
                               isMapped:true
                           }
                       ]}
                       onChange={(e) => this.setState(e)}
                       ID={ConsentingModal.ID}
                       onSubmit={(formState) => this.submit()}
                       title={"Patient Release Consent"}/>
        )
    }
}

export default ConsentingModal;