import React from "react";
import InformedConsentModal from "./modals/InformedConsentModal";
import {parseDate, showModalNoOutsideClick} from "../util/FormatUtil";
import Parser from "html-react-parser";

interface ConsentProps {
    onAcknowledge: (e) => void
    onSubmit: () => void
    facility: {
        FacilityName?: string
        Address?: string
        days?: { day: string }[]
    },
    day:string
    time:string
    legalName:string
        //will get serivces so we can display release info for each service
    services?
    setSelectedService?
}

interface ConsentState {
    acknowledged
    selectedService?
}

export class ConsentSummary extends React.Component<ConsentProps, ConsentState> {
    constructor(props) {
        super(props);
        this.state = {acknowledged:""};
    }

    private buildSummary(): any {
        if (!this.props.facility || !this.props.day || !this.props.time)
            return <p>Please select a location, date, and time.</p>;
        return (

            <div className={"row"}>
            <div className={"col-12"}>You are scheduling the follow services: 
                {this.props.services.map(ss => (
                    <div className="my-1"><b>{ss.Name}</b></div>
                ))} at <b>{this.props.facility.FacilityName}</b> located
                at <b>{this.props.facility.Address}</b> on <b>{parseDate(this.props.day)}</b> at <b>{this.props.time}</b>.
            </div>
            <div className="col-4">
                {/* <span className="float-right pl-2">
                    <button
                        className={"btn btn-primary verlag-bold"}
                        style={{ float: "right" }}
                        onClick={() =>
                            showModalNoOutsideClick(InformedConsentModal.ID)
                        }
                        >
                        Informed Consent
                    </button>
                </span> */}
            </div>
            </div>
        )
        
    }

    render() {
        return (
            <div className="card mb-2  mt-2">
                <div className="card-header verlag-bold">
                    <h4>Summary</h4>
                </div>
                
                <div className="card-body">
                    {this.buildSummary()}
                    <div className="row mt-3" id={'infcon'}>
                        <h4 className="col-12">
                            <a href="#infcon" style={{color: '#317ab5'}} onClick={() => showModalNoOutsideClick(InformedConsentModal.ID) }>Informed Consent</a>
                        </h4>
                    </div>
                    <br/>
                        {this.props.services.map(ss => (
                            <div className={"p-2 mb-2"} style={{borderWidth: '1px', borderColor: 'lightgray', borderStyle: 'solid', borderRadius: 15}}>
                                <h5 className="text-center pb-2">
                                    <span >{ss.Name}</span>
                                   
                                </h5>
                                <div>{Parser(ss.Summary)}</div>
                            </div>
                        ))}
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="acknowledge"
                               value={this.state.acknowledged}
                               onChange={(e) => this.setState({acknowledged: e.target.checked}, () => this.props.onAcknowledge(e.target.checked))}/>
                        <label className="form-check-label" htmlFor="acknowledge">
                            I acknowledge and agree that I have read, understand, and agreed to the
                            statements contained within this form.
                        </label>
                    </div>
                </div>
                <div className={"card-footer"}>
                    <button className={"btn btn-outline-primary "} onClick={this.props.onSubmit}>Next</button>
                    <p><b>* This is not a final appointment. You will need to hit 'Submit Appointment' on
                        the next page before your actual appointment is scheduled. You will receive an
                        email when your appointment has been scheduled.</b></p>
                </div>
            </div>);
    }
}