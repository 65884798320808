import React from "react";
import SystemAPI from "../network/SystemAPI";
import InBetweenOverlay from "./InBetweenOverlay";
import Overlay from "./Overlay";

interface HomeState {
    logoURL:string
    productName:string
    buttonColorHexValue:string
    showLoading:boolean
}

class Home extends React.Component<{}, HomeState> {
    constructor(props) {
        super(props);
        this.state = {
            logoURL: '',
            productName: '',
            showLoading: false,
            buttonColorHexValue: '',
        };
    }

    componentDidMount(): void {

        this.setState({showLoading: true},  async () => {
            document.title = 'Patient Home Page';
            document.body.style.backgroundColor = 'black';
               
            let currentURL = window.location.href;

            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let backgroundImageURL = data.ProductBackgroundImageURL;
                //@ts-ignore
                document.body.style.backgroundImage = `url(${backgroundImageURL}`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";
                this.setState({logoURL: data.ProductAltLogoURL, productName: data.Name, buttonColorHexValue: data.ProductButtonColorHexValue, showLoading: false});
            })
        })
        
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>
            {/* <Overlay show_loading={this.state.showLoading} /> */}
            {/* <InBetweenOverlay showInBetween={true} zIndex={-10}/> */}
            <main id="main-content" tabIndex={-1} aria-label="Patient Home Page">
                <div className={this.state.showLoading ? "d-none" : "container min-vh-100 "}>
                    <div className="row responsive-p mt-5">
                        <div className={"col-12 pb-4"}>
                            <h1 className={"text-center verlag-light"}>
                                <img src={this.state.logoURL}
                                    className={"homepage-logo"}
                                    alt={this.state.productName}/>
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-center text-dark mt-3">Welcome to the <b>Streamline Employee Portal</b></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center mt-3">
                            <a href={"/login"}>
                                <button className={"btn btn-lg text-light"} style={{backgroundColor: this.state.buttonColorHexValue}}>
                                    Log In
                                </button>
                            </a>
                            {/* <a href={"/register"}>
                                <button className={"btn btn-lg text-light ml-2"} style={{backgroundColor: this.state.buttonColorHexValue}}>
                                    Register
                                </button>
                            </a> */}
                            <a href={"/appointment"}>
                                <button className={"btn btn-lg text-light ml-2"} style={{backgroundColor: this.state.buttonColorHexValue}}>
                                    Appointment
                                </button>
                            </a>
                            {/* <a href={"/results"}>
                                <button className={"btn btn-lg text-light ml-2"} style={{backgroundColor: this.state.buttonColorHexValue}}>
                                    Results
                                </button>
                            </a> */}
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>)
    }
}
export default Home;