import React from "react"
import { buildRow, getLabel } from "../../util/FormatUtil";
import { getConvertedValue } from "../resultReports/helpers/utils";


interface ViewTestInfoModalState{

}

interface ViewTestInfoModalProps{
    selectedSample
    tests
    results
    getResultsButtonBoolean
}

class ViewTestInfoModal extends React.Component<ViewTestInfoModalProps, ViewTestInfoModalState>{
    public static ID = "ViewTestInfo"
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // console.log('ViewTestInfoModal props', this.props)
        let testAndResultsArray = []
        let tempMap;
        let tempIDs;
        let hasAdditionalResultValue = false
        let noTests = false

        if(this.props.selectedSample.TestsAndResultsMap){
            tempMap = JSON.parse(this.props.selectedSample.TestsAndResultsMap)
        }

        if(this.props.selectedSample.TestIDs){
            tempIDs = JSON.parse(this.props.selectedSample.TestIDs)
        }

        //get results that have AllowNonStandardResultValue flag set to true
        let resultsThatAllowNonStandard = this.props.results.filter(r => r.AllowNonStandardResultValue);
        resultsThatAllowNonStandard = resultsThatAllowNonStandard.map(r => {return r.value})

        if(tempIDs && tempIDs?.length === 0){
            //has no tests
            noTests = true
        }
        else if(tempMap && tempMap?.length === tempIDs?.length){
            //every test has a result
            let temp = []
            for (let i = 0; i < tempMap.length; i++) {
                const tempMapElement = tempMap[i];
                
                if(resultsThatAllowNonStandard.includes(tempMapElement.ResultID)){
                    temp.push({Test: getLabel(tempMapElement.TestID, this.props.tests).label, Result: getLabel(tempMapElement.ResultID, this.props.results).label, ResultValue: tempMapElement.ResultValue})
                    hasAdditionalResultValue = true
                }
                else{
                    temp.push({Test: getLabel(tempMapElement.TestID, this.props.tests).label, Result: getLabel(tempMapElement.ResultID, this.props.results).label})
                }
            }
            testAndResultsArray = temp
        }
        else if(tempIDs && (!tempMap || tempMap?.length === 0) && tempIDs?.length > 0){
            //has test but no results
            for (let i = 0; i < tempIDs.length; i++) {
               const element = tempIDs[i];
               testAndResultsArray.push({Test: getLabel(element, this.props.tests).label, Result: 'No Result'})
            }
        }
        else if(tempMap && tempIDs && tempMap?.length > 0 && tempIDs?.length > 0 && tempIDs?.length !== tempMap?.length){
            //not every test has result
            let temp = []
            let notIncluded = []
            for (let i = 0; i < tempMap.length; i++) {
                const tempMapElement = tempMap[i];
                if(resultsThatAllowNonStandard.includes(tempMapElement.ResultID)){
                    temp.push({Test: getLabel(tempMapElement.TestID, this.props.tests).label, Result: tempMapElement.ResultID ? getLabel(tempMapElement.ResultID, this.props.results).label : 'No Result', ResultValue: tempMapElement.ResultValue})
                    hasAdditionalResultValue = true
                }
                else{
                    temp.push({Test: getLabel(tempMapElement.TestID, this.props.tests).label, Result: tempMapElement.ResultID ? getLabel(tempMapElement.ResultID, this.props.results).label : 'No Result'})
                }
            }
            for (let i = 0; i < tempIDs.length; i++) {
                const tempIDsElement = tempIDs[i];
                if(!tempMap.find(t => t.TestID === tempIDsElement)){
                    notIncluded.push(tempIDsElement)
                }
            }

            for (let i = 0; i < notIncluded.length; i++) {
                const element = notIncluded[i];
                temp.push({Test: getLabel(element, this.props.tests).label, Result: 'No Result'})
            }

            testAndResultsArray = temp

        }

        return (
            <div className="modal fade form_modal" id={ViewTestInfoModal.ID} tabIndex={-1} role="dialog"
                 aria-label="Test Info"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0 m-0">
                            <div className="container-fluid  min-vh-10">
                                <div className={"row"}>
                                    <div className="col-12 pt-2">
                                        <div className="card mb-2 text-center">
                                            <div className="card-header verlag-bold">
                                                <h4 style={{fontWeight: 'bold'}}>Test Info
                                                    <button style={{outline: 'none'}}  type="button" className="close pr-4" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                    </button>
                                                </h4>
                                            </div>
                                            <div className="card-body">
                                                {noTests ? (
                                                    <div>No Tests Assigned</div>
                                                ) :
                                                testAndResultsArray && testAndResultsArray.length > 0 && testAndResultsArray.map(t => (
                                                    <div className="row">
                                                        <div className={hasAdditionalResultValue ? "col-4 pl-md-0 ml-md-0" :  "col-5 pl-md-0 ml-md-0"}>
                                                            {buildRow("Test",
                                                                <input className="form-control ml-2 ml-md-0"
                                                                    type={"text"}
                                                                    name={t.Test}
                                                                    value={t.Test}
                                                                    disabled={true}
                                                                    aria-label="Test Name"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className={hasAdditionalResultValue ? "col-4 ml-md-0" : "col-6 ml-md-0"}>
                                                            {buildRow("Result",
                                                                <input className="form-control ml-2 ml-md-0"
                                                                    type={"text"}
                                                                    name={t.Result ? t.Result : ''}
                                                                    value={t.Result ? t.Result : ''}
                                                                    disabled={true}
                                                                    aria-label="Test Result"
                                                                />
                                                            )}
                                                        </div>

                                                        {hasAdditionalResultValue && (
                                                        <div className="col-4 ml-md-0">
                                                            {buildRow("Result Value",
                                                                <input className="form-control ml-2 ml-md-0"
                                                                    type={"text"}
                                                                    name={t.ResultValue || t.ResultValue == 0 ? getConvertedValue(Number(t.ResultValue)) : ''}
                                                                    value={t.ResultValue || t.ResultValue == 0 ? getConvertedValue(Number(t.ResultValue)) : ''}
                                                                    disabled={true}
                                                                    aria-label="Test Result Value"
                                                                />
                                                            )}
                                                        </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer mb-2 mr-2 ml-0" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                <a className={this.props.getResultsButtonBoolean ? '' : 'd-none'} href={this.props.selectedSample ? "/results?fname=" + this.props.selectedSample?.PatientFirstName?.trim() +"&lname=" + this.props.selectedSample?.PatientLastName?.trim()
                                    +"&dob=" + this.props.selectedSample?.PatientDOB + "&conf=" + this.props.selectedSample?.ReqNum +"&norecord=true" : ""} target={"_blank"}>
                                    <button type="button"  className={this.props.getResultsButtonBoolean ? 'btn btn-success float-right mb-2' : 'd-none'}  >Get Results</button>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewTestInfoModal;