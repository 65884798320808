import React from "react";
import Parser from "html-react-parser";
import SystemAPI from "../network/SystemAPI";
import { AuthContext } from "../context/AuthContext";
import { sweetalert } from "../App";

export interface ConfirmationState {
    linkedApptsID: string
    code: string
    services: string
    htmlTemplates:any
    buttonColorHexValue:string
    logoURL:string
}

class Confirmation extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            htmlTemplates: [],
            buttonColorHexValue:"",
            logoURL: ""
        }
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search); 
        const currentURL = window.location.href;

        SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
            this.setState({
                buttonColorHexValue: data.ProductButtonColorHexValue,
                logoURL: data.ProductLogoURL // blue logo
            })
        })
        this.setState({linkedApptsID: query.get('apptCode'), code: query.get('code'), services: query.get('services') }, ()=>{
            fetch("/api/system/confirmationTemplate?services=" + this.state.services)
                .then(res => res.json() )
                .then( data => {
                    this.setState({ htmlTemplates: data})
                })
                .catch((err)=>{console.error(err);})
        });
    }

    redirectToPortal = () => {
        sweetalert.fire({
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Continue",
            title: "Attention",
            html: "<p>You will be redirected to Streamline Employee Portal and the Registration Form.</>",
            icon: "info"
        }).then((result) => {
            if (result.isConfirmed) {
                //TODO: After testing, this needs to be a production url
                window.open("https://demo.patient.immytech.com/register", "_blank"); // open in new window
            }
        });
    }
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let code = this.state.code ? this.state.code : ''
        code = code.split(",")
        return(
            <React.Fragment>
                <div className="container-fluid  min-vh-100 ">
                    <div className={"row"}>
                        <div className="col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Appointment# {this.state.linkedApptsID}</h4>
                                </div>
                                <div className="card-body">
                                    <div>
                                    {/* <h1 className={"verlag-bold"}>{this.state.services?.length > 1 ? 'Your codes' : 'Your code'}: {code}</h1><hr /> */}

                                    <p><b>Please verify</b> that you have received an email from <b>no-reply@immytech.com</b> containing your appointment code and details. This email will also contain confirmation numbers for the services chosen. <b>You are required to bring
                                        this appointment code and any confirmation codes to your appointment.</b></p>
                                    <p>If you do not receive an email containing this information within 15 minutes, please resubmit your appointment request.</p>
                                    </div>
                                    <hr />
                                    

                                    {this.state.htmlTemplates.map((t, i) => {
                                        return (
                                        <div className={"p-2 mb-2"} style={{borderWidth: '1px', borderColor: 'lightgray', borderStyle: 'solid', borderRadius: 15}}>
                                            <div className="row">
                                                <div className={"col-12 col-md-8 mb-2 mb-md-0"}>
                                                    <h4 className="verlag-bold">{t.Name}</h4>
                                                </div>   
                                                <div className={"col-12 col-md-4 mb-2 mb-md-0 text-md-right"}>
                                                    <h4>Conf# {code[i]}</h4>
                                                </div>       
                                            </div>
                                            <div className={"row"}>
                                                <div className="col-12">
                                                {t.HTMLTemplate && Parser(t.HTMLTemplate)}
                                                </div>
                                            </div>                                     
                                        </div>
                                        )
                                    })}

                                    {/* <h4 className="verlag-bold">After testing</h4>
                                    <p>A text and email will be sent to you once your test results are ready with instructions on how to securely obtain your results. Most test results will be available within 3 business days (excluding holidays and weekends) after your scheduled appointment date. </p>
                                    { !window.location.pathname.includes("/admin") &&  
                                        <div className="d-grid mb-3">
                                            <p className="d-fles justify-center text-center">
                                                Access all of your results in one convenient location with Streamline Patient Portal.
                                            </p>
                                            <div className="d-flex justify-center">
                                                <button
                                                    style={{ backgroundColor: this.state.buttonColorHexValue }}
                                                    className="btn btn-lg"
                                                    onClick={this.redirectToPortal}>
                                                    <img src={this.state.logoURL}
                                                        alt="streamlineLogo"
                                                        style={{ "width": "300px", "margin": "10px" }}
                                                    />
                                                    <p className={"text-light"}>Register Here</p>
                                                </button>
                                            </div>
                                        </div>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Confirmation;