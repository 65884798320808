import React from "react";
import ModalForm from "./ModalForm";
import ImageCapture from 'react-image-data-capture';
import {sweetalert} from "../../App";
import {hideModal} from "../../util/FormatUtil";

interface InsuranceImageProps{
    onFrontImgChange: (img: File) => void;
    onBackImgChange: (img: File) => void;
    clearFiles: () => void;
    handleLoading;
    handleImageSavedButton?: (bool:boolean) => void;
    captureClicked?;
    closeInBetween?: () => void
    clearImageState?: () => void
}

interface InsuranceImageState{
    frontImageURL: string
    backImageURL: string
}

export class InsuranceImageModal extends React.Component<InsuranceImageProps, InsuranceImageState>{
    public formRef: React.RefObject<ModalForm>;

    constructor(props) {
        super(props);
        this.state = {
            frontImageURL:'',
            backImageURL:'',
        };
        this.formRef = React.createRef()
    }

    componentDidMount(): void {
        $(`#${InsuranceImageModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    onFrontCapture = (imageData) => {
        this.setState({frontImageURL: imageData.webP})
        let frontFile = new File([imageData.blob], 'front.png', {type: 'image/png'});
        this.props.onFrontImgChange(frontFile);
    };

    onBackCapture = (imageData) => {
        this.setState({backImageURL: imageData.webP})
        let backFile = new File([imageData.blob], 'back.png', {type: 'image/png'});
        this.props.onBackImgChange(backFile);
    };

    clear = () => {
        hideModal(InsuranceImageModal.ID)
        this.props.handleLoading();
        this.setState({frontImageURL: '', backImageURL: ''});
        this.props.handleImageSavedButton(false)
        this.props.clearFiles();
    }

    handleCloseModalInsuranceCapture(){
        hideModal(InsuranceImageModal.ID)
        this.props.closeInBetween()
        this.props.clearImageState()
        this.clear()
    }



    public static readonly ID = "InsuranceImage"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={InsuranceImageModal.ID} tabIndex={-1} role="dialog" style={{zIndex: 100006}} data-backdrop="static" data-keyboard="false"
                     aria-label="Insurance Images"
                     aria-hidden="true">
                    <div className="modal-dialog modal-xl " role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid  min-vh-10">
                                    <div className={"row"}>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                                            <div className="card mb-2 text-center">
                                                <div className="card-header verlag-bold">
                                                    <h4 style={{fontWeight: 'bold'}}>Insurance Card Image Capture
                                                        <button style={{outline: 'none'}}  type="button" className="close pr-4"
                                                                aria-label="Close" onClick={() => this.handleCloseModalInsuranceCapture()}>
                                                            <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                        </button>
                                                    </h4>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className="form-group row">
                                                        <div className={"col-sm-6 p-0 m-0"}>
                                                            <div className="row ml-10">
                                                                <label htmlFor={"FrontCardCapture"} className={"col-form-label"} >Click red dot to capture <span className={'text-danger font-weight-bold'}>FRONT</span> of card</label>
                                                            </div>
                                                            {this.props.captureClicked
                                                                ?
                                                                <ImageCapture
                                                                    id={"FrontCardCapture"}
                                                                    onCapture={this.onFrontCapture}
                                                                    width={300}
                                                                    userMediaConfig={{video: true}}
                                                                />
                                                                :
                                                                <div></div>
                                                            }

                                                            {this.state.frontImageURL &&
                                                            <div className={'mt-2'}>
                                                                <div className={'font-weight-bold text-sm-center text-muted'}>Please make sure image is easy to read</div>
                                                                <img src={this.state.frontImageURL} alt="captured-img" />
                                                            </div>
                                                            }
                                                        </div>

                                                        <div className={"col-sm-6 p-0 m-0 "}>
                                                            <div className="row ml-10">
                                                                <label htmlFor={"BackCardCapture"} className={"disabled col-form-label"} >Click red dot to capture <span className={'text-danger font-weight-bold'}>BACK</span> of card</label>
                                                            </div>
                                                            {this.props.captureClicked
                                                                ?
                                                                <ImageCapture
                                                                    id={"BackCardCapture"}
                                                                    onCapture={this.onBackCapture}
                                                                    width={300}
                                                                    userMediaConfig={{video: true}}
                                                                />
                                                                :
                                                                <div></div>
                                                            }

                                                            {this.state.backImageURL &&
                                                            <div className={'mt-2'}>
                                                                <div className={'font-weight-bold text-sm-center text-muted'}>Please make sure image is easy to read</div>
                                                                <img src={this.state.backImageURL} alt="captured-img" />
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {!this.state.frontImageURL || !this.state.backImageURL ?
                                                <div className="invisible"></div>
                                                :
                                                <div className="card-footer">
                                                    <button className={"btn btn-primary float-right"} onClick={() => {
                                                        sweetalert.fire({
                                                            icon: 'info',
                                                            title: '',
                                                            text: "Insurance Card Images will not be uploaded until record is submitted/saved."
                                                        }).then(result => {
                                                            hideModal(InsuranceImageModal.ID); this.setState({frontImageURL: '', backImageURL: ''}); this.props.handleLoading(); this.props.closeInBetween(); this.props.handleImageSavedButton(true)
                                                            });
                                                        }}>
                                                        Save
                                                    </button>
                                                </div>
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

