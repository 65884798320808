import React from "react";
import Overlay from "../Overlay";
import SamplesAPI from "../../network/SamplesAPI";
import AdminAPI from "../../network/AdminAPI";
import BatchesAPI from "../../network/BatchesAPI";
import { AuthContext } from "../../context/AuthContext";
import { isMobile } from 'react-device-detect';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Bar, Line, Doughnut } from 'react-chartjs-2';
import { FakeTasks } from "../../types/FakeTasks";
import moment from "moment";


ChartJS.register(ArcElement, Tooltip, Legend);

const employeePersonalHealthChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      datasets: [
        {
          label: 'Weight',
          data: [165, 159, 180, 181, 156, 155],
          fill: false,
          borderColor: 'rgb(255, 99, 132)',
          tension: 0.1,
        },
        {
          label: 'Blood Pressure',
          data: [125, 80, 60, 71, 80, 75],
          fill: false,
          borderColor: 'rgb(54, 162, 235)',
          tension: 0.1,
        },
        {
          label: 'Heart Rate',
          data: [70, 55, 75, 82, 65, 70],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
      ],
  }
  
  const employeePersonalHealthChartOptions = {
  };
  
  const employeeCompletedTasksDoughtnutChartData = {
      labels: ['Complete', 'Incomplete'],
      datasets: [
        {
          data: [75, 25], // Percentage of compliant and non-compliant employees
          backgroundColor: ['#36A2EB', '#FF6384'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384'],
        },
      ],
  }
  
  const employeeCompletedTasksDoughtnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 20,
        },
      },
    },
  };
  
  const employeeCommonHealthRisksBarChartData = {
    
  }
  
  const employeeCommonHealthRisksBarChartOptions = { 
  }
  
  let employeeCardsArray = [
    {label: 'Appointments', imageLink: "https://storage.googleapis.com/streamline-bucket/appointment.svg", url: "/admin/appointmentform"},
    {label: 'Vaccines', imageLink: "https://storage.googleapis.com/streamline-bucket/injection.svg", url: "/admin/vaccinerecords"},
    {label: 'Evaluations', imageLink: "https://storage.googleapis.com/streamline-bucket/treatment.svg", url: "/admin/treatmentrecords"},
    {label: 'Tasks', imageLink: "https://storage.googleapis.com/streamline-bucket/clipboard.png", url: "/admin/Tasks"},
  ]
  
  let employeeChartsArray = [
    {label: 'Employee Health Metrics', chartType: 'line', chartData: employeePersonalHealthChartData, options: employeePersonalHealthChartOptions},
    {label: 'Completed Tasks Status', chartType: 'doughnut', chartData: employeeCompletedTasksDoughtnutChartData, options: employeeCompletedTasksDoughtnutChartOptions },
    {label: 'Tasks', chartType: 'tasks', chartData: employeeCommonHealthRisksBarChartData, options: employeeCommonHealthRisksBarChartOptions }
  ]
  

interface AdminLandingPageState {
  showLoading?: boolean;
  facilitySamplesArray
  facilities
  batches
}

export default class AdminLandingPage extends React.Component<
  any,
  AdminLandingPageState
> {
    static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      facilitySamplesArray: [],
      facilities: [],
      batches: []
    };
  }

  componentDidMount(): void {
    this.setState({ showLoading: true }, async () => {
        this.setState({showLoading:false});
    });
  }

  getCard(data){
    return(
      <div className="col-12 col-xl-3">
        <a href={data.url} style={{textDecoration: 'none'}}>
        <div className="card mb-3" style={{backgroundColor: '#21212E'}}>
          <div className="row no-gutters">
            <div className="col-md-3 text-center">
              <img src={data.imageLink} className="card-img pt-2 navIcon" alt="..." style={{width: '4em'}} />
            </div>
            <div className="col-md-9">
              <div className="card-body text-center">
                <h3 className="card-title text-white">{data.label}</h3>
              </div>
            </div>
          </div>
        </div>
        </a>
      </div>
  )}

  getChart(data){
    return(
      <div className="col-12 col-lg-4">
        <div className="card mb-3 text-center" style={{borderColor: '#21212E'}}>
          <div className="card-body" style={window.innerWidth > 1540 ? {height: '450px'} : {height: '400px'}}>
            <div className="row no-gutters">
              <div className="col-12">
                <h3 className="card-title">{data.label}</h3>
              </div>
            </div>
            {data && data.chartType === 'bar' 
            ?
            <div className="row no-gutters">
              <div className="col-12">
                <div>
                  <Bar 
                    height={200}
                    data={data.chartData} 
                    options={data.options}
                  />
                </div>
              </div>
            </div>
            : data && data.chartType === 'pie'
            ?
            <div className="row no-gutters">
              <div className="col-12">
                <div>
                  <Pie 
                    data={data.chartData} 
                    options={data.options}
                    style={{ height: '300px' }}
                  />
                </div>
              </div>
            </div>
            : data && data.chartType === 'line'
            ?
            <div className="row no-gutters">
              <div className="col-12">
                <div>
                  <Line 
                    height={200}
                    data={data.chartData} 
                    options={data.options}
                  />
                </div>
              </div>
            </div>
            : data && data.chartType === 'doughnut'
            ?
            <div className="row no-gutters">
              <div className="col-12">
                <div >
                  <Doughnut 
                    height={300}
                    data={data.chartData} 
                    options={data.options}
                  />
                </div>
              </div>
            </div>
            
            : data && data.chartType === 'tasks'
            ?
            <div className="row no-gutters">
              <div className="col-12">
                <div >
                {this.renderEmployeeTaskList()}
                </div>
              </div>
            </div>
            
            :
            null
          }
          </div>
        </div>
      </div>
  )}

  getPageBasedOnUser(){
    return (
      <main id="main-content" tabIndex={-1} aria-label="Home Page">
          <div className="container-fluid">
              <div className={"row mt-3"}>
                  {employeeCardsArray.map((f, index) => {
                    return (
                      <>
                        {this.getCard(f)}
                      </>
                    )
                  })}
              </div>
              <div className={"row"}>
                  {employeeChartsArray.map((f, index) => {
                    return (
                      <>
                        {this.getChart(f)}
                      </>
                    )
                  })}
              </div>
          </div>
        </main>
    )
  }

  renderEmployeeTaskList(){
    return (
      <ul className="list-group">
        {FakeTasks?.map(task => {
          return (
            <>
              <li className="list-group-item">
                {task.Title} <br/> <small>{task.Description}</small> <br /> <small>Due: {moment(task.DueDate).format('MM/DD/YYYY h:mm a')}</small>
              </li>
            </>
          )
        })}
      </ul>
    )
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {

    return (
    
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        {this.getPageBasedOnUser()}




        {/* {!isMobile ? <div className="container-fluid">
        <main id="main-content" tabIndex={-1} aria-label="Admin Landing Page">
          <div className={"row"}>
            <div className="col-12 pt-2">
                <div className="row card-deck">
                    <div className="col-sm-6 col-md-6 col-lg-3 p-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Appointments</h5>
                                <p className="card-text">Sign up for Services</p>
                                <a role="button" className="btn immySubmitButtonOutline"
                                    href={`/admin/appointmentform`} > Appointments
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 p-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Vaccines</h5>
                                <p className="card-text">View Vaccine Records</p>
                                <a role="button" className="btn immySubmitButtonOutline"
                                    href={`/admin/vaccinerecords`} > Vaccines 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 p-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Treatments</h5>
                                <p className="card-text">View Treatment Records </p>
                                <a role="button" className="btn immySubmitButtonOutline"
                                    href={`/admin/treatmentrecords`} > Treatments 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 p-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Tests</h5>
                                <p className="card-text">View Test Records</p>
                                <a role="button" className="btn immySubmitButtonOutline"
                                    href={`/admin/Tests`} > Tests 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 p-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Profile</h5>
                                <p className="card-text">Update Profile Info</p>
                                <a role="button" className="btn immySubmitButtonOutline"
                                    href={`/admin/profilemanagement`}> Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 p-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Family Groups</h5>
                                <p className="card-text">Manage Family Groups</p>
                                <a role="button" className="btn immySubmitButtonOutline"
                                    href={`/admin/FamilyGroup`} > Family
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
          </div>
          </main>
        </div> 
        : 
        <div className="container-fluid">
        <div className={"row"}>
          <div className="col-12 pt-2">
            <main id="main-content" tabIndex={-1} aria-label="Admin Landing Page">
                <div className="row landing-card-deck">
                    <div className="col-6 p-2">
                            <a role="button" className="d-flex justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid" href={`/admin/appointmentform`} > 
                                <span className='pr-2'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/appointmentSmall.png"} alt="" className='landingIcon'/>
                                </span>
                                Appointments
                            </a>
                    </div>
                    <div className="col-6 p-2">
                            <a role="button" className="d-flex justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid" href={`/admin/vaccinerecords`} > 
                                <span className='pr-2'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/injection.png"} alt="" className='landingIcon'/>
                                </span>
                                Vaccines 
                            </a>
                    </div>
                    <div className="col-6 p-2">
                            <a role="button" className="d-flex justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid" href={`/admin/treatmentrecords`} > 
                                <span className='pr-2'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/treatment.png"} alt="" className='landingIcon'/>
                                </span>
                                Treatments 
                            </a>
                    </div>
                    <div className="col-6 p-2">
                            <a role="button" className="d-flex justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid" href={`/admin/Tests`} > 
                                <span className='pr-2'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/samplesSmall.png"} alt="" className='landingIcon'/>
                                </span>
                                Tests 
                            </a>
                    </div>
                    <div className="col-6 p-2">
                            <a role="button" className="d-flex justify-content-center align-items-center col-12 btn landingBtn  immySubmitButtonSolid" href={`/admin/profilemanagement`}> 
                                <span className='pr-2'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/profileLandingSmall.png"} alt="" className='landingIcon'/>
                                </span>
                                Profile
                            </a>
                    </div>
                    <div className="col-6 p-2">
                            <a role="button" className="d-flex justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid" href={`/admin/FamilyGroup`} > 
                                <span className='pr-2'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/family.png"} alt="" className='landingIcon'/>
                                </span>
                                Family
                            </a>
                    </div>
                    
                </div>
              </main>
          </div>
        </div>
      </div>} */}
      </React.Fragment>
    );
  }
}
