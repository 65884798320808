import React from "react";
import FilterCard, {FilterPart} from "../FilterCard";
import {Sample} from "../../types/Sample";
import {calculateAge} from "../../util/ValidationUtil";
import {sweetalert} from "../../App";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import Overlay from "../Overlay";
import { hideModal, showModalNoOutsideClick, buildRow, getLabel, parseDate } from "../../util/FormatUtil";
import { isEmptyObject } from "jquery";
import InBetweenOverlay from "../InBetweenOverlay";
import Select from "react-select";
import PhoneInput from 'react-phone-number-input';
import DatePicker from 'react-datepicker';
import moment from "moment";
import { Patient } from "../../types/Patient";
import PatientAPI from "../../network/PatientAPI";

interface PatientInfoModalProps{
    submit:(e) => void,
    attachClearStateFunc?:(func) => void
    attachClearStateFuncGuardian?:(func) => void
    states:any
    gender:any
    ethnicity:any
    race:any
    countries:any
    portalPatients?
    fromWalkin?
    patientProfile?
    patients?
    patientOptions?
}

interface PatientInfoModalState{
    showLoading:boolean
    showInBetween:boolean
    patientInfo?
    selectedPatient?
    portalPatients?
}
export class PatientInfoModal extends React.Component<PatientInfoModalProps, PatientInfoModalState>{
    public static ID = "patientinfo";
    constructor(props) {
        super(props);
        this.state ={
            showLoading: false,
            showInBetween: false,
            selectedPatient: null,
            portalPatients: null
        }
        this.assignClearState = this.assignClearState.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount(): void {
        $(`#${PatientInfoModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    handleSave(){
        this.setState({showLoading: true}) 

        let tempDate = this.state.patientInfo.PatientDOB;

        let patientInfoCopy = JSON.parse(JSON.stringify(this.state.patientInfo))
        patientInfoCopy.PatientFirstName = patientInfoCopy.PatientFirstName ? patientInfoCopy.PatientFirstName.trim() : null
        patientInfoCopy.PatientMiddleName = patientInfoCopy.PatientMiddleName ? patientInfoCopy.PatientMiddleName.trim() : null
        patientInfoCopy.PatientLastName = patientInfoCopy.PatientLastName ? patientInfoCopy.PatientLastName.trim() : null
        patientInfoCopy.PatientEmail = patientInfoCopy.PatientEmail ? patientInfoCopy.PatientEmail.trim() : null
        patientInfoCopy.PatientDOB = tempDate ? tempDate : null
        patientInfoCopy['confirmEmail'] = patientInfoCopy['confirmEmail'] ? patientInfoCopy['confirmEmail'].trim() : null
        patientInfoCopy.PatientAddress = patientInfoCopy.PatientAddress ? patientInfoCopy.PatientAddress.trim() : null
        patientInfoCopy.PatientAddress2 = patientInfoCopy.PatientAddress2 ? patientInfoCopy.PatientAddress2.trim() : null
        patientInfoCopy.PatientCity = patientInfoCopy.PatientCity ? patientInfoCopy.PatientCity.trim() : null
        patientInfoCopy.PatientZip = patientInfoCopy.PatientZip ? patientInfoCopy.PatientZip.trim() : null
        patientInfoCopy.PatientCounty = patientInfoCopy.PatientCounty ? patientInfoCopy.PatientCounty.trim() : null
        patientInfoCopy.PatientPhone = patientInfoCopy.PatientPhone ? patientInfoCopy.PatientPhone.trim() : null
        patientInfoCopy.GuardianFirstName = patientInfoCopy.GuardianFirstName && calculateAge(tempDate) < 18 ? patientInfoCopy.GuardianFirstName.trim() : ""
        patientInfoCopy.GuardianLastName = patientInfoCopy.GuardianLastName && calculateAge(tempDate) < 18 ? patientInfoCopy.GuardianLastName.trim() : ""

        if(!this.props.fromWalkin){
            delete patientInfoCopy.PatientAddress2
        }

        this.setState({patientInfo: patientInfoCopy})


        //validate patient info
        if(!PatientInfoModal.validatePatient(patientInfoCopy)){
            this.setState({showLoading: false})
            return
        }

        //pass back to parent
        this.props.submit({patientInfo: patientInfoCopy})

        hideModal(PatientInfoModal.ID)
        this.setState({showLoading: false})
        

    }


    public static validatePatient(s):boolean{
        let patientValidation = {
            PatientFirstName: s.PatientFirstName,
            PatientMiddleName: s.PatientMiddleName,
            PatientLastName: s.PatientLastName,
            PatientDOB: s.PatientDOB,
            PatientEmail: s.PatientEmail,
            ConfirmEmail: s['confirmEmail'],
            PatientGender: s.PatientGenderID,
            PatientAddress: s.PatientAddress,
            PatientAddress2: s.PatientAddress2,
            PatientCity: s.PatientCity,
            PatientZip: s.PatientZip,
            PatientState: s.PatientState,
            PatientCountry: s.PatientCountry,
            PatientCounty: s.PatientCounty,
            PatientPhone: s.PatientPhone,
            PatientEthnicity: s.PatientEthnicityID,
            PatientRace: s.PatientRaceID,
            GuardianFirstName: s.GuardianFirstName,
            GuardianLastName: s.GuardianLastName
        }

        let validator = new Validator<any>()
            .withValidation("PatientFirstName", Validators.requireNotBlankAndLength(50, "Patient First Name"))
        if(s.PatientMiddleName && s.PatientMiddleName.trim().length > 0){
            validator = validator.withValidation("PatientMiddleName", Validators.requireNotBlankAndLength(50, "Patient Middle Name"))
        }
        if(calculateAge(s.PatientDOB) < 18){
            validator = validator.withValidation("GuardianFirstName", Validators.requireNotBlankAndLength(50, "Guardian First Name")).withValidation("GuardianLastName", Validators.requireNotBlankAndLength(50, "Guardian Last Name"))
        }
        validator = validator.withValidation("PatientLastName", Validators.requireNotBlankAndLength(50, "Patient Last Name"))
            .withValidation("PatientDOB", Validators.requireDOB(150, "Patient Date of Birth"))
            .withComposedValidation("PatientEmail", new ValidationEntry(Validators.requireValidEmail("Patient Email")), Validators.requireNotBlankAndLength(100, "Patient Email"), new ValidationEntry<any>((key, attr, obj) =>{
                if (obj.PatientEmail !== s['confirmEmail']) {
                    return {success:false, error:"Patient Email and Confirmation Email do not match"};
                }
                return {success:true, error:null}
            }))
            .withSimpleValidation("PatientGender", Validators.requireNonNullValidator("Patient Gender"))
            .withValidation("PatientAddress", Validators.requireNotBlankAndLength(500, "Patient Address"))
            if(s.PatientAddres2){
                validator = validator.withValidation("PatientAddress2", Validators.requireNotBlankAndLength(500, "Patient Address2"))
            }
            validator = validator.withValidation("PatientCity", Validators.requireNotBlankAndLength(100, "Patient City"))
            .withComposedValidation("PatientZip", new ValidationEntry(Validators.requireNotBlankValidator("Patient Zipcode")), new ValidationEntry(Validators.requireZip("Patient Zipcode")))
            .withSimpleValidation("PatientState", Validators.requireNonNullValidator("Patient State"))
            .withSimpleValidation("PatientCountry", Validators.requireNonNullValidator("Patient Country"))
            .withValidation("PatientCounty", Validators.requireNotBlankAndLength(100, "Patient County"))
            .withComposedValidation("PatientPhone", new ValidationEntry(Validators.requireNonNullValidator("Patient Phone")), new ValidationEntry(Validators.requirePhone("Patient Phone")))
            .withSimpleValidation("PatientEthnicity", Validators.requireNonNullValidator("Patient Ethnicity"))
            .withSimpleValidation("PatientRace", Validators.requireNonNullValidator("Patient Race"))
        let validationResponse = validator.validate(patientValidation);
        if(!validationResponse.success) {
            sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
            return false
        }

        return true;
    }

    clearForWalkin = () => {
        this.setState({
            patientInfo: {
                PatientFirstName: "",
                PatientMiddleName: "",
                PatientLastName: "",
                PatientEmail: "",
                confirmEmail: "",
                PatientGenderID: null,
                PatientEthnicityID: null,
                PatientRaceID: null,
                PatientDOB: "",
                PatientState: null,
                PatientCountry: null,
                PatientAddress: "",
                PatientAddress2: "",
                PatientCity: "",
                PatientZip: "",
                PatientCounty: "",
                PatientPhone: "",
                GuardianFirstName: "",
                GuardianLastName: ""
            }, 
            selectedPatient: null,
            portalPatients: null
        })
    }

    clearFilterState:() => void = null;

    assignClearState(func){
        this.clearFilterState = func;
    }

    clearState = () =>{
        this.clearFilterState();
    }

    componentWillReceiveProps(nextProps: Readonly<PatientInfoModalProps>, nextContext: any): void {
        if (!isEmptyObject(nextProps.patientProfile)) {
            let profile = nextProps.patientProfile;
            this.updateRows(nextProps.patientProfile)
        } else {

        }
    }
    shouldComponentUpdate(nextProps: Readonly<PatientInfoModalProps>, nextState: Readonly<PatientInfoModalState>, nextContext: any): boolean {
        if(this.props.attachClearStateFunc)
            this.props.attachClearStateFunc(this.clearState)
        return true;
    }

    closeInBetween(){
        this.setState({showInBetween: false})
    }

    getQuestions():FilterPart[]{
        let questions:FilterPart[] = [];
        questions = questions.concat(CustomFieldBuilder.getPatientQuestions(this.props.gender, this.props.states, this.props.ethnicity, this.props.race, this.props.countries))
        return questions;
    }

    updateRows(patientProfile){
        // this.setState({selectedPatient: patientProfile.id}) // to disable fields, set selectedPatient
        this.setState({
            patientInfo: {
                UID: patientProfile.uid ? patientProfile.uid : null,
                PatientFirstName: patientProfile.firstName ? patientProfile.firstName : '',
                PatientMiddleName: patientProfile.middleName ? patientProfile.middleName : '',
                PatientLastName: patientProfile.lastName ? patientProfile.lastName : '',
                PatientEmail: patientProfile.email ? patientProfile.email : '',
                confirmEmail: patientProfile.email ? patientProfile.email : '',
                PatientGenderID: patientProfile.genderID ? patientProfile.genderID : null,
                PatientEthnicityID: patientProfile.ethnicityID ? patientProfile.ethnicityID : null,
                PatientRaceID: patientProfile.raceID ? patientProfile.raceID : null, 
                PatientDOB: patientProfile.dateOfBirth ? patientProfile.dateOfBirth : '',
                PatientState: patientProfile.state ? patientProfile.state : null,
                PatientCountry: patientProfile.country && patientProfile.country === 'United States of America' ? 'United States' : patientProfile.country ? patientProfile.country : null,
                PatientAddress: patientProfile.streetAddress ? patientProfile.streetAddress : '',
                PatientAddress2: patientProfile.streetAddress2 ? patientProfile.streetAddress2 : '',
                PatientCity: patientProfile.city ? patientProfile.city : '',
                PatientZip: patientProfile.zipcode ? patientProfile.zipcode : '',
                PatientCounty: patientProfile.county ? patientProfile.county : '',
                PatientPhone: patientProfile.phoneNumber ? patientProfile.phoneNumber : '',
                GuardianFirstName: patientProfile.guardianFirstName ? patientProfile.guardianFirstName : '',
                GuardianLastName: patientProfile.guardianLastName ? patientProfile.guardianLastName : '',
            }
        })
    }

    render() {

        // console.log('PatientInfoModal props', this.props)
        // console.log('PatientInfoModal state', this.state)

            return  (
                <React.Fragment>
                    <Overlay show_loading={this.state.showLoading} zIndex={100005}/>
                    <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100003} />
                    <div className="modal fade form_modal" id={PatientInfoModal.ID} tabIndex={-1} role="dialog"
                        aria-labelledby="result_modal_label"
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0 m-0">
                                    <div className="container-fluid">
                                        <div className={"row"}>
                                            <div className="col-12 pt-2">
                                                <div className="card mt-2">
                                                    <div className="card-header verlag-bold">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <h4>
                                                                    Employee Information
                                                                </h4>
                                                            </div>
                                                            <div className="col-2">
                                                                <button style={{outline: 'none'}} type="button" className="close pr-4"
                                                                        aria-label="Close" onClick={() => hideModal(PatientInfoModal.ID)}>
                                                                    <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className={""}>
                                                            {this.props.patients ? buildRow('Employee',
                                                                    <Select
                                                                        key={'selectedPatient'}
                                                                        id={'selectedPatient'}
                                                                        isSearchable={true}
                                                                        placeholder={"Please Select..."}
                                                                        noOptionsMessage={()=> "No option"}
                                                                        aria-label="Patient Select"
                                                                        value={this.state.patientInfo ? getLabel(this.state.selectedPatient, this.props.patientOptions) : null}
                                                                        onChange={(e)=>{
                                                                            this.setState( (prevState) => ({
                                                                                // patientInfo: {
                                                                                //     ...prevState.patientInfo,
                                                                                //     SelectedPatient: e.value
                                                                                // },
                                                                                selectedPatient: e.value
                                                                            }), () => {
                                                                                this.updateRows(this.props.patients.find((patient) => patient.id === e.value))
                                                                            })
                                                                        }}
                                                                        className={'state_select'}
                                                                        options={this.props.patientOptions}
                                                                    />, 'Employee Select'
                                                                ) : <></>}
                                                            {/* <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'PatientFirstName'}>
                                                                <label htmlFor={'PatientFirstName'} className="col-sm-4 col-form-label">{'First Name'}</label>
                                                                <div className="col-12 col-md-8 p-0 m-0" id={'PatientFirstName'}>
                                                                    <input
                                                                        id={'PatientFirstName'}
                                                                        className={"form-control"}
                                                                        maxLength={50}
                                                                        autoComplete={"off"}
                                                                        type={"search"}
                                                                        name={'PatientFirstName'}
                                                                        disabled={this.state.selectedPatient}
                                                                        onChange={(e) => {
                                                                            this.setState((prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    PatientFirstName: e.target.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        value={this.state.patientInfo?.PatientFirstName}
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            {buildRow('First Name',
                                                                <input 
                                                                    id={'PatientFirstName'}
                                                                    className={"form-control"}
                                                                    maxLength={50}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'PatientFirstName'}
                                                                    aria-label="First Name"
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientFirstName: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientFirstName}
                                                            />, 'Patient First Name'
                                                            )}
                                                            {buildRow('Middle Name',
                                                                <input 
                                                                    id={'PatientMiddleName'}
                                                                    className={"form-control"}
                                                                    maxLength={50}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'PatientMiddleName'}
                                                                    aria-label="Middle Name"
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientMiddleName: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientMiddleName}
                                                            />, 'Patient Middle Name'
                                                            )}
                                                            {buildRow('Last Name',
                                                                <input 
                                                                    id={'PatientLastName'}
                                                                    className={"form-control"}
                                                                    maxLength={50}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'PatientLastName'}
                                                                    aria-label="Patient Last Name"
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientLastName: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientLastName}
                                                            />, 'Patient Last Name'
                                                            )}
                                                            {buildRow('Email to Receive Results',
                                                                <input 
                                                                    id={'PatientEmail'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'Email'}
                                                                    aria-label="Email to Receive Results"
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientEmail: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientEmail}
                                                            />, 'Patient Email'
                                                            )}
                                                            {buildRow('Confirm Email',
                                                                <input 
                                                                    id={'ConfirmPatientEmail'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'ConfirmEmail'}
                                                                    aria-label="Confirm Email"
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                confirmEmail: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.confirmEmail}
                                                            />, 'Confirm Patient Email'
                                                            )}
                                                            {this.state.selectedPatient ? 
                                                                buildRow('Gender',
                                                                <input 
                                                                    id={'PatientGender'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'Gender'}
                                                                    aria-label={'Patient Gender'}
                                                                    disabled={this.state.selectedPatient}
                                                                    value={this.state.patientInfo?.PatientGenderID ? getLabel(this.state.patientInfo.PatientGenderID, this.props.gender).label : null}
                                                                />, 'Patient Gender'
                                                                )
                                                            :
                                                                buildRow('Gender',
                                                                    <Select
                                                                        key={'PatientGenderID'}
                                                                        id={'PatientGender'}
                                                                        isSearchable={true}
                                                                        placeholder={"Please Select..."}
                                                                        noOptionsMessage={()=> "No option"}
                                                                        aria-label="Patient Gender"
                                                                        value={this.state.patientInfo ? getLabel(this.state.patientInfo.PatientGenderID, this.props.gender) : null}
                                                                        onChange={(e)=>{
                                                                            this.setState( (prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    PatientGenderID: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        className={'state_select'}
                                                                        options={this.props.gender}
                                                                    />, 'Patient Gender'
                                                                )
                                                            }
                                                            {this.state.selectedPatient ? 
                                                                buildRow('Ethnicity',
                                                                <input 
                                                                    id={'PatientEthnicity'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'Ethnicity'}
                                                                    aria-label="Patient Ethnicity"
                                                                    disabled={this.state.selectedPatient}
                                                                    value={this.state.patientInfo?.PatientEthnicityID ? getLabel(this.state.patientInfo.PatientEthnicityID, this.props.ethnicity).label : null}
                                                                />, 'Patient Ethnicity'
                                                                )
                                                            :
                                                                buildRow('Ethnicity',
                                                                    <Select
                                                                        key={'PatientEthnicityID'}
                                                                        id={'PatientEthnicityID'}
                                                                        isSearchable={true}
                                                                        placeholder={"Please Select..."}
                                                                        noOptionsMessage={()=> "No option"}
                                                                        aria-label="Patient Ethnicity"
                                                                        value={this.state.patientInfo ? getLabel(this.state.patientInfo.PatientEthnicityID, this.props.ethnicity) : null}
                                                                        onChange={(e)=>{
                                                                            this.setState( (prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    PatientEthnicityID: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        className={'state_select'}
                                                                        options={this.props.ethnicity}
                                                                    />, 'Patient Ethnicity'
                                                                )
                                                            }
                                                            {this.state.selectedPatient ?
                                                                buildRow('Race',
                                                                    <input 
                                                                        id={'PatientRaceID'}
                                                                        className={"form-control"}
                                                                        maxLength={100}
                                                                        autoComplete={"off"}
                                                                        type={"search"}
                                                                        name={'Race'}
                                                                        aria-label="Patient Race"
                                                                        disabled={this.state.selectedPatient}
                                                                        value={this.state.patientInfo?.PatientRaceID ? getLabel(this.state.patientInfo.PatientRaceID, this.props.race).label : null}
                                                                    />, 'Patient Race'
                                                                    )
                                                            :
                                                                buildRow('Race',
                                                                    <Select
                                                                        key={'PatientRaceID'}
                                                                        id={'PatientRaceID'}
                                                                        isSearchable={true}
                                                                        placeholder={"Please Select..."}
                                                                        noOptionsMessage={()=> "No option"}
                                                                        aria-label="Patient Race"
                                                                        value={this.state.patientInfo ? getLabel(this.state.patientInfo.PatientRaceID, this.props.race) : null}
                                                                        onChange={(e)=>{
                                                                            this.setState( (prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    PatientRaceID: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        className={'state_select'}
                                                                        options={this.props.race}
                                                                    />, 'Patient Race'
                                                                )
                                                            }
                                                            {buildRow("Date of Birth",
                                                                <input 
                                                                    id="PatientDateOfBirth"
                                                                    min={'1000-01-01'}
                                                                    max={'9999-12-31'}
                                                                    disabled={this.state.selectedPatient}
                                                                    className={"form-control"}
                                                                    autoComplete={"off"}
                                                                    type={"date"}
                                                                    name={"DOB"}
                                                                    aria-label="Date of Birth"
                                                                    value={this.state.patientInfo && this.state.patientInfo.PatientDOB ? new Date(this.state.patientInfo.PatientDOB).toISOString().split('T')[0] : ''}
                                                                    onChange={(e) => {
                                                                        if(Date.parse(e.target.value)){
                                                                            this.setState((prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    PatientDOB: new Date(e.target.value)
                                                                                }
                                                                            }));
                                                                        }
                                                                    }}
                                                                />, "Patient Date of Birth"
                                                            )}
                                                            {this.state.selectedPatient ? 
                                                                buildRow('State',
                                                                    <input 
                                                                        id={'PatientState'}
                                                                        className={"form-control"}
                                                                        maxLength={100}
                                                                        autoComplete={"off"}
                                                                        type={"search"}
                                                                        name={'State'}
                                                                        aria-label="Patient State of Residence"
                                                                        disabled={this.state.selectedPatient}
                                                                        value={this.state.patientInfo?.PatientState ? getLabel(this.state.patientInfo.PatientState, this.props.states).label : null}
                                                                    />, 'Patient State'
                                                                )
                                                            :
                                                                buildRow('State',
                                                                    <Select
                                                                        key={'PatientState'}
                                                                        id={'PatientState'}
                                                                        isSearchable={true}
                                                                        placeholder={"Please Select..."}
                                                                        noOptionsMessage={()=> "No option"}
                                                                        aria-label="State of Residence"
                                                                        value={this.state.patientInfo ? getLabel(this.state.patientInfo.PatientState, this.props.states) : null}
                                                                        onChange={(e)=>{
                                                                            this.setState( (prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    PatientState: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        className={'state_select'}
                                                                        options={this.props.states}
                                                                    />, 'Patient State'
                                                                )
                                                            }
                                                            {this.state.selectedPatient ? 
                                                                buildRow('Country',
                                                                    <input 
                                                                        id={'PatientCountry'}
                                                                        className={"form-control"}
                                                                        maxLength={100}
                                                                        autoComplete={"off"}
                                                                        type={"search"}
                                                                        name={'Country'}
                                                                        aria-label="Country of Residence"
                                                                        disabled={this.state.selectedPatient}
                                                                        value={this.state.patientInfo?.PatientCountry ? getLabel(this.state.patientInfo.PatientCountry, this.props.countries).label : null}
                                                                    />, 'Patient Country'
                                                                )
                                                            :
                                                                buildRow('Country',
                                                                    <Select
                                                                        key={'PatientCountry'}
                                                                        id={'PatientCountry'}
                                                                        isSearchable={true}
                                                                        placeholder={"Please Select..."}
                                                                        noOptionsMessage={()=> "No option"}
                                                                        aria-label="Country of Residence"
                                                                        value={this.state.patientInfo ? getLabel(this.state.patientInfo.PatientCountry, this.props.countries) : null}
                                                                        onChange={(e)=>{
                                                                            this.setState( (prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    PatientCountry: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        className={'state_select'}
                                                                        options={this.props.countries}
                                                                    />, 'Patient Country'
                                                                )
                                                            }
                                                            {buildRow('Street Address',
                                                                <input 
                                                                    id={'PatientStreetAddress'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'PatientAddress'}
                                                                    aria-label={"Patient Street Address"}
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientAddress: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientAddress}
                                                            />, 'Patient Street Address'
                                                            )}
                                                            {buildRow('Street Address cont.',
                                                                <input 
                                                                    id={'PatientStreetAddresscont'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'PatientAddres2'}
                                                                    aria-label={"Patient Street Address Continued"}
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientAddress2: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientAddress2}
                                                            />, 'Patient Street Address cont'
                                                            )}
                                                            
                                                            {buildRow('City',
                                                                <input 
                                                                    id={'PatientCity'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'PatientCity'}
                                                                    aria-label={"Patient City of Residence"}
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientCity: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientCity}
                                                            />, 'Patient City'
                                                            )}
                                                            
                                                            {buildRow('Zip',
                                                                <input 
                                                                    id={'PatientZipcode'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'PatientZipcode'}
                                                                    aria-label={"Patient Zipcode of Residence"}
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientZip: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientZip}
                                                            />, 'Patient Zipcode'
                                                            )}
                                                            
                                                            {buildRow('County',
                                                                <input 
                                                                    id={'PatientCounty'}
                                                                    className={"form-control"}
                                                                    maxLength={100}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={'PatientCounty'}
                                                                    aria-label={"Patient County of Residence"}
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e)=>{
                                                                        this.setState( (prevState) => ({
                                                                            patientInfo: {
                                                                                ...prevState.patientInfo,
                                                                                PatientCounty: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.patientInfo?.PatientCounty}
                                                            />, 'Patient County'
                                                            )}
                                                            
                                                            {buildRow('Cell Phone',
                                                                <PhoneInput
                                                                    id={'PatientPhone'}
                                                                    placeholder={"Enter phone number"}
                                                                    disabled={this.state.selectedPatient}
                                                                    onChange={(e) => 
                                                                        this.setState( (prevState) => ({
                                                                        patientInfo: {
                                                                            ...prevState.patientInfo,
                                                                            PatientPhone: e
                                                                        }
                                                                    }))}
                                                                    defaultCountry="US"
                                                                    value={this.state.patientInfo?.PatientPhone}/>, "Patient Phone"
                                                            )}

                                                            <div className={this.state.patientInfo && calculateAge(this.state.patientInfo.PatientDOB) < 18 ? "" : "d-none"}>
                                                                {buildRow("Guardian First Name",
                                                                    <input className={"form-control"}
                                                                        id="GuardianFirstName"
                                                                        maxLength={50}
                                                                        autoComplete={"off"}
                                                                        type={"search"}
                                                                        name={"Guardian First Name"}
                                                                        aria-label={"Guardian First Name"}
                                                                        disabled={this.state.selectedPatient}
                                                                        onChange={(e) => {
                                                                            this.setState((prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    GuardianFirstName: e.target.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        value={this.state.patientInfo?.GuardianFirstName}
                                                                    />
                                                                    , 'Guardian First Name'
                                                                )}

                                                                {buildRow("Guardian Last Name",
                                                                    <input className={"form-control"}
                                                                        id="GuardianLastName"
                                                                        maxLength={50}
                                                                        autoComplete={"off"}
                                                                        type={"search"}
                                                                        name={"Guardian Last Name"}
                                                                        aria-label={"Guardian Last Name"}
                                                                        disabled={this.state.selectedPatient}
                                                                        onChange={(e) => {
                                                                            this.setState((prevState) => ({
                                                                                patientInfo: {
                                                                                    ...prevState.patientInfo,
                                                                                    GuardianLastName: e.target.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        value={this.state.patientInfo?.GuardianLastName}
                                                                    />
                                                                    , 'Guardian Last Name'
                                                                )}
                                                                <div className="small">*Guardian info required for patients under the age of 18</div>
                                                            </div>
                                                        </div>

                                                        {/* <div className={this.props.fromWalkin === true ? "d-none" : ""}>
                                                            <FilterCard passClearStateFunc={this.assignClearState} fields={this.getQuestions()} filterChanged={(e)=>this.setState({patientInfo: e})}/>
                                                            <div className="small">*Guardian info required for patients under the age of 18</div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                            <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}>Save</button>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

}