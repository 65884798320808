import {formatPhone, parseDate} from "../../util/FormatUtil";
import React from "react";
import {TestReport, TestReportAddtlInfo} from "../../types/TestReport";
import { Chart as ChartJS, CategoryScale, LinearScale, LogarithmicScale, BarElement, Title, Tooltip, Legend } from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getBarChart } from "./helpers/barCharts";
import PathogenDescriptions from "./childComponents/PathogenDescriptions";
import { checkThreshold, dateFormatter, getAbbreviations, getAllergies, getBarBackgroundColor, getRxRecommendation } from "./helpers/utils";


ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin,
    ChartDataLabels
  );



interface PeriodontalProps{
    result?:TestReportAddtlInfo
    test
    services?
    allResults?
    branding?
}
interface PeriodontalState{
    
}

export default class Periodontal extends React.Component<PeriodontalProps, PeriodontalState>{

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(): void {
        document.body.style.backgroundImage = ``;
        document.body.style.backgroundColor = '#FFFFFF'
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        
        // console.log('PeridontalReport props', this.props)

        //allergy and rx recommendation section
        let tests = this.props && this.props.result && this.props.result.Tests ? this.props.result.Tests : null;
        let testsAndResultsMap = this.props && this.props.result && this.props.result.TestsAndResultsMap ? this.props.result.TestsAndResultsMap : null;
        let customData = this.props && this.props.result && this.props.result.CustomFieldData ? this.props.result.CustomFieldData : null;
        let rxRecommendation;
        let testMeetsOrExceedsArray = checkThreshold(tests, testsAndResultsMap);
        if(testMeetsOrExceedsArray && testMeetsOrExceedsArray.length > 0){
            let allergies = getAllergies(customData);
            rxRecommendation = getRxRecommendation(testMeetsOrExceedsArray, allergies);
        }

        // get current test date, current test result id's, and current test result values
        let formattedCurrentTestDate = this.props.result && this.props.result.TestResultDate ? dateFormatter(new Date(this.props.result.TestResultDate)) : null;

        var currentTestResultValues = this.props.result.TestsAndResultsMap.map((d) => { return Number(d.ResultValue) });

        //get first and second prior test dates and info
        let firstPriorTestDate;
        let secondPriorTestDate;
        if(this.props.result && this.props.result.otherSamples && this.props.result.otherSamples.length > 0){
            firstPriorTestDate = this.props.result.otherSamples[0].TestResultDate ? dateFormatter(new Date(this.props.result.otherSamples[0].TestResultDate)) : null;

            var firstPriorTestResultValues = this.props.result.otherSamples[0] ? JSON.parse(this.props.result.otherSamples[0].TestsAndResultsMap).map((d) => { return Number(d.ResultValue) }) : null;

            secondPriorTestDate = this.props.result.otherSamples[1] && this.props.result.otherSamples[1].TestResultDate ? dateFormatter(new Date(this.props.result.otherSamples[1].TestResultDate)) : null;

            var secondPriorTestResultValues = this.props.result.otherSamples[1] ? JSON.parse(this.props.result.otherSamples[1].TestsAndResultsMap).map((d) => { return Number(d.ResultValue) }) : null;

        }


        const getDatasets = () => {
            let currentProps = this.props;
            let firstPriorProps = this.props.result.otherSamples && this.props.result.otherSamples.length > 0 ? this.props.result.otherSamples[0] : null;
            let secondPriorProps = this.props.result.otherSamples && this.props.result.otherSamples.length > 0 ? this.props.result.otherSamples[1] : null;
            let allResults = this.props.allResults;
            if(this.props.result.otherSamples && this.props.result.otherSamples.length > 0){
                if(this.props.result.otherSamples.length === 2){
                    return [
                        {
                            label: secondPriorTestDate,
                            data: secondPriorTestResultValues,
                            backgroundColor: function(context){ return getBarBackgroundColor(context, secondPriorProps, allResults)},
                            borderRadius: 10,
                            barPercentage: .5,
                            date: secondPriorTestDate
                        },
                        {
                            label: firstPriorTestDate,
                            data: firstPriorTestResultValues,
                            backgroundColor: function(context){ return getBarBackgroundColor(context, firstPriorProps, allResults)},
                            borderRadius: 10,
                            barPercentage: .5,
                            date: firstPriorTestDate
                        },
                        {
                            label: formattedCurrentTestDate,
                            data: currentTestResultValues,
                            barPercentage: .85,
                            date: formattedCurrentTestDate,
                            backgroundColor: function(context){ return getBarBackgroundColor(context, currentProps, allResults)},
                            borderRadius: 10,
                        },   
                    ]
                }
                else if(this.props.result.otherSamples.length === 1){
                    return [
                        {
                            label: firstPriorTestDate,
                            data: firstPriorTestResultValues,
                            backgroundColor: function(context){ return getBarBackgroundColor(context, firstPriorProps, allResults)},
                            borderRadius: 10,
                            barPercentage: .5,
                            date: firstPriorTestDate,
                        },
                        {
                            label: formattedCurrentTestDate,
                            data: currentTestResultValues,
                            barPercentage: .85,
                            date: formattedCurrentTestDate,
                            backgroundColor: function(context){return getBarBackgroundColor(context, currentProps, allResults)},
                            borderRadius: 10,
                        },   
                    ]
                }
            }
            else{
                return [{
                    label: formattedCurrentTestDate,
                    data: currentTestResultValues,
                    barPercentage: .85,
                    date: formattedCurrentTestDate,
                    backgroundColor: function(context){ return getBarBackgroundColor(context, currentProps, allResults)},
                    borderRadius: 10,
                }]
            }
        }       

        //CURRENT 
        let labels = this.props.result && this.props.result.Tests ? getAbbreviations(this.props.result.Tests) : [];
        let oralBacteriaProfile =  {
            labels,
            datasets: getDatasets() as any           
        }

        return(
            <div className="col-12 border printPerioWrapper">
                <div className="col-12 text-center mb-3">
                        <img src={this.props.branding.ProductLogoURL}  height={90} className="d-inline-block align-top nav-href pr-sm-2 pr-0" alt="Logo"/>
                </div>

                {/* print only  */}
                <div className="d-none d-print-flex pt-3 pb-3" style={{border: '3px solid', borderColor: this.props.branding.ProductButtonColorHexValue, borderRadius: '5px'}}>
                    <div className="col-4 p-2 " style={{borderRight: '1px solid black'}}>
                        <div className="row">
                            <div className="col-5 text-right">
                                <div>Date of Birth:</div>
                                <div>Gender:</div>
                                <div>Confirmation:</div>
                                <div>Patient Name:</div>
                            </div>
                            <div className="col-7 text-left font-weight-bold pl-0">
                                <div>{parseDate(this.props.result.PatientDOB)}</div>
                                <div>{this.props.result.PatientGender}</div>
                                <div>{this.props.result.ReqNum}</div>
                                <div>{this.props.result.PatientFirstName + ' ' + this.props.result.PatientLastName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 p-2" style={{borderRight: '1px solid black'}}>
                        <div className="row">
                            <div className="col-3 text-right">
                                <div>Provider:</div>
                                <div>Phone:</div>
                                <div>Email:</div>
                                <div>Address:</div>
                            </div>
                            <div className="col-9 text-left font-weight-bold pl-0">
                                <div>{this.props.result.ProviderFirstName}{" "}{this.props.result.ProviderLastName}</div>
                                <div>{formatPhone(this.props.result.ReportPhone)}</div>
                                <div>{this.props.result.ReportHelpEmail}</div>
                                <div>{this.props.result.ReportAddress}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 p-2">
                        <div className="row">
                            <div className="col-5 text-right">
                                <p>
                                    <div>Specimen ID:</div>
                                    <div>Internal ID:</div>
                                    <div>Source:</div>
                                    <div>Service:</div>
                                </p>
                            </div>
                            <div className="col-7 text-left font-weight-bold pl-0">
                                <p className="mb-0">
                                    <div>{this.props.result.SpecimenID}</div>
                                    <div>{this.props.result.InternalSpecimenID}</div>
                                    <div>{this.props.result.SpecimenSource}</div>
                                    <div>{this.props.result.Service.Name}</div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

                {/* screen only  */}
                <div className="d-print-none d-lg-flex pt-lg-3 pb-lg-3" style={{border: '3px solid #82d2e6', borderRadius: '5px'}}>
                    <div className="col-12 col-lg-4 p-2" style={window.innerWidth < 992 ? {borderBottom: '1px solid black'
} : {borderRight: '1px solid black'}}>
                        <div className="row">
                            <div className="col-5 text-lg-right">
                                <div>Confirmation:</div>
                                <div>Date of Birth:</div>
                                <div>Gender:</div>
                                <div>Patient Name:</div>
                            </div>
                            <div className="col-7 text-right text-lg-left font-weight-bold pl-0">
                                <div>{this.props.result.ReqNum}</div>
                                <div>{parseDate(this.props.result.PatientDOB)}</div>
                                <div>{this.props.result.PatientGender}</div>
                                <div>{this.props.result.PatientFirstName + ' ' + this.props.result.PatientLastName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2" style={window.innerWidth < 992 ? {borderBottom: '1px solid black'
} : {borderRight: '1px solid black'}}>
                        <div className="row">
                            <div className="col-3 text-lg-right">
                                <div>Provider:</div>
                                <div>Phone:</div>
                                <div>Email:</div>
                                <div>Address:</div>
                            </div>
                            <div className="col-9 text-right text-lg-left font-weight-bold pl-0">
                                <div>{this.props.result.ProviderFirstName}{" "}{this.props.result.ProviderLastName}</div>
                                <div>{formatPhone(this.props.result.ReportPhone)}</div>
                                <div>{this.props.result.ReportHelpEmail}</div>
                                <div>{this.props.result.ReportAddress}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2">
                        <div className="row">
                            <div className="col-5 text-lg-right">
                                <p>
                                    <div>Specimen ID:</div>
                                    <div>Internal ID:</div>
                                    <div>Source:</div>
                                    <div>Service:</div>
                                </p>
                            </div>
                            <div className="col-7 text-right text-lg-left font-weight-bold pl-0">
                                <p className="mb-0">
                                    <div>{this.props.result.SpecimenID}</div>
                                    <div>{this.props.result.InternalSpecimenID}</div>
                                    <div>{this.props.result.SpecimenSource}</div>
                                    <div>{this.props.result.Service.Name}</div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

                <div className="row m-0">
                    <div className="col-12 mt-2 p-0 text-center font-weight-bold">
                        Thank you for your confidence and trust in OraPath for your salivary analysis and high risk pathogen report.
                    </div>
                </div>
                <div className="row text-center p-2 my-3" style={{border: '1px solid lightgray', borderRadius: '0.25rem', marginLeft: '0.15rem', marginRight: '0.15rem'}}>
                    <p>
                        <h5>What is OraPath designed to do?</h5>
                        Orapath is designed to identify five high-risk pathogens associated with the development of Gingivitis, Periodontitis, Periimplantitis and systemic inflammation. Elevated numbers of these specific bacteria are linked to bone loss, tooth loss and implant loss as well as serious health consequences when not treated. Orapath provides critical information and recommendations that when used in conjunction with antibiotic prescription and therapies provided by your dentist is associated with reducing both oral and systemic risks. 
                    </p>
                </div>

   
                <div className="card p-2 centerChart">
                    <div className="row m-0 p-0">

                        {/* print only  */}
                        <div className="d-none d-print-block col-2 text-center">
                            <div className="card mb-1">
                                <div className="row mb-2">
                                    <div className="col-12">
                                        RESULTS FOR  
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12 font-weight-bold">
                                            {formattedCurrentTestDate}
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-1">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-12" style={{fontSize: '0.7rem'}}>
                                            Relative Risk Categories
                                        </div>
                                    </div>
                                </div>
                                <div className="row pl-4 pr-1 pt-3">
                                    <div className="col-2" style={{borderRadius: '50%', backgroundImage:'linear-gradient(#33cc33, #009933)'}}>
                                    </div>
                                    <div className="col-8 text-center font-weight-bold" style={{fontSize: '0.7rem'}}>
                                        Low
                                    </div>
                                </div>
                                <div className="row pl-4 pr-1 pt-3">
                                    <div className="col-2" style={{borderRadius: '50%', backgroundImage:'linear-gradient(#ffcc00, #ff9900)'}}>
                                    </div>
                                    <div className="col-8 text-center font-weight-bold pr-1" style={{fontSize: '0.7rem'}}>
                                        Moderate
                                    </div>
                                </div>
                                <div className="row pl-4 pr-1 pt-3 pb-3">
                                    <div className="col-2" style={{borderRadius: '50%', backgroundImage:'linear-gradient(#ff0000, #cc0000)'}}>
                                    </div>
                                    <div className="col-8 text-center font-weight-bold" style={{fontSize: '0.7rem'}}>
                                        High
                                    </div>
                                </div>
                            </div>
                            <div className="card pt-2">
                                <div className="row">
                                    <div className="col-12">
                                        <p style={{fontSize: '0.75rem'}}>Units = Log Genome Copies / ml</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p style={{fontSize: '0.75rem'}}>*No bar present represents not detected</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  */}

                        {/* screen only  */}
                        <div className="d-print-none col-12 col-lg-2 text-center">
                            <div className="card mb-1">
                                <div className="row mb-2">
                                    <div className="col-12">
                                        RESULTS FOR  
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12 font-weight-bold">
                                            {formattedCurrentTestDate}
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-1">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-12" style={window.innerWidth > 991 && window.innerWidth < 1200 ? {fontSize: '0.7rem'}: {}}>
                                            Relative Risk Categories
                                        </div>
                                    </div>
                                </div>
                                <div className="row pl-4 pr-1 pt-3">
                                    <div className="col-2" style={window.innerWidth < 992 ? {backgroundImage:'linear-gradient(#33cc33, #009933)'} : {borderRadius: '50%', backgroundImage:'linear-gradient(#33cc33, #009933)'}}>
                                    </div>
                                    <div className="col-10 col-lg-8 col-xl-9 text-right text-lg-center font-weight-bold" style={window.innerWidth > 991 && window.innerWidth < 1200 ? {fontSize: '0.7rem'} : {}}>
                                        Low
                                    </div>
                                </div>
                                <div className="row pl-4 pr-1 pt-3">
                                    <div className="col-2" style={window.innerWidth < 992 ? {backgroundImage:'linear-gradient(#ffcc00, #ff9900)'} : {borderRadius: '50%', backgroundImage:'linear-gradient(#ffcc00, #ff9900)'}}>
                                    </div>
                                    <div className="col-10 col-lg-8 col-xl-9 text-right text-lg-center font-weight-bold pr-lg-1 pr-xl-2" style={window.innerWidth > 991 && window.innerWidth < 1200 ? {fontSize: '0.7rem'} : {}}>
                                        Moderate
                                    </div>
                                </div>
                                <div className="row pl-4 pr-1 pt-3 pb-3">
                                    <div className="col-2" style={window.innerWidth < 992 ? {backgroundImage:'linear-gradient(#ff0000, #cc0000)'} : {borderRadius: '50%', backgroundImage:'linear-gradient(#ff0000, #cc0000)'}}>
                                    </div>
                                    <div className="col-10 col-lg-8 col-xl-9 text-right text-lg-center font-weight-bold" style={window.innerWidth > 991 && window.innerWidth < 1200? {fontSize: '0.7rem'} : {}}>
                                        High
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-md-block card pt-2">
                                <div className="row">
                                    <div className="col-12">
                                        <p style={{fontSize: '0.75rem'}}>Units = Log Genome Copies / ml</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p style={{fontSize: '0.75rem'}}>*No bar present represents not detected</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        {/*  */}

                        {/* screen only  */}
                        {/* <div className="d-print-none col-1 d-lg-none"></div> */}
                        {/*  */}

                        <div className="d-none d-md-block d-print-block col-12 col-lg-9 text-center">
                            {getBarChart(oralBacteriaProfile, 'Oral Bacteria Profile', this.props.result.Tests, this.props.branding.ProductButtonColorHexValue)}
                        </div>

                        
                    </div>
                </div>

                <div className={"row text-center p-2 my-3"} style={{border: '1px solid lightgray', borderRadius: '0.25rem', marginLeft: '0.15rem', marginRight: '0.15rem'}}>
                    <div className="col-12">
                        <div className="pb-2"><b>Prescription recommendation(s):</b> {rxRecommendation ? rxRecommendation : 'None at this time'}</div>
                        <div className="small">
                            <i>These are general recommendations for antibiotic prescription which must be prescribed along with scaling and root planing to achieve successful outcomes. Antibiotics are not stand alone treatments for periodontal disease. It is necessary to do a thorough medication review, antibiotic allergy and interaction check before prescribing any antibiotics.</i>  
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column col-sm-12 p-0" id="perioResultWrapper">
                    <div className="col-12 pl-0">
                        <div className="col-12 text-center ml-0 mt-2 mb-2 mr-2 p-0">
                            <h4 className="my-5" style={{fontSize: '2rem'}}>Pathogen Description</h4>
                            {this.props.result && this.props.result.Tests && this.props.result.Tests.length > 0 && this.props.result.Tests.map((t, index) => (
                                <PathogenDescriptions test={t} currentResultValue={currentTestResultValues[index]} currentResultID={this.props.result.TestsAndResultsMap[index].ResultID} allResults={this.props.allResults} />
                            ))}
                        </div>
                    </div>
                    <div className="row" style={{fontSize: '0.8rem'}}>
                        <div className="col-9" style={{borderRight: '1px solid gray'}}>
                            <p className="ml-3">
                                <h5>What is Periodontal disease?</h5>
                                Periodontal disease is a serious gum infection that damages the soft and hard tissue and, without treatment, the infection leads to loose teeth or even tooth loss. More concerning is the threat periodontal disease poses to systemic health.  Periodontal disease is not only correlated to many systemic whole body conditions, but also found to be causative.  
                            </p>
                            <p className="ml-3">
                                <h5>Why should treatment be started early?</h5>
                                Treating periodontal disease early and aggressively can prevent additional bone loss and eventual tooth loss, as well as a host of other problems affecting multiple organ systems. The hallmark sign and symptom of both gingivitis and periodontitis is inflammation. We now understand the critical role inflammation in the mouth has on the development and potentiation of diseases affecting your heart, lungs, brain, and even affecting babies in utero. Gum disease is associated with strokes, heart disease, Alzheimer's, and diabetes--all severe, often fatal, and all require a significant financial and time investment, which is all too often all for naught.  
                            </p>
                            <p className="ml-3">
                                <h5>How do OraPath salivary diagnostics help my patients?</h5>
                                <div>
                                    Salivary testing reveals your patient's unique pathogen profile which contributes to their disease process.  Understanding the results of the test, making appropriate treatment recommendations by following an established therapeutic protocol and utilizing antibiotics when warranted yields positive treatment outcomes to restore your patient's oral health. These results can also provide insight into the viability and long-term success of oral surgical procedures such as implant placement, gingival and connective tissue grafting and tooth extractions when used as part of a pre-treatment protocol. Lowering your patient's bacteria (or viral) load before surgery, minimizes risk of post-surgical complications. The OraPath report will illustrate levels of five different oral pathogens known to be moderate and high risk for periodontal disease and the microbial challenge posed to the patient. If any of these pathogens are over a certain threshold, a systemic antibiotic will be recommended to adjunct periodontal treatment. Additional tests, such as the 1,5-Ag test, can provide valuable information about the patient's risk of systemic inflammatory issues. If these additional tests yield positive results, the patient can be advised to see his/her medical doctor for further evaluation. 
                                </div>
                            </p>
                            <p className="ml-3">
                                <h5>Why is this important?</h5>
                                <div>
                                    Periodontal disease is a source of inflammation that can be treated to help with oral and systemic health. If a patient needs antibiotics in conjunction with periodontal therapy, the OraPath report can provide this information. If a patient has other inflammatory markers that need to be investigated by a physician, the OraPath report provides this information.
                                </div>
                            </p>
                        </div>
                        <div className="col-3 pl-1">
                            <div className="row">
                                <div className="col-12">
                                    <h5>Oral Risks:</h5>
                                    <ul className="pl-1" style={{listStyleType: 'none'}}>
                                        <li>Gingivitis</li>
                                        <li>Gingival attachment loss</li>
                                        <li>Periodontitis</li>
                                        <li>Severe bone loss</li>
                                        <li>Implant failure</li>
                                        <li>Tooth Loss</li>
                                        <li>Oral Cancer</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h5>Systemic Risks:</h5>
                                    <ul className="pl-1" style={{listStyleType: 'none'}}>
                                        <li>Chronic inflammation</li>
                                        <li>Diabetes</li>
                                        <li>IBD</li>
                                        <li>UTI</li>
                                        <li>Respiratory disease</li>
                                        <li>Appendicitis</li>
                                        <li>Arthritis</li>
                                        <li>Cardiovascular disease</li>
                                        <li>Atherosclerosis</li>
                                        <li>Septicemia</li>
                                        <li>Rheumatoid arthritis</li>
                                        <li>Adverse pregnancy outcomes</li>
                                        <li>Alzheimer's disease/dementia</li>
                                        <li>Osteomyelitis</li>
                                        <li>Brain abcesses</li>
                                        <li>Oral Cancer</li>
                                        <li>Colorectal cancer</li>
                                        <li>Aneurysm</li>
                                        <li>Stroke</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="font-italic my-2 px-3" style={{fontSize: '0.6rem'}}>
                        This real-time quantitative polymerase chain reaction (qPCR) test was developed and performed by IMMYLabs, 133 Ed Noble Pkwy, Norman, OK 73072 CLIA# 37D2236199 and COLA# 32679. The performance characteristics of this laboratory developed test (LDT) were established and determined by IMMYLabs pursuant to Clinical Laboratory Improvement Amendments of 1988 (CLIA) 42 U.S.C. § 263a requirements. Results are not intended to diagnose or treat disease and should not be used as the sole basis for patient management decisions and must be combined with clinical observations and patient history. The U.S. Food and Drug Administration has not approved or cleared this test; The FDA has determined that such clearance or approval is not necessary. Lab Director: Jeff McCormack, PhD, HCLD (ABB).
                    </div>
                </div>
                <div className={"d-none d-print-block pageNumberOne"}>Page 1 of 2</div>
                <div className={"d-none d-print-block pageNumberTwo"}>Page 2 of 2</div>
            </div>
        )
    }
}