import React from "react";
import Select from "react-select";
import ReactQuill from "react-quill";
import {
    buildRow,
    getBoolSelectVal,
    hideModal,
    getLabel,
    parseDate,
    showModalNoOutsideClick,
    getDateOrNull
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import Validator, { ValidationEntry } from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {isEmptyObject} from "jquery";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import GetField from "../../customfields/GetField";
import TreatmentRecordType from "../../types/TreatmentRecordType";
import { AiFillEye, AiOutlineDown, AiOutlineUp, AiOutlineCheckCircle, AiOutlineClose, } from "react-icons/ai";
import CustomInput from "../form/CustomInput";
import CustomTextArea from "../form/CustomTextArea";
import CustomSelect from "../form/CustomSelect";
import CustomDate from '../form/CustomDate';
import CustomPhone from "../form/CustomPhone";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { InsuranceImageModal } from "./InsuranceImageModal";
import { SampleInsuranceCardModal } from "./SamplesInsuranceCardModal";
import { InsuranceCardUploadModal } from "./InsuranceCardUploadModal";
import TreatmentRecordsAPI from "../../network/TreatmentRecordsAPI";
import Overlay from "../Overlay";
import InBetweenOverlay from "../InBetweenOverlay";
import SystemAPI from "../../network/SystemAPI";

interface TreatmentRecordsState {
    selectedTreatment
    customFieldData?
    patientArrowDown?
    apptArrowDown?
    treatArrowDown?
    addArrowDown?
    insuranceArrowDown?
    imageSaved: boolean;
    showInBetween: boolean;
    captureClicked: boolean;
    imageCached: boolean;
    showLoadingForViewInsuranceCards: boolean;
    frontInsuranceCardImage: string;
    backInsuranceCardImage: string;
    frontCardImgFile: File;
    backCardImgFile: File;
    showLoading: boolean
    showLoadingForPhysicianReview: boolean
    ModalShowInsurance
}

interface TreatmentRecordsModalProps{
    selectedTreatment:TreatmentRecordType
    onSubmit?:(record:TreatmentRecordType, insuranceInformation, insuranceCards) => void
    pageCustomQuestions?
    ethnicity?
    race?
    gender?
    states?
    countries?
    treatments
    onClose?
    facilities
}

const emptyRecordState = {
    ID: null,
    Treatment: null,
    LotNumber: null,
    InjectionSite: null,
    NurseFirstName: null,
    RecordDate: null,
    PatientFirstName: null,
    PatientMiddleName: null,
    PatientLastName: null,
    PatientAddress: null,
    PatientZip: null,
    PatientCity: null,
    PatientState: null,
    PatientyCounty: null,
    PatientCountry: null,
    PatientPhone: null,
    PatientEmail: null,
    NurseLastName: null,
    NurseLicenseNum: null,
    PatientID: null,
    ReqNum: null,
    LastUpdatedBy: null,
    LastUpdatedDate: null,
    CreatedBy: null,
    CreatedData: null
  }

export class TreatmentRecordsModal extends React.Component<TreatmentRecordsModalProps, TreatmentRecordsState> {
    public static ID = "TreatmentRecordsModal"

    constructor(props) {
        super(props);
        this.state = {
            selectedTreatment: emptyRecordState,
            imageSaved: false,
            showInBetween: false,
            captureClicked: false,
            imageCached: false,
            showLoadingForViewInsuranceCards: false,
            frontInsuranceCardImage: "",
            backInsuranceCardImage: "",
            frontCardImgFile: null,
            backCardImgFile: null,
            showLoading: false,
            showLoadingForPhysicianReview: false,
            ModalShowInsurance: 0
        }
        this.clearFields = this.clearFields.bind(this)
    }

    componentDidMount() {
      $(`#${TreatmentRecordsModal.ID}`).on('shown.bs.modal', function() {
        $(document).off('focusin.modal');
    });
        if(!isEmptyObject(this.props.selectedTreatment)){
            let record = this.props.selectedTreatment;
            //console.log(record, "Modal Data")
            this.setState({
                selectedTreatment: {
                    ...record,
                    countryLabel: record && record.PatientCountry && record.PatientCountry === 'United States of America' ? getLabel('United States', this.props.countries) : record && record.PatientCountry ? getLabel(record.PatientCountry, this.props.countries) : "",
                    stateLabel: record && record.PatientState ? getLabel(record.PatientState, this.props.states) : "",
                    patientRace: record && record.PatientRaceID ? getLabel(record.PatientRaceID, this.props.race) : "",
                    patientGender: record && record.PatientGenderID ? getLabel(record.PatientGenderID, this.props.gender) : "",
                    patientEthnicity: record && record.PatientEthnicityID ? getLabel(record.PatientEthnicityID, this.props.ethnicity) : "",
                    patientDOB: record && record.PatientDOB ? parseDate(record.PatientDOB) : null,
                    treatmentID: record && record.TreatmentID ? getLabel(record.TreatmentID, this.props.treatments) : "",
                    AppointmentDate: record && record.AppointmentDate ? parseDate(record.AppointmentDate) : null,
                    lastUpdatedDate: record && record.LastUpdatedDate ? parseDate(record.LastUpdatedDate) : null,
                    createdDate: record && record.CreatedDate ? parseDate(record.CreatedDate) : null,
                    insuredStateLabel: record && record.InsuredState ? getLabel(record.InsuredState, this.props.states) : "",
                    insuredDOB: record && record.InsuredDOB ? parseDate(record.InsuredDOB) : null,
                    facilityName: record && record.FacilityID ? getLabel(record.FacilityID, this.props.facilities) : "",
                } as TreatmentRecordType,
                ModalShowInsurance: record.ShowInsurance
            });
        }
    }

    treatmentInsuranceCardUpload() {
      if (!isMobile) {
        sweetalert
          .fire({
            title: "Add New Insurance Card Images",
            showCloseButton: true,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Upload",
            denyButtonText: `Capture`,
          })
          .then((result) => {
            //console.log(result, "RESULT")
            this.setState({ showInBetween: true });
            if (result.isConfirmed) {
              showModalNoOutsideClick(InsuranceCardUploadModal.ID);
            } else if (result.isDenied) {
              this.setState({ captureClicked: true });
              showModalNoOutsideClick(InsuranceImageModal.ID);
            } else if (result.isDismissed) {
              this.setState({ showInBetween: false });
            }
          });
      } else {
        this.setState({ showInBetween: true });
        showModalNoOutsideClick(InsuranceCardUploadModal.ID);
      }
    }

    getInsuranceCardImagesFromCloud() {
      if (this.state.imageCached) {
        this.setState({ showInBetween: true });
        showModalNoOutsideClick(SampleInsuranceCardModal.ID);
      } else {
        this.setState({ showLoadingForViewInsuranceCards: true }, async () => {
          try {
            let frontImage = await TreatmentRecordsAPI.getInsuranceCardImagesFromCloud({
              url: this.state.selectedTreatment?.InsuredFrontCardURL,
              side: "front",
              ReqNum: this.state.selectedTreatment?.ReqNum,
            });
            if (typeof frontImage === "object") {
              sweetalert.fire({
                icon: "error",
                title: "",
                text: "Insurance Card Images not found",
              });
              this.setState({ showLoadingForViewInsuranceCards: false });
              return;
            } else {
              this.setState({ frontInsuranceCardImage: frontImage });
            }
            let backImage = await TreatmentRecordsAPI.getInsuranceCardImagesFromCloud({
              url: this.state.selectedTreatment?.InsuredBackCardURL,
              side: "back",
              ReqNum: this.state.selectedTreatment?.ReqNum,
            });
            if (typeof backImage === "object") {
              sweetalert.fire({
                icon: "error",
                title: "",
                text: "Insurance Card Images not found",
              });
              this.setState({ showLoadingForViewInsuranceCards: false });
              return;
            } else {
              this.setState({
                backInsuranceCardImage: backImage,
                showInBetween: true,
                showLoadingForViewInsuranceCards: false,
                imageCached: true,
              });
              showModalNoOutsideClick(SampleInsuranceCardModal.ID);
            }
          } catch (e) {
            console.log(e);
            sweetalert.fire({
              icon: "error",
              title: "",
              text: "Error getting Insurance Card Images",
            });
            this.setState({ showLoadingForViewInsuranceCards: false });
          }
        });
      }
    }

    addDashes(e, stateToSet) {
      const len = e.target.value.length;
      if (len === 3 || len === 6) {
        e.preventDefault();
        this.setState((prevState) => ({
          selectedTreatment: {
            ...prevState.selectedTreatment,
            [stateToSet]: `${e.target.value}-${e.data}`,
          },
        }));
      }
    }

    handleChange(e, stateToSet, stateLabelToSet='', type='input'){
        if(type === 'select'){
            this.setState((prevState) => ({
              selectedTreatment: {
                ...prevState.selectedTreatment,
                [stateLabelToSet]: e,
                [stateToSet]: e.value,
              },
            }));
        }
        else{
          this.setState((prevState) => ({
            selectedTreatment: {
              ...prevState.selectedTreatment,
              [stateToSet]: e.target.value,
            },
          }));
        }
        
      }

      // isInsuranceRequired(e){
      //   //console.log(e)
      //   SystemAPI.isInsuranceRequiredOnTreatment(e.value).then((data) => {
      //     //console.log(data)
      //     this.setState({ ModalShowInsurance: data.length > 0 ? data : 0});
      // });
      // }

      handleDateOnChange(value, state) {
        //console.log(value, "VALUE")
        if (value) {
          this.setState((prevState) => ({
            selectedTreatment: {
              ...prevState.selectedTreatment,
              [state]: value,
            },
          }));
        } else {
          this.setState((prevState) => ({
            selectedTreatment: {
              ...prevState.selectedTreatment,
              [state]: null,
            },
          }));
        }
      }

      onUpload = (state, e) => {
        if (e.target) {
          this.setState({ [state]: e.target.files[0] } as any);
        } else {
          this.setState({ [state]: e } as any);
        }
      };
    
      clearFiles = () => {
        const frontUpload = document.querySelector("#FrontCardCapture") as any;
        frontUpload.value = "";
        const backUpload = document.querySelector("#BackCardCapture") as any;
        backUpload.value = "";
        this.setState({ frontCardImgFile: null, backCardImgFile: null, imageSaved: false });
      };


    clearFields(){
        this.setState({
            selectedTreatment: emptyRecordState
        })
    }


    render() {

        return(
          <>
            <SampleInsuranceCardModal
              handleLoading={() =>
                this.setState({
                  showInBetween: false,
                  showLoadingForViewInsuranceCards: false,
                })
              }
              closeInBetween={() => this.setState({ showInBetween: false })}
              frontImage={this.state.frontInsuranceCardImage}
              backImage={this.state.backInsuranceCardImage}
              reqNum={this.state.selectedTreatment?.ReqNum}
            />
            <InsuranceCardUploadModal
            frontUpload={(e) => this.onUpload("frontCardImgFile", e)}
            backUpload={(e) => this.onUpload("backCardImgFile", e)}
            clearFiles={() => this.clearFiles()}
            handleLoading={() => this.setState({ showInBetween: false })}
            closeInBetween={() => this.setState({ showInBetween: false })}
            handleImageSavedButton={(e) => this.setState({ imageSaved: e })}
            isDupe={false}
            clearImageState={() => this.setState({ frontCardImgFile: null, backCardImgFile: null }) }
          />
          <InsuranceImageModal
            onFrontImgChange={(e) => this.onUpload("frontCardImgFile", e)}
            onBackImgChange={(e) => this.onUpload("backCardImgFile", e)}
            clearFiles={() => this.clearFiles()}
            handleLoading={() => this.setState({ showInBetween: false })}
            closeInBetween={() => this.setState({ showInBetween: false })}
            handleImageSavedButton={(e) => this.setState({ imageSaved: e })}
            captureClicked={this.state.captureClicked}
            clearImageState={() => this.setState({ frontCardImgFile: null, backCardImgFile: null }) }
          />
            <Overlay show_loading={this.state.showLoading} />
            <Overlay show_loading={this.state.showLoadingForViewInsuranceCards || this.state.showLoadingForPhysicianReview} zIndex={100005} />
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <div className="modal fade form_modal" id={TreatmentRecordsModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Evaluation Record</h5>
                            <button  style={{outline: 'none'}} type="button" onClick={() => {this.clearFields(); this.props.onClose()}} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body m-3">
                        <div className="card mb-2">
                            {/* Patient Accordion */}
                            <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#patientXLCollapse" onClick={() => this.setState({patientArrowDown: !this.state.patientArrowDown,})} aria-expanded="false" aria-controls="patientXLCollapse">
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Employee</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.patientArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#patientXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#patientXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                            </button>
                            <div className="card-body collapse" id={"patientXLCollapse"}>
                                <div className="row">
                                <div className="col-12 col-xl-6">
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientFirstName} label={"First Name"} tooltip={"The first name of the Patient"} handleChange={(e) => this.handleChange(e, "PatientFirstName")} readOnly={true} />
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientMiddleName} label={"Middle Name"} tooltip={"The middle name of the Patient"} handleChange={(e) => this.handleChange(e, "PatientMiddleName")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientLastName} label={"Last Name"} tooltip={"The last name of the Patient"} handleChange={(e) => this.handleChange(e, "PatientLastName")} readOnly={true}/>
                                    <CustomDate fromFilterPage={false} value={this.state.selectedTreatment?.patientDOB} label={"DOB"} tooltip={`The date of birth of the Patient`} handleDateOnChange={(e) => this.handleDateOnChange(e, "patientDOB")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientEmail} label={"Email"} tooltip={"The email of the Patient"} maxLength={100} handleChange={(e) => this.handleChange(e, "PatientEmail")} readOnly={true}/>
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedTreatment?.patientEthnicity} label={"Ethnicity"} tooltip={"The ethnicity of the Patient"} handleChange={(e) => this.handleChange(e, "PatientEthnicityID", "patientEthnicity", "select")} options={this.props.ethnicity} readOnly={true}/>
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedTreatment?.patientRace} label={"Race"} tooltip={"The race of the Patient"} handleChange={(e) => this.handleChange(e, "PatientRaceID", "patientRace", "select")} options={this.props.race} readOnly={true}/>
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedTreatment?.patientGender} label={"Gender"} tooltip={"The gender of the Patient"} handleChange={(e) => this.handleChange(e, "PatientGenderID", "patientGender", "select")} options={this.props.gender} readOnly={true}/>
                                </div>
                                <div className="col-12 col-xl-6">
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientPhone} label={"Phone"} tooltip={`The phone number of the Patient`} handleDateOnChange={(e) => this.handleDateOnChange(e, "PatientPhone")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientAddress} label={"Address"} tooltip={"The address of the Patient"} maxLength={500} handleChange={(e) => this.handleChange(e, "PatientAddress")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientCity} label={"City"} tooltip={"The city of the Patient"} maxLength={100} handleChange={(e) => this.handleChange(e, "PatientCity")} readOnly={true}/>
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedTreatment?.stateLabel} label={"State"} tooltip={"The state of the Patient's address"} handleChange={(e) => this.handleChange(e, "PatientState", "stateLabel", "select")} options={this.props.states} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientZip} label={"Zip"} tooltip={"The zip of the Patient"} handleChange={(e) => this.handleChange(e, "PatientZip")} readOnly={true}/>          
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.PatientCounty} label={"County"} tooltip={"The county of the Patient"} maxLength={100} handleChange={(e) => this.handleChange(e, "PatientCounty")} readOnly={true}/>
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedTreatment?.countryLabel} label={"Country"} tooltip={"The country of the Patient's address"} handleChange={(e) => this.handleChange(e, "PatientCountry", "countryLabel", "select")} options={this.props.countries} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.GuardianFirstName} label={"Guardian First Name"} tooltip={"The first name of the guardian if the patient is under 18"} handleChange={(e) => this.handleChange(e, "GuardianFirstName")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.GuardianLastName} label={"Guardian Last Name"} tooltip={"The last name of the guardian if the patient is under 18"} handleChange={(e) => this.handleChange(e, "GuardianLastName")} readOnly={true}/>
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* Appt Accordion */}
                            {this.state.selectedTreatment.AppointmentDate ? <>
                            <div className="card mb-2">
                            <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#apptXLCollapse" onClick={() => this.setState({apptArrowDown: !this.state.apptArrowDown,})} aria-expanded="false" aria-controls="patientXLCollapse">
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Appointment</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.apptArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#apptXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#apptXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                            </button>
                            <div className="card-body collapse" id={"apptXLCollapse"}>
                                <div className="row">
                                <div className="col-12 col-xl-12">
                                    <CustomDate fromFilterPage={false} value={this.state.selectedTreatment?.AppointmentDate} label={"Appointment Date"} tooltip={"Date of Appointment"} handleDateOnChange={(e) => this.handleDateOnChange(e, "AppointmentDate")} readOnly={true}/>
                                    <CustomDate fromFilterPage={false} value={this.state.selectedTreatment?.AppointmentTime} label={"Appointment Time"} tooltip={`Time of appointment`} timeOnly={true} handleDateOnChange={(e) => this.handleDateOnChange(e, "AppointmentTime")} readOnly={true}/> 
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.ReqNum} label={"Confirmation Number"} tooltip={"Confirmation Number for Appointment"} readOnly={true} />
                                    
                                </div>
                            </div>
                            
                        </div>
                        </div>
                        </> : <></>}
                        {/* Treatment Accordion */}
                        <div className="card mb-2">
                        <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#vaccXLCollapse" onClick={() => this.setState({treatArrowDown: !this.state.treatArrowDown,})} aria-expanded="false" aria-controls="patientXLCollapse">
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Evaluation</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.treatArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#vaccXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#vaccXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                            </button>
                            <div className="card-body collapse" id={"vaccXLCollapse"}>
                                <div className="row">
                                <div className="col-12 col-xl-12">
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedTreatment.treatmentID} label={"Treatment"} tooltip={"Treatment chose to be administered to the patient"} handleChange={(e) => {this.handleChange(e, "TreatmentID", "treatmentID", 'select')}} options={this.props.treatments} readOnly={true}/>
                                    <CustomSelect fromFilterPage={false} value={this.state.selectedTreatment.facilityName} label={"Facility"} tooltip={"Facility where treatment is taking place"} handleChange={(e) => {this.handleChange(e, "FacilityID", "facilityName", 'select')}} options={this.props.facilities} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.ProviderFirstName} label={"Provider First Name"} tooltip={"First name of Doctor who administered treatment"} handleChange={(e) => this.handleChange(e, "ProviderFirstName")} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.ProviderLastName} label={"Provider Last Name"} tooltip={"Last name of Doctor who administered treatment"} handleChange={(e) => this.handleChange(e, "ProviderLastName")} readOnly={true}/>
                                </div>
                            </div>
                            </div>
                            </div>
                            {/* Insurance Accordion */}
                            
                            <div className="card mb-2">
                                <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#insuranceXLCollapse" onClick={() => this.setState({ insuranceArrowDown: !this.state.insuranceArrowDown, }) } aria-expanded="false" aria-controls="insuranceXLCollapse" >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Insurance</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.insuranceArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#insuranceXLCollapse" role="button" aria-expanded="false" aria-controls="insuranceXLCollapse" />
                                      ) : (
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#insuranceXLCollapse" role="button" aria-expanded="false" aria-controls="insuranceXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div className="card-body collapse" id={"insuranceXLCollapse"} >
                                  {this.state.selectedTreatment?.rerunCount > 0 ? (
                                    <div> Please see original record to manage insurance information. Be sure to change Rerun Flag to 'No' or blank in the filter to find original record </div>
                                  ) : (
                                    <>
                                      <div className="row mb-4 pr-1">
                                        <div className={"col-12 text-center text-xl-left"}>
                                          <div className="row justify-content-between">
                                            <div className="col-12 col-xl-3 font-weight-bold"> Insurance holder's information</div>
                                            <button className={this.state.selectedTreatment?.InsuredFrontCardURL ? "d-xl-none col-12 mt-2 btn btn-success btn-sm mb-2 w-60" : "d-none"} onClick={() => this.getInsuranceCardImagesFromCloud()}> View Insurance Card Images </button>
                                            <button className={this.state.imageSaved ? "col-12 col-xl-2 pl-xl-0" : "invisible"}>
                                              <AiOutlineCheckCircle className={"ml-2"} color={"#14410B"} size={30} />
                                              <span className={"ml-1 text-success"} style={{ fontSize: "1em" }} > Images Saved </span>
                                            </button>
                                            <button className={ this.state.selectedTreatment?.InsuredFrontCardURL ? "d-none d-xl-inline col-xl-3 btn btn-success btn-sm" : "d-none" } onClick={() => this.getInsuranceCardImagesFromCloud() } > View Insurance Card Images </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-xl-6">
                                          <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.InsuredFirstName} label={"First Name"} tooltip={"First name of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredFirstName")}  readOnly={true}/>
                                          <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.InsuredLastName} label={"Last Name"} tooltip={"Last name of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredLastName")}  readOnly={true}/>
                                          <CustomInput  fromFilterPage={false} value={this.state.selectedTreatment?.InsuredSSN} label={"Social Security #"} tooltip={"SSN of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredSSN")} addDashes={(e) => this.addDashes(e, "InsuredSSN")} readOnly={true} />
                                          <CustomDate fromFilterPage={false} value={this.state.selectedTreatment?.insuredDOB} label={"DOB"} tooltip={`The date of birth of the insurance holder`} showDateWarning={true} handleDateOnChange={(e) => this.handleDateOnChange(e, "insuredDOB")}  readOnly={true}/>
                                          <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.InsuredMRN} label={"Medical Record #"} tooltip={"Medical Record Number of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredMRN")} readOnly={true}/>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                          <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.InsuredAddressLine1} label={"Address"} tooltip={"Address of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredAddressLine1")} maxLength={100} readOnly={true}/>
                                          <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.InsuredAddressLine2} label={"Address Cont."} tooltip={"Address Cont. of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredAddressLine2")} maxLength={100} readOnly={true}/>
                                          <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.InsuredCity} label={"City"} tooltip={"City of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredCity")} readOnly={true}/>
                                          <CustomSelect fromFilterPage={false} value={this.state.selectedTreatment?.insuredStateLabel} label={"State"} tooltip={`State of insurance holder`} handleChange={(e) => this.handleChange(e, "insuredState", "insuredStateLabel", "select")} options={this.props.states} readOnly={true}/>
                                          <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.InsuredZip} label={"Zip"} tooltip={"Zipcode of insurance holder"} handleChange={(e) => this.handleChange(e, "InsuredZip")} readOnly={true}/>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                </div>
                              
                            {/* Additional Accordion */}
                            {this.state.selectedTreatment.ID ? <>
                            <div className="card mb-2">
                            <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#addXLCollapse" onClick={() => this.setState({addArrowDown: !this.state.addArrowDown,})} aria-expanded="false" aria-controls="patientXLCollapse">
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Additional</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.addArrowDown ? (
                                        <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#addXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      ) : (
                                        
                                        <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#addXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />
                                      )}
                                    </div>
                                  </div>
                            </button>
                            <div className="card-body collapse" id={"addXLCollapse"}>
                                <div className="row">
                                <div className="col-12 col-xl-12">
                                    <CustomDate fromFilterPage={false} value={this.state.selectedTreatment?.lastUpdatedDate} label={"Last Updated Date"} tooltip={"Record Last Updated Date"} readOnly={true}/>
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.RecordLastUpdatedBy} label={"Last Updated By"} tooltip={"Record last updated by this person"} readOnly={true} />
                                    <CustomDate fromFilterPage={false} value={this.state.selectedTreatment?.createdDate} label={"Created Date"} tooltip={"Patient created appointment"} readOnly={true} />
                                    <CustomInput fromFilterPage={false} value={this.state.selectedTreatment?.RecordCreatedBy} label={"Created By"} tooltip={"Record created by this person"} readOnly={true} />
                                    
                                </div>
                            </div>
                            </div>
                            </div>
                            </> : <></>}

                            {/* if hasNonStandard is true, display ranges  */}

                            {/*Custom Field Data*/}

                            {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField 
                                                            val={val} 
                                                            selectedItem={this.state.selectedTreatment ? this.state.selectedTreatment : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedTreatment: e})
                                                            }}  
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }


                            {/* <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Test Result Description</label>
                                <div className="col-sm-12  p-0 m-0">
                                    <ReactQuill theme="snow" value={this.state.selectedTreatment.ReportDescription}
                                                onChange={(e) =>{
                                                    this.setState( (prevState) => ({
                                                        selectedTreatment: {
                                                            ...prevState.selectedTreatment,
                                                            ReportDescription: e
                                                        }
                                                    }))
                                                }}
                                    />
                                </div>
                            </div> */}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => {

                                    let recordInfo = {
                                      ID: this.state.selectedTreatment?.sampleID,
                                      LinkedApptsID: this.state.selectedTreatment?.linkedApptsID ? this.state.selectedTreatment?.linkedApptsID.trim() : null,
                                      ReqNum: this.state.selectedTreatment?.reqNum ? this.state.selectedTreatment?.reqNum.trim() : null,
                                      SpecimenID: this.state.selectedTreatment?.specimenID ? this.state.selectedTreatment?.specimenID.trim() : null,
                                      InternalSpecimenID: this.state.selectedTreatment?.internalSpecimenID ? this.state.selectedTreatment?.internalSpecimenID.trim() : null,
                                      Memo: this.state.selectedTreatment?.memo ? this.state.selectedTreatment?.memo.trim() : null,
                                      PatientFirstName: this.state.selectedTreatment?.patientFirstName ? this.state.selectedTreatment?.patientFirstName.trim() : null,
                                      PatientMiddleName: this.state.selectedTreatment?.patientMiddleName ? this.state.selectedTreatment?.patientMiddleName.trim() : null,
                                      PatientLastName: this.state.selectedTreatment?.patientLastName ? this.state.selectedTreatment?.patientLastName.trim() : null,
                                      PatientDOB: getDateOrNull(this.state.selectedTreatment?.patientDOB),
                                      PatientZip: this.state.selectedTreatment?.patientZip ? this.state.selectedTreatment?.patientZip.trim() : null,
                                      PatientCity: this.state.selectedTreatment?.patientCity ? this.state.selectedTreatment?.patientCity.trim() : null,
                                      PatientState: this.state.selectedTreatment?.stateLabel ? this.state.selectedTreatment?.stateLabel.value : null,
                                      PatientCounty: this.state.selectedTreatment?.patientCounty ? this.state.selectedTreatment?.patientCounty.trim() : null,
                                      PatientCountry: this.state.selectedTreatment?.countryLabel ? this.state.selectedTreatment?.countryLabel.value : null,
                                      PatientAddress: this.state.selectedTreatment?.patientAddress ? this.state.selectedTreatment?.patientAddress.trim() : null,
                                      PatientEmail: this.state.selectedTreatment?.patientEmail ? this.state.selectedTreatment?.patientEmail.trim() : null,
                                      PatientPhone: this.state.selectedTreatment?.patientPhone ? this.state.selectedTreatment?.patientPhone.trim() : null,
                                      PatientEthnicityID: this.state.selectedTreatment?.patientEthnicity ? this.state.selectedTreatment?.patientEthnicity.value : null,
                                      PatientRaceID: this.state.selectedTreatment?.patientRaceID ? this.state.selectedTreatment?.patientRace.value : null,
                                      PatientGenderID: this.state.selectedTreatment?.patientGender ? this.state.selectedTreatment?.patientGender.value : null,
                                      GuardianFirstName: this.state.selectedTreatment?.guardianFirstName ? this.state.selectedTreatment?.guardianFirstName.trim() : null,
                                      GuardianLastName: this.state.selectedTreatment?.guardianLastName ? this.state.selectedTreatment?.guardianLastName.trim() : null,
                                      ProviderFirstName: this.state.selectedTreatment?.providerFirstName ? this.state.selectedTreatment?.providerFirstName.trim() : null,
                                      ProviderLastName: this.state.selectedTreatment?.providerLastName ? this.state.selectedTreatment?.providerLastName.trim() : null,
                                      SampleReceivedID: this.state.selectedTreatment?.binID ? this.state.selectedTreatment?.bin.value.ID : null,
                                      BatchID: this.state.selectedTreatment?.batchNum ? this.state.selectedTreatment?.batchNums.value.ID : null,
                                      WellSlot: this.state.selectedTreatment?.wellSlot ? this.state.selectedTreatment?.wellSlot.trim() : null,
                                      CollectionDate: getDateOrNull(this.state.selectedTreatment?.collectionDate),
                                      SpecimenSourceID: this.state.selectedTreatment?.specimenSourceID ? this.state.selectedTreatment?.specimenSourceID : null,
                                      TestIDs: this.state.selectedTreatment?.testIDs ? this.state.selectedTreatment?.testIDs : null,
                                      ProcessedDate: getDateOrNull(this.state.selectedTreatment?.processedDate),
                                      TestOrderedDate: getDateOrNull(this.state.selectedTreatment?.testOrderedDate),
                                      ApptTime: this.state.selectedTreatment?.apptTime ? moment(this.state.selectedTreatment?.apptTime, "hh:mm a").format("hh:mm a") : null,
                                      PodApptID: this.state.selectedTreatment?.podLabel ? this.state.selectedTreatment?.podLabel.value : null,
                                      ApptCanceled: this.state.selectedTreatment?.apptCanceledLabel ? this.state.selectedTreatment?.apptCanceledLabel.value : null,
                                      RerunCount: this.state.selectedTreatment?.rerunCount ? this.state.selectedTreatment?.rerunCount : null,
                                      RerunFlag: this.state.selectedTreatment?.rerunFlagLabel ? this.state.selectedTreatment?.rerunFlagLabel.value : null,
                                      ConsentFlag: this.state.selectedTreatment?.consentFlagLabel ? this.state.selectedTreatment?.consentFlagLabel.value : null,
                                      ResultIDs: this.state.selectedTreatment?.resultIDs ? this.state.selectedTreatment?.resultIDs : null,
                                      Reason: this.state.selectedTreatment?.reason ? this.state.selectedTreatment?.reason.trim() : null,
                                      TestResultDate: getDateOrNull(this.state.selectedTreatment?.testResultDate),
                                      ReportedDate: getDateOrNull(this.state.selectedTreatment?.reportedDate),
                                      CreatedDate: getDateOrNull(this.state.selectedTreatment?.createdDate),
                                      FacilityID: this.state.selectedTreatment?.facilityID ? this.state.selectedTreatment?.facilityName.value : null,
                                      ResultsAccessedDate: getDateOrNull(this.state.selectedTreatment?.resultsAccessedDate),
                                      Lot: this.state.selectedTreatment?.lot ? this.state.selectedTreatment?.lot.trim() : null,
                                      CustomFieldData: this.state.selectedTreatment?.CustomFieldData ? this.state.selectedTreatment?.CustomFieldData : null,
                                      IncludeInHL7: this.state.selectedTreatment?.IncludeInHL7 ? this.state.selectedTreatment.IncludeInHL7 : null,
                                      TestsAndResultsMap: this.state.selectedTreatment?.testsAndResultsMap ? this.state.selectedTreatment?.testsAndResultsMap : null,
                                    };
                                    let insuranceInfo = {
                                      FirstName: this.state.selectedTreatment?.InsuredFirstName ? this.state.selectedTreatment?.InsuredFirstName.trim() : null,
                                      LastName: this.state.selectedTreatment?.InsuredLastName ? this.state.selectedTreatment?.InsuredLastName.trim() : null,
                                      SSN: this.state.selectedTreatment?.InsuredSSN ? this.state.selectedTreatment?.InsuredSSN.replace(/-/g, "").trim() : null,
                                      DOB: getDateOrNull(this.state.selectedTreatment?.insuredDOB),
                                      MRN: this.state.selectedTreatment?.InsuredMRN ? this.state.selectedTreatment?.InsuredMRN.trim() : null,
                                      AddressLine1: this.state.selectedTreatment?.InsuredAddressLine1 ? this.state.selectedTreatment?.InsuredAddressLine1.trim() : null,
                                      AddressLine2: this.state.selectedTreatment?.InsuredAddressLine2 ? this.state.selectedTreatment?.InsuredAddressLine2.trim() : null,
                                      City: this.state.selectedTreatment?.InsuredCity ? this.state.selectedTreatment?.InsuredCity.trim() : null,
                                      State: this.state.selectedTreatment?.insuredStateLabel ? this.state.selectedTreatment?.insuredStateLabel.value : null,
                                      Zip: this.state.selectedTreatment?.InsuredZip ? this.state.selectedTreatment?.InsuredZip.trim() : null,
                                      FrontCard: this.state.selectedTreatment.InsuredFrontCardURL ? this.state.selectedTreatment.InsuredFrontCardURL : null,
                                      BackCard: this.state.selectedTreatment.InsuredBackCardURL ? this.state.selectedTreatment.InsuredBackCardURL : null,
                                    };
                                    let insuranceCards = this.state.frontCardImgFile ? [this.state.frontCardImgFile, this.state.backCardImgFile] : null;

                                    this.state.selectedTreatment.AppointmentTime = moment(this.state.selectedTreatment?.AppointmentTime, "hh:mm a").format("hh:mm a")

                                    let formValidation = {
                                        FirstName: this.state.selectedTreatment.PatientFirstName,
                                        LastName: this.state.selectedTreatment.PatientLastName,
                                        Email: this.state.selectedTreatment.PatientEmail,
                                        Ethnicity: this.state.selectedTreatment.PatientEthnicityID,
                                        Race: this.state.selectedTreatment.PatientRaceID,
                                        DOB: this.state.selectedTreatment.patientDOB,
                                        Gender: this.state.selectedTreatment.PatientGenderID,
                                        Phone: this.state.selectedTreatment.PatientPhone,
                                        Address: this.state.selectedTreatment.PatientAddress,
                                        City: this.state.selectedTreatment.PatientCity,
                                        State: this.state.selectedTreatment.PatientState,
                                        ZIP: this.state.selectedTreatment.PatientZip,
                                        Country: this.state.selectedTreatment.PatientCountry,
                                        County: this.state.selectedTreatment.PatientCounty,
                                        AppointmentDate: this.state.selectedTreatment.AppointmentDate,
                                        Treatment: this.state.selectedTreatment.Treatment,
                                        ProviderFirstName: this.state.selectedTreatment.ProviderFirstName,
                                        ProviderLastName: this.state.selectedTreatment.ProviderLastName
                                    }

                                    //console.log(formValidation, "VALIDATION OBJECT")

                                    let validator = new Validator<any>()
                                    .withValidation("FirstName", Validators.requireNotBlankAndLength(50, "First Name"))
                                    .withValidation("LastName", Validators.requireNotBlankAndLength(50, "Last Name"))
                                    .withValidation("DOB", Validators.requireDOB(150, "Patient Date of Birth"))
                                    .withComposedValidation("Email", new ValidationEntry(Validators.requireValidEmail("Patient Email")), Validators.requireNotBlankAndLength(100, "Patient Email"))
                                    .withComposedValidation("Phone", new ValidationEntry(Validators.requireNonNullValidator("Patient Phone")), new ValidationEntry(Validators.requirePhone("Patient Phone")))
                                    .withComposedValidation("ZIP", new ValidationEntry(Validators.requireNotBlankValidator("Patient Zipcode")), new ValidationEntry(Validators.requireZip("Patient Zipcode")))
                                    //.withValidation("Ethnicity", Validators.requireNotBlankAndLength(45, "Ethnicity"))
                                    //.withValidation("Race", Validators.requireNotBlankAndLength(45, "Race"))
                                    //.withValidation("Gender", Validators.requireNotBlankAndLength(45, "Gender"))
                                    .withValidation("Address", Validators.requireNotBlankAndLength(60, "Address"))
                                    .withValidation("City", Validators.requireNotBlankAndLength(60, "City"))
                                    .withValidation("State", Validators.requireNotBlankAndLength(60, "State"))
                                    .withValidation("Country", Validators.requireNotBlankAndLength(60, "Country"))
                                    .withValidation("County", Validators.requireNotBlankAndLength(60, "County"))
                                    //.withValidation("AppointmentDate", Validators.requireNotBlankAndLength(45, "Appointment Date"))
                                    // .withValidation("Vaccine", Validators.requireNotBlankAndLength(45, "Vaccine"))
                                    // .withValidation("InjectionSite", Validators.requireNotBlankAndLength(100, "Injection Site"))
                                    //.withValidation("LotNumber", Validators.requireNotBlankAndLength(45, "Lot Number"))
                                    // .withValidation("NurseFirstName", Validators.requireNotBlankAndLength(50, "Nurse First Name"))
                                    // .withValidation("NurseLastName", Validators.requireNotBlankAndLength(50, "Nurse Last Name"))
                                    // .withValidation("NurseLicenseNum", Validators.requireNotBlankAndLength(45, "Nurse License Number"))
                                   
                                       
                                    let validationResponse = validator.validate(formValidation);
                                    if(!validationResponse.success) {
                                        return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                    }

                                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedTreatment.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedTreatment)

                                    if(invalid){
                                        return;
                                    }
                                    if(this.state.ModalShowInsurance && !insuranceCards){
                                      return sweetalert.fire({icon: 'error', title: '', text: "Please submit insurance card photos"});
                                    }

                                    hideModal(TreatmentRecordsModal.ID)
                                    this.props.onSubmit( 
                                        this.state.selectedTreatment as TreatmentRecordType,
                                        insuranceInfo,
                                        insuranceCards
                                    )
                                    this.setState({
                                        selectedTreatment: emptyRecordState
                                    })
                                }}>
                                    Submit
                                </button>
                            </div>
                    </div>
                </div>
                </div>
                </div></>
           
        )
    }
}