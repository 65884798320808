import React from "react";
import FilterCard, {FIELD_TYPE, VALIDATOR_TYPE} from "./FilterCard";
import {buildRow, parseDate} from "../util/FormatUtil";
import ReactToPrint from "react-to-print";
import TestResult from "./TestResult";
import Overlay from "./Overlay";
import moment from "moment";
import ConsentingModal from "./modals/ConsentingModal";
import {getToken} from "../util/CaptchaUtil";
import {ImageType} from "../types/Branding";
import {Sample} from "../types/Sample";
import ReleaseForm from "./Release";
import {load} from "recaptcha-v3";
import {CAPTCHA, sweetalert} from "../App";
import SystemAPI from "../network/SystemAPI";
import SubmissionAPI from "../network/SubmissionAPI";
import Validator from "../validation/Validator";
import {Validators} from "../validation/Validators";
import {calculateAge, isBlank, isOnlyWhitespace} from "../util/ValidationUtil";
import ServicesAPI from "../network/ServicesAPI";
import ResultsAPI from "../network/ResultsAPI";
import BasicReport from "./resultReports/BasicReport";
import PeriodontalReport from "./resultReports/PeriodontalReport";
import AdminAPI from "../network/AdminAPI";
import {FcRotateToLandscape} from 'react-icons/fc'
import InBetweenOverlay from "./InBetweenOverlay";
import { AuthContext } from "../context/AuthContext";
import PatientAPI from "../network/PatientAPI";
import VaccineReport from "./resultReports/VaccineReport";
import TreatmentReport from "./resultReports/TreatmentReport";
import Select from "react-select";
interface ResultsState {
    hasResult: boolean
    showLoading: boolean
    result: any | Sample
    FName?: string
    LName?: string
    DOB?: Date
    RNum?: string
    token?: string
    NoRecord?: string
    release?
    consenting: {ID:number,Name:string,FacilityID:number,AccessCode:string}[]
    gender?
    specimen_source?
    logo
    test?
    results?
    reportTypes?
    buttonColorHexValue:string
    branding?
    RecordType?
    productName
}

export default class ResultsForm extends React.Component<any, ResultsState> {
    private componentRef: any;
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {result: {}, showLoading: false, hasResult: false, consenting: [], logo:"", buttonColorHexValue: '', productName: ""}
        this.submit = this.submit.bind(this);
        this.openConsent = this.openConsent.bind(this);
        this.redirectToPortal = this.redirectToPortal.bind(this);
    }

    componentDidMount() {
        document.title = 'Results Page';
        let currentURL = window.location.href;
        this.setState({showLoading: true}, () => {
            document.body.style.backgroundColor = 'black';

            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let backgroundImageURL = data.ProductBackgroundImageURL;
                //@ts-ignore
                document.body.style.backgroundImage = `url(${backgroundImageURL}`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";
                this.setState({logo: data.ProductLogoURL, productName: data.Name, buttonColorHexValue: data.ProductButtonColorHexValue, showLoading: false});
            })

            if(this.context){
                PatientAPI.getUserProfileByUID(this.context.uid).then((response)=>{
                    let patientInfo = response.data[0];
                    this.setState({
                        FName: patientInfo.FirstName,
                        LName: patientInfo.LastName,
                        DOB: patientInfo.DOB
                    })
                })
            }

            SystemAPI.getAllGenders().then(data => {
                this.setState({gender: data})
            })
            SystemAPI.getAllSpecimenSources().then(data => {
                this.setState({specimen_source: data})
            })
            SystemAPI.getConsentEntities().then(data => {
                this.setState({consenting: data})
            })
            SystemAPI.getAllResultTypes().then((data) => {
                this.setState({ results: data });
            });
            SystemAPI.getReportTypes().then((data) => {
                this.setState({reportTypes: data})
            })
            SystemAPI.getBranding().then(data => {
                this.setState({branding: data.branding})
            })
            

        })

        const query = new URLSearchParams(window.location.search);

        if (query.get('fname') && query.get('fname') !== "") {
            let parsedDate = moment(query.get("dob"), ["MM/DD/YYYY", "MM-DD-YYYY", "YYYY-MM-DD"], false)
            this.setState({
                FName: query.get('fname'),
                LName: query.get("lname"),
                DOB: parsedDate.toDate(),
                RNum: query.get("conf"),
                NoRecord: query.get("norecord")
            }, () => this.submit())
        }
        else{
            this.setState({showLoading: false})
        }
    }

    openConsent() {
        ConsentingModal.display();
    }

    private async submit() {
        let formValidation = {
            FirstName: this.state.FName,
            LastName: this.state.LName,
            ReqNum: this.state.RNum,
            DOB: this.state.DOB,
            RecordType: this.state.RecordType?.value
        }

        let validator = new Validator<any>()
            .withSimpleValidation("FirstName", Validators.requireNotBlankValidator("First Name"))
            .withSimpleValidation("LastName", Validators.requireNotBlankValidator("Last Name"))
            .withSimpleValidation("DOB", Validators.requireNonNullValidator("Date of Birth"))
            .withSimpleValidation("ReqNum", Validators.requireNotBlankValidator("Confirmation Number"))
            .withSimpleValidation("RecordType", Validators.requireNotBlankValidator("Record Type"))
        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        const filterObj = {
            FirstName: this.state.FName,
            LastName: this.state.LName,
            ReqNum: this.state.RNum,
            DOB:  parseDate(this.state.DOB),
            RecordType: this.state.RecordType
        }

        let token:string = await getToken();

        this.setState({showLoading: true})
        let result = await SubmissionAPI.submitResults(filterObj, token);
        
        try {
            if (result.success) {
                this.setState({
                    hasResult: true,
                    result: result.result
                })
            } else {
                sweetalert.fire({icon: 'error', title: '', text: result.reason});
            }
            this.setState({showLoading: false});
        }
        catch (e) {
            console.error(e)
            sweetalert.fire({icon: 'error', title: '', text: "We have encountered an error obtaining your results. Check your connection and try again, or please check back in a few hours"});
            this.setState({showLoading: false})
        }
    }


    getForm() {
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>
                <InBetweenOverlay showInBetween={true} zIndex={-10}/>
                <div className={this.state.showLoading ? "d-none" : "container  min-vh-100"}>
                    <div className="row justify-content-center mt-5">
                        <div className="col-12 col-md-9 col-xl-6">
                        <main id="main-content" tabIndex={-1} aria-label="Patient Home Page">
                            <div className="card shadow">
                                <div className="card-title text-center border-bottom" style={{"backgroundColor": this.state.buttonColorHexValue, "borderRadius": "3px"}}>
                                    <a href='/'>
                                        <img src={this.state.logo}
                                            style={{ width: '200px', margin: '20px' }}
                                            className={'nav-logo'} alt={this.state.productName}
                                        />
                                    </a>
                                    
                                </div>
                                <div className="card-body">
                                    {buildRow("First Name",
                                        <input 
                                            id="FirstName"
                                            className={"form-control"}
                                            autoComplete={"off"}
                                            type={"search"}
                                            name={"FirstName"}
                                            onChange={(e) => {
                                                this.setState({
                                                    FName: e.target.value
                                                })
                                            }}
                                            value={this.state.FName}
                                        />, "First Name"
                                    )}
                                    {buildRow("Last Name",
                                        <input 
                                            id="LastName"
                                            className={"form-control"}
                                            autoComplete={"off"}
                                            type={"search"}
                                            name={"LastName"}
                                            onChange={(e) => {
                                                this.setState({
                                                    LName: e.target.value
                                                })
                                            }}
                                            value={this.state.LName}
                                        />, "Last Name"
                                    )}
                                    {buildRow("Date of Birth",
                                        <input 
                                            id="DateofBirth"
                                            className={"form-control"}
                                            autoComplete={"off"}
                                            type={"date"}
                                            name={"DOB"}
                                            onChange={(e) => {
                                                this.setState({
                                                    DOB: new Date(e.target.value)
                                                })
                                            }}
                                        />, "Date of Birth"
                                    )}
                                    {buildRow("Confirmation Code",
                                        <input 
                                            id="ConfirmationCode"
                                            className={"form-control"}
                                            autoComplete={"off"}
                                            type={"search"}
                                            name={"ConfirmationCode"}
                                            onChange={(e) => {
                                                this.setState({
                                                    RNum: e.target.value
                                                })
                                            }}
                                            value={this.state.RNum}
                                        />, "Confirmation Code"
                                    )}
                                    {buildRow("Record Type",
                                        <Select
                                            isSearchable={true}
                                            className={"type_select"}
                                            placeholder={<div className="accessibilityText">Please Select...</div>}
                                            name={"RecordType"}
                                            aria-label="Record Type"
                                            onChange={(e) => {
                                                this.setState({
                                                    RecordType: e
                                                })
                                            }}
                                            value={this.state.RecordType}
                                            options={[{label: 'Test', value: 'test'}, {label: 'Vaccine', value: 'vaccine'}, {label: 'Treatment', value: 'treatment'}]}
                                        />
                                    )}
                                    <div className="d-flex justify-content-center">
                                        <button
                                            onClick={this.submit}
                                            id="results-button"
                                            name="results-button"
                                            className="btn btn-lg text-light width-100" style={{backgroundColor: this.state.buttonColorHexValue}}>
                                                Get My Results
                                        </button>
                                    </div>
                                </div>
                                <div className={"card-footer mx-auto d-flex justify-content-center mt-1"}>
                                    <p>A text and email will be sent to you once your test results are ready with instructions on how to securely obtain your results. Most test results will be available within 3 business days (excluding holidays and weekends) after your scheduled appointment date.
                                    </p>
                                </div>
                            </div>
                            </main>
                        </div>
                    </div>
                </div>
                    {/* keep */}
                            {/* <div className="d-grid mb-3">
                                <p className="d-fles justify-center text-center">
                                    Access all of your results in one convenient location with Streamline Patient Portal.
                                </p>
                                <div className="d-flex justify-center">
                                    <button
                                        className="btn"
                                        onClick={this.redirectToPortal}>
                                        <img src={this.state.logo}
                                            alt="streamlineLogo"
                                            style={{ "width": "300px", "margin": "10px" }}
                                        />
                                        <br />Register Here
                                    </button>
                                </div>
                            </div> */}
                        {/* </div>
                    </div>
                </div> */}
            </React.Fragment>
        )
    }

    getReport(result){
        let reportToReturn;
        let reportTypeString = this.state.reportTypes ? this.state.reportTypes.find(f => f.value === result.Service.ReportType).label : '';
        let branding = this.state.branding.find(f => f.ID === result.Service.BrandingID);
        switch (reportTypeString) {
            //basic report
            case 'basic':
                reportToReturn = <BasicReport result={result} branding={branding} />
                break;

            //periodontal report
            case 'periodontal5Tests':
                reportToReturn = <PeriodontalReport result={result} allResults={this.state.results} test={result.test} branding={branding} />
                break; 
            default:
                break;
        }
        return reportToReturn
    }

    getPCRResult(result) {
            return (
                <React.Fragment>
                    <div className="container  min-vh-100 ">
                        <div className={"row"}>
                            {this.state.result.Service && 
                            <ReleaseForm buttonColorHexValue={this.state.buttonColorHexValue} service={this.state.result.Service} consenting={result.ConsentEntity} releaseChanged={(r) => this.setState({release: r},
                                ()=> this.sendConsent())} revokeConsent={() => this.revokeConsent()}
                            />
                            }
                            

                            <div className={"col-12 text-center pt-2 verlag-bold responsive-p"}>
                                {result && result.Service && result.Service.ReportType && this.state.reportTypes.find(f => f.value === result.Service.ReportType).label === 'periodontal5Tests' ? <div className="d-none"></div> : 
                                <p>
                                    For further questions regarding your result, please consult your doctor or primary care
                                    physician. You can also learn more at the <a target="_blank"
                                                                                href={"https://www.cdc.gov/coronavirus/2019-ncov/testing/diagnostic-testing.html"}>Centers
                                    for Disease Control and Prevention website</a>.
                                </p>
                                }
                                <ReactToPrint
                                    trigger={() => <button className={"btn btn-outline-primary mb-5"} >Print Results</button>}
                                    // @ts-ignore
                                    content={() => this.componentRef}
                                />
                            </div>
                        </div>
                        <div ref={(el => this.componentRef = el)}>
                            {this.getReport(result)}
                            <div className="d-grid mb-3">
                                <p className="d-fles justify-center text-center">
                                    Access all of your results in one convenient location with Streamline Patient Portal.
                                </p>
                                <div className="d-flex justify-center">
                                    <button
                                        style={{backgroundColor: this.state.buttonColorHexValue}}
                                        className="btn btn-lg"
                                        onClick={this.redirectToPortal}>
                                        <img src={this.state.logo}
                                            alt="streamlineLogo"
                                            style={{ "width": "300px", "margin": "10px" }}
                                        />
                                        <p className={"text-light"}>Register Here</p>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </React.Fragment>
            )
    }

    async sendConsent(){
        this.setState({showLoading: true})
        if(!this.state.release  || !this.state.RNum){
            this.setState({showLoading: false})
            return;
        }
        let recaptcha = await load(CAPTCHA);
        let token = await recaptcha.execute('login');

        let result = await SubmissionAPI.release(this.state.release, this.state.RNum, token);
        try{
            if(result.success){
                // sweetalert.fire("Consent saved.")
                this.setState({showLoading: false})
                return
            }  
            if(!result.success)
                sweetalert.fire("Could not save this consent. ")
                this.setState({showLoading: false})
                return
        }catch (e) {
            console.error(e)
            this.setState({showLoading: false})
        }
    }

    async revokeConsent(){
        this.setState({showLoading: true})
        if(!this.state.RNum){
            this.setState({showLoading: false})
            return;
        }
        let recaptcha = await load(CAPTCHA);
        let token = await recaptcha.execute('login');

        let result = await SubmissionAPI.revokeConsent(this.state.RNum, token);
        try{
            if(result.success){ 
                // sweetalert.fire("Consent revoked.")
                this.setState({showLoading: false})
                return
            }
            if(!result.success){
                sweetalert.fire("Could not revoke this consent. ")
                this.setState({showLoading: false})
                return
            }
                
        }catch (e) {
            this.setState({showLoading: false})
            console.log(e)
        }
    
    }

    redirectToPortal = () => {
        sweetalert.fire({
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Continue",
            title: "Attention",
            html: "<p>You will be redirected to Streamline Patient Portal and the Registration Form.</>",
            icon: "info"
        }).then((result) => {
            if (result.isConfirmed) {
                //TODO: After testing, this needs to be a production url
                window.open("https://demo.patient.immytech.com/register", "_blank"); // open in new window
            }
        });
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // console.log('ResultForm state', this.state)
        if (!this.state.hasResult)
            return this.getForm();
        let result = this.state.result;
        return this.getPCRResult(result);
    }

}