import NetworkUtil from "./NetworkUtil";
import TestType from "../types/TestType"
import {ServerResponse} from "../types/ServerResponse";
import RecordType from "../types/RecordType";
import TreatmentRecordType from "../types/TreatmentRecordType";


export default class TreatmentRecordsAPI {
    
    public static async filterRecord(filter):Promise<{success:boolean, data:TreatmentRecordType[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/treatmentrecords/filter", filter);
    }

    public static async queryRecord(body):Promise<{success:boolean, record:RecordType}>{
        return await NetworkUtil.makePost("/api/admin/treatmentrecords/details", body);
    }

    public static async editRecord(body, insuranceInformation, insuranceCards):Promise<ServerResponse>{
      console.log(JSON.stringify(body))
      let data = new FormData()
      data.set('insuranceInformation', JSON.stringify(insuranceInformation))
      data.set('patientInformation', JSON.stringify(body))
      if(insuranceCards){
          for(let i = 0; i < insuranceCards.length; i++){
              let ext = insuranceCards[i].type.split('/').pop();
              data.append('insuranceCards', insuranceCards[i], `${i === 0 ? 'front' : 'back'}.${ext}`)
          }
      }
      for (let [key, value] of data.entries()) {
        console.log(key, value);
      }
      // console.log(data, "DATA")
      return NetworkUtil.postForm("/api/admin/treatmentrecords/edit", data);
    }

    public static async newRecord(body, insuranceInfo, insuranceCards):Promise<ServerResponse>{
      let data = new FormData()
      data.set('insuranceInformation', JSON.stringify(insuranceInfo))
      data.set('patientInformation', JSON.stringify(body))
      if(insuranceCards){
          for(let i = 0; i < insuranceCards.length; i++){
              let ext = insuranceCards[i].type.split('/').pop();
              data.append('insuranceCards', insuranceCards[i], `${i === 0 ? 'front' : 'back'}.${ext}`)
          }
      }
      // for (let [key, value] of data.entries()) {
      //   console.log(key, value);
      // }
      return await NetworkUtil.postForm("/api/admin/treatmentrecords/new", data);
    }

    public static getInsuranceCardImagesFromCloud(data):Promise<any>{
      return NetworkUtil.makePostForBlob("/api/admin/getInsuranceCardImagesFromCloud", data);
    }

}