import React from "react";
import Select from "react-select";
import { sweetalert } from "../../App";
import { calculateAge } from "../../util/ValidationUtil";
import {
    buildRow,
    getLabel,
    getLabelIDsArray,
    getSelectLabels,
    getlabelsFromIDs,
    parseDate,
    showModalNoOutsideClick,
} from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Overlay from "../Overlay";
import { Patient } from "../../types/Patient";
import PhoneInput from 'react-phone-number-input'
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import PatientAPI from "../../network/PatientAPI";
import { AuthContext } from "../../context/AuthContext";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";

interface ProfileManagementState {
    profile: Patient
    showInBetween
    showLoading
    genders
    ethnicities
    races
    states
    countries
    // facilities
}
interface ProfileManagementProps {
}
export class ProfileManagement extends React.Component<ProfileManagementProps, ProfileManagementState>{
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            showInBetween: false,
            showLoading: false,
            profile: {
                ID: null,
                uid: "",
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                dateOfBirth: '',
                streetAddress: '',
                city: '',
                state: '',
                zipcode: '',
                country: '',
                county: '',
                streetAddress2: '',
                customerID: null,
                genderID: null,
                ethnicityID: null,
                raceID: null,
                guardianFirstName: '',
                guardianLastName: '',
                // FacilityIDs: [],
            } as Patient,
            genders: [],
            ethnicities: [],
            races: [],
            states: [],
            countries: [],
            // facilities: [],
        }
        this.modifyPatient = this.modifyPatient.bind(this);
        this.loadDataAndPatientInfo = this.loadDataAndPatientInfo.bind(this);
    }

    componentDidMount(): void {
        document.title = 'Profile Management Page';
        this.loadDataAndPatientInfo()
    }

    loadDataAndPatientInfo() {
        this.setState({ showLoading: true }, () => {
            SystemAPI.getAllRaces().then((data) => {
                this.setState({ races: data });
            });
            SystemAPI.getAllGenders().then((data) => {
                this.setState({ genders: data });
            });
            SystemAPI.getAllEthnicities().then((data) => {
                this.setState({ ethnicities: data });
            });
            SystemAPI.getAllStates().then((data) => {
                this.setState({ states: data });
            });
            SystemAPI.getAllCountries().then((data) => {
                this.setState({ countries: data });
            });
            PatientAPI.getUserProfileByUID(this.context.uid).then(response => {
                let profileData = response.data[0];
                this.setState({
                    profile: {
                        ID: profileData.id,
                        uid: profileData.uid,
                        firstName: profileData.firstName,
                        middleName: profileData.middleName ? profileData.middleName : null,
                        lastName: profileData.lastName,
                        email: profileData.email,
                        phoneNumber: profileData.phoneNumber,
                        dateOfBirth: parseDate(profileData.dateOfBirth),
                        streetAddress: profileData.streetAddress,
                        city: profileData.city,
                        state: profileData.state,
                        zipcode: profileData.zipcode,
                        country: profileData.country && profileData.country === 'United States of America' ? 'United States' : profileData.country ? profileData.country : "",
                        county: profileData.county,
                        streetAddress2: profileData.streetAddress2 ? profileData.streetAddress2 : null,
                        genderID: profileData.genderID,
                        ethnicityID: profileData.ethnicityID,
                        raceID: profileData.raceID,
                        guardianFirstName: profileData.guardianFirstName ? profileData.guardianFirstName : '',
                        guardianLastName: profileData.guardianLastName ? profileData.guardianLastName : '',
                        // FacilityIDs: profileData.FacilityIDs ? getlabelsFromIDs(parsedFacilityIDs, this.state.facilities) : null,
                    } as Patient,
                    showLoading: false
                }); // set state profile
            }); // getUserProfileByID...then
        }); // set state showLoading:true
    }

    handleSubmit() {
        this.setState({ showLoading: true }, () => {
            let tempDate = this.state.profile.dateOfBirth;
            if (typeof tempDate === 'object') {
                tempDate = parseDate(tempDate);
            }

            let patientInfoCopy = JSON.parse(JSON.stringify(this.state.profile));
            patientInfoCopy.firstName = patientInfoCopy.firstName ? patientInfoCopy.firstName.trim() : null;
            patientInfoCopy.middleName = patientInfoCopy.middleName ? patientInfoCopy.middleName.trim() : null;
            patientInfoCopy.lastName = patientInfoCopy.lastName ? patientInfoCopy.lastName.trim() : null;
            patientInfoCopy.email = patientInfoCopy.email ? patientInfoCopy.email.trim() : null;
            patientInfoCopy.dateOfBirth = tempDate ? tempDate : null;
            patientInfoCopy.streetAddress = patientInfoCopy.streetAddress ? patientInfoCopy.streetAddress.trim() : null;
            patientInfoCopy.streetAddress2 = patientInfoCopy.streetAddress2 ? patientInfoCopy.streetAddress2.trim() : null;
            patientInfoCopy.city = patientInfoCopy.city ? patientInfoCopy.city.trim() : null;
            patientInfoCopy.zipcode = patientInfoCopy.zipcode ? patientInfoCopy.zipcode.trim() : null;
            patientInfoCopy.county = patientInfoCopy.county ? patientInfoCopy.county.trim() : null;
            patientInfoCopy.country = patientInfoCopy.country ? patientInfoCopy.country.trim() : null;
            patientInfoCopy.phoneNumber = patientInfoCopy.phoneNumber ? patientInfoCopy.phoneNumber.trim() : null;
            patientInfoCopy.guardianFirstName = patientInfoCopy.guardianFirstName ? patientInfoCopy.guardianFirstName.trim() : '';
            patientInfoCopy.guardianLastName = patientInfoCopy.guardianLastName ? patientInfoCopy.guardianLastName.trim() : '';

            this.setState({ profile: patientInfoCopy });

            //validate patient info
            if (!ProfileManagement.validatePatient(patientInfoCopy)) {
                this.setState({ showLoading: false });
                return;
            }

            this.modifyPatient(this.state.profile);
        })
    }

    public static validatePatient(s): boolean {
        let patientValidation = {
            PatientFirstName: s.firstName,
            PatientMiddleName: s.middleName,
            PatientLastName: s.lastName,
            PatientDOB: s.dateOfBirth,
            PatientEmail: s.email,
            PatientGender: s.genderID,
            PatientAddress: s.streetAddress,
            PatientAddress2: s.streetAddress2,
            PatientCity: s.city,
            PatientZip: s.zipcode,
            PatientState: s.state,
            PatientCounty: s.county,
            PatientCountry: s.country,
            PatientPhone: s.phoneNumber,
            PatientEthnicity: s.ethnicityID,
            PatientRace: s.raceID,
            GuardianFirstName: s.guardianFirstName,
            GuardianLastName: s.guardianLastName,
        }

        let validator = new Validator<any>()
            .withValidation("PatientFirstName", Validators.requireNotBlankAndLength(50, "Patient First Name"));
        if (s.middleName && s.middleName.trim().length > 0) {
            validator = validator.withValidation("PatientMiddleName", Validators.requireNotBlankAndLength(50, "Patient Middle Name"));
        }
        if (calculateAge(s.dateOfBirth) < 18) {
            validator = validator.withValidation("GuardianFirstName", Validators.requireNotBlankAndLength(50, "Guardian First Name"))
                .withValidation("GuardianLastName", Validators.requireNotBlankAndLength(50, "Guardian Last Name"));
        }
        validator = validator.withValidation("PatientLastName", Validators.requireNotBlankAndLength(50, "Patient Last Name"))
            .withValidation("PatientDOB", Validators.requireDOB(150, "Patient Date of Birth"))
            .withComposedValidation("PatientEmail", new ValidationEntry(Validators.requireValidEmail("Patient Email")), Validators.requireNotBlankAndLength(100, "Patient Email"))
            .withSimpleValidation("PatientGender", Validators.requireNonNullValidator("Patient Gender"))
            .withValidation("PatientAddress", Validators.requireNotBlankAndLength(500, "Patient Address"));
        if (s.streetAddress2 && s.streetAddress2.trim().length > 0) {
            validator = validator.withValidation("PatientAddress2", Validators.requireNotBlankAndLength(500, "Street Address cont."));
        }
        validator = validator.withValidation("PatientCity", Validators.requireNotBlankAndLength(100, "Patient City"))
            .withComposedValidation("PatientZip", new ValidationEntry(Validators.requireNotBlankValidator("Patient Zipcode")), new ValidationEntry(Validators.requireZip("Patient Zipcode")))
            .withSimpleValidation("PatientState", Validators.requireNonNullValidator("Patient State"))
            .withValidation("PatientCounty", Validators.requireNotBlankAndLength(100, "Patient County"))
            // .withSimpleValidation("PatientFacilityIDs", Validators.requireNonNullValidator("Patient Facilities"))
            .withSimpleValidation("PatientCountry", Validators.requireNonNullValidator("Patient Country"))
            .withComposedValidation("PatientPhone", new ValidationEntry(Validators.requireNonNullValidator("Patient Phone")), new ValidationEntry(Validators.requirePhone("Patient Phone")))
            .withSimpleValidation("PatientEthnicity", Validators.requireNonNullValidator("Patient Ethnicity"))
            .withSimpleValidation("PatientRace", Validators.requireNonNullValidator("Patient Race"));
        let validationResponse = validator.validate(patientValidation);
        if (!validationResponse.success) {
            sweetalert.fire({ icon: 'error', title: '', text: validationResponse.error });
            return false
        }

        return true;
    }

    modifyPatient(patient: Patient) {
        patient.guardianFirstName = patient.guardianFirstName ? patient.guardianFirstName : null;
        patient.guardianLastName = patient.guardianLastName ? patient.guardianLastName : null;
        // patient.FacilityIDs = patient.FacilityIDs ? getLabelIDsArray(patient.FacilityIDs) : null;

        this.setState({ showLoading: true }, async () => {
            try {
                let result = await PatientAPI.editPatient(patient);
                if (result.success) {
                    sweetalert.fire({ icon: 'success', title: '', text: 'Patient saved' }).then(() => {
                        this.loadDataAndPatientInfo();
                    });
                } else {
                    sweetalert.fire({ icon: 'error', title: '', text: result.reason });
                    this.setState({ showLoading: false });
                }
            }
            catch (e) {
                console.log(e);
                this.setState({ showLoading: false });
            }
        });
    }

    render() {
        return (
            <>
                <Overlay show_loading={this.state.showLoading} zIndex={100003} />
                <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100002} />
                {!this.state.showLoading &&
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 offset-lg-1 col-sm-12 col-md-12 col-lg-10 col-xl-10 pt-2">
                                <main id="main-content" tabIndex={-1} aria-label="Profile Management">
                                    <div className={"card"}>
                                        <div className="card-header">
                                            <h5 className="card-title" id="result_card_label">Profile Management</h5>
                                        </div>
                                        <div className="card-body row">
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            {buildRow("Email",
                                                <input className={"form-control"}
                                                    id="Email"
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"Email"}
                                                    aria-label="Email"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                email: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.email}
                                                />
                                                , 'Email'
                                            )}
                                            {buildRow("First Name",
                                                <input 
                                                    id="FirstName"
                                                    className={"form-control"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"First Name"}
                                                    aria-label="First Name"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                firstName: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.firstName}
                                                />
                                                , 'First Name'
                                            )}
                                            {buildRow("Middle Name",
                                                <input 
                                                    id="MiddleName"
                                                    className={"form-control"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"Middle Name"}
                                                    aria-label="Middle Name"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                middleName: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.middleName}
                                                />
                                                , 'Middle Name'
                                            )}
                                            {buildRow("Last Name",
                                                <input 
                                                    id="LastName"
                                                    className={"form-control"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"Last Name"}
                                                    aria-label="Last Name"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                lastName: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.lastName}
                                                />
                                                , 'Last Name'
                                            )}
                                            <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Patient Date of Birth'}>
                                                <label htmlFor={'PatientDOB'} className="col-12 col-sm-4 col-form-label text-center text-md-left">{'Date of Birth'}</label>
                                                <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'PatientDOB'}>
                                                    <input
                                                        id="PatientDOB"
                                                        min={'1000-01-01'}
                                                        max={'9999-12-31'}
                                                        className={'form-control'}
                                                        autoComplete={'off'}
                                                        type={'date'}
                                                        name={'PatientDOB'}
                                                        aria-label="Date of Birth"
                                                        value={this.state.profile && this.state.profile.dateOfBirth ? new Date(this.state.profile.dateOfBirth).toISOString().split('T')[0] : ''}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const [year, month, day] = inputValue.split('-');
                                                            if (Date.parse(inputValue)) {
                                                                if (year && year.length > 4) {
                                                                    const correctedYear = year.slice(0, 4);
                                                                    const newDateValue = `${correctedYear}-${month}-${day}`;
                                                                    this.setState((prevState) => ({
                                                                        profile: {
                                                                            ...prevState.profile,
                                                                            dateOfBirth: new Date(newDateValue)
                                                                        }
                                                                    }));
                                                                } else {
                                                                    this.setState((prevState) => ({
                                                                        profile: {
                                                                            ...prevState.profile,
                                                                            dateOfBirth: new Date(inputValue)
                                                                        }
                                                                    }));
                                                                }
                                                            }
                                                        }
                                                        }
                                                    >
                                                    </input>
                                                </div>
                                            </div>
                                            {buildRow("Gender",
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                    noOptionsMessage={() => "No option"}
                                                    value={getLabel(this.state.profile.genderID, this.state.genders)}
                                                    aria-label="Gender"
                                                    onChange={(e) => this.setState((prevState) => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            genderID: e.value
                                                        }
                                                    }))}
                                                    className={"state_select"}
                                                    options={this.state.genders}
                                                />
                                                , 'Gender of the patient'
                                            )}
                                            {buildRow("Ethnicity",
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                    noOptionsMessage={() => "No option"}
                                                    value={getLabel(this.state.profile.ethnicityID, this.state.ethnicities)}
                                                    aria-label="Ethnicity"
                                                    onChange={(e) => this.setState((prevState) => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            ethnicityID: e.value
                                                        }
                                                    }))}
                                                    className={"state_select"}
                                                    options={this.state.ethnicities}
                                                />
                                                , 'Ethnicity of the patient'
                                            )}
                                            {buildRow("Race",
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                    noOptionsMessage={() => "No option"}
                                                    value={getLabel(this.state.profile.raceID, this.state.races)}
                                                    aria-label="Race"
                                                    onChange={(e) => this.setState((prevState) => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            raceID: e.value
                                                        }
                                                    }))}
                                                    className={"state_select"}
                                                    options={this.state.races}
                                                />
                                                , 'Race of the patient'
                                            )}
                                            </div>
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            {buildRow("Phone Number",
                                                <PhoneInput
                                                    id={'Phone Number'}
                                                    placeholder="Enter phone number"
                                                    aria-label="Phone Number"
                                                    value={this.state.profile.phoneNumber}
                                                    onChange={(e) => this.setState((prevState) => ({
                                                        profile: { ...prevState.profile, phoneNumber: e }
                                                    }))}
                                                    defaultCountry="US"
                                                />, 'Phone number of the patient'
                                            )}
                                            {buildRow("Street Address",
                                                <input 
                                                    id="StreetAddress"
                                                    className={"form-control"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"Street Address"}
                                                    aria-label="Street Address"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                streetAddress: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.streetAddress}
                                                />
                                                , 'Street Address'
                                            )}
                                            {buildRow("Street Address cont.",
                                                <input 
                                                    id="StreetAddresscont."
                                                    className={"form-control"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"Street Address cont."}
                                                    aria-label="Street Address Continued"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                streetAddress2: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.streetAddress2}
                                                />
                                                , 'Street Address cont.'
                                            )}
                                            {buildRow("City",
                                                <input 
                                                    id="City"
                                                    className={"form-control"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"City"}
                                                    aria-label="City"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                city: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.city}
                                                />
                                                , 'City'
                                            )}
                                            {buildRow("State",
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                    noOptionsMessage={() => "No option"}
                                                    value={getLabel(this.state.profile.state, this.state.states)}
                                                    aria-label="State"
                                                    onChange={(e) => this.setState((prevState) => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            state: e.value
                                                        }
                                                    }))}
                                                    className={"state_select"}
                                                    options={this.state.states}
                                                />, 'State of the patient'
                                            )}
                                            {buildRow("County",
                                                <input 
                                                    id="County"
                                                    className={"form-control"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"County"}
                                                    aria-label="County"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                county: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.county}
                                                />
                                                , 'County'
                                            )}
                                            {buildRow("Zipcode",
                                                <input 
                                                    id="Zipcode"
                                                    className={"form-control"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    type={"search"}
                                                    name={"Zipcode"}
                                                    aria-label="Zipcode"
                                                    onChange={(e) => {
                                                        this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                zipcode: e.target.value
                                                            }
                                                        }))
                                                    }}
                                                    value={this.state.profile.zipcode}
                                                />
                                                , 'Zipcode'
                                            )}
                                            {buildRow("Country",
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                    noOptionsMessage={() => "No option"}
                                                    value={getLabel(this.state.profile.country, this.state.countries)}
                                                    aria-label="Country"
                                                    onChange={(e) => this.setState((prevState) => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            country: e.value
                                                        }
                                                    }))}
                                                    className={"state_select"}
                                                    options={this.state.countries}
                                                />, 'Country of the patient'
                                            )}
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="button" className="btn btn-primary" onClick={() => { this.handleSubmit() }}> Submit </button>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>}
            </>
        )
    }
}