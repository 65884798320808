import React from "react";
import ReleaseConsent from "./modals/ReleaseConsent";
import { sweetalert } from "../App";
import SystemAPI from "../network/SystemAPI";
import { showModalNoOutsideClick } from "../util/FormatUtil";
import Parser from "html-react-parser";
import Overlay from "./Overlay";
import { isBlank, isOnlyWhitespace } from "../util/ValidationUtil";
import InBetweenOverlay from "./InBetweenOverlay";

interface ReleaseFormState {
  release?: any;
  entity?: { ID: number; FacilityID: number; Name: string; AccessCode: string };
  consentEntities: {
    ID: number;
    FacilityID: number;
    Name: string;
    AccessCode: string;
  }[];
  accepted: boolean;
  showLoading:boolean
  showInbetween:boolean
}
interface ReleaseProps {
  consenting?: any;
  releaseChanged: (release: any) => void;
  revokeConsent?
  service?;
  services?
  buttonColorHexValue
}

export default class ReleaseForm extends React.Component<
  ReleaseProps,
  ReleaseFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      consentEntities: [],
      accepted: false,
      showLoading: false,
      showInbetween: false
    };
    this.onConfirmation = this.onConfirmation.bind(this);
  }

  componentDidMount() {
    SystemAPI.getConsentEntities()
      .then((data) => {
        this.setState({ consentEntities: data });
      })
      .catch((e) => {
        console.log(e);
      });

    // display readonly AccessCode if user has already submitted consent
    if (this.props.consenting) {
      this.setState({
        entity: this.props.consenting,
        accepted: true,
        release: this.props.consenting.AccessCode,
      });

    }
  }

  searchForAccessCode() {
    let accessCode = this.state.release;
    let consentEntity = this.state.consentEntities.find(
      (entity) => entity.AccessCode === accessCode
    );
    if (accessCode && accessCode != "" && consentEntity) {
      this.setState({ entity: consentEntity }, () => {
        if (
          this.state.entity &&
          this.state.entity.AccessCode &&
          this.state.entity.AccessCode !== ""
        ) {
          this.setState({showInbetween: true})
          showModalNoOutsideClick(ReleaseConsent.ID)
        }
      });
    }
    if (accessCode && accessCode != "" && !consentEntity) {
      sweetalert.fire({
        icon: "warning",
        text: "Invalid Access Code.",
        title: "",
      }).then(x => this.setState({showLoading: false}));
    }
    if(!accessCode || isBlank(accessCode) || isOnlyWhitespace(accessCode)){
      sweetalert.fire({
          icon: "warning",
          text: "Access Code cannot be blank.",
          title: "",
        }).then(x => this.setState({showLoading: false}));
  }
  }

  onConfirmation(didAccept) {
    if (!didAccept) {
      this.setState({ accepted: false, showInbetween: false });
      Array.from(document.querySelectorAll("input")).forEach((input) => {
        if (input.name == "accessCodeInput") return (input.value = "");
      });
      this.props.releaseChanged("");
    } else {
      this.setState({ accepted: true, showInbetween: false });
      this.props.releaseChanged({entityID: this.state.entity.ID, service: this.props.service.ID});

    }
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {


    return (
      <React.Fragment>
        <ReleaseConsent
          onConfirmation={(didAccept) => this.onConfirmation(didAccept)}
          service={this.props.service}
          entity={
            this.state.entity
              ? this.state.entity
              : { ID: null, FacilityID: null, Name: "", AccessCode: "" }
          }
          fromRelease={true}
        />
        <Overlay show_loading={this.state.showLoading} zIndex={100005} />
        <InBetweenOverlay
          zIndex={100006}
          showInBetween={this.state.showInbetween}
        />
              <div className="col-12 mb-5">
                <div className="card mt-2">
                  <div className="card-header verlag-bold">
                      <h4>Release of Information<span className={"text-secondary"} style={{fontSize: '1rem'}}>{" "}(optional)</span></h4>
                  </div>
                  <div className="card-body pb-0">
                    {this.props.service?.ReleaseOfInformation && Parser(this.props.service.ReleaseOfInformation)}
                    <div className="form-group row pr-3">
                      <label className="col-sm-4 col-form-label">Access Code</label>
                      <div className="col-sm-8  p-0 m-0">
                        <input
                        readOnly={this.state.accepted}
                        type={"search"}
                        name={"accessCodeInput"}
                        min={10}
                        max={10}
                        className={"form-control"}
                        value={this.state.release}
                        autoComplete="off"
                        onChange={(e) => {
                          this.setState({release: e.target.value})
                        }}
                        />
                        {this.state.entity && this.state.accepted && (
                        <div className="alert alert-primary m-0" role="alert">
                            <p className="primary-black m-0">
                            You have authorized {this.state.entity.Name} to access to
                            your test results for this service.
                            </p>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.accepted ? 
                  <div
                    className="card-footer"
                    style={{
                      backgroundColor: "transparent",
                      borderTop: "none",
                    }}
                  >
                    <button
                      className={"btn btn-primary float-right mb-3"}
                      onClick={() => {
                        this.setState({accepted: false, entity: null, release: null});
                        Array.from(document.querySelectorAll("input")).forEach((input) => {
                            if (input.name == "accessCodeInput"){
                                return (input.value = "");
                            } 
                        }); 
                          this.setState({accepted: false})
                          this.props.revokeConsent();
                    }}
                    >
                      Remove
                    </button>
                    {/* <button
                      className={"btn btn-primary float-right mb-3"}
                      onClick={() => {this.props.releaseChanged({entity: this.state.entity, serviceID: this.props.service.ID})}}
                    >
                      Save
                    </button> */}
                  </div>
                  :
                  <div
                    className="card-footer"
                    style={{
                      backgroundColor: "transparent",
                      borderTop: "none",
                    }}
                  >
                    <button
                      className={"btn btn-primary float-right mb-3"}
                      onClick={() => this.searchForAccessCode()}
                    >
                      Search
                    </button>
                  </div>
                  }
              </div>
            </div>

      </React.Fragment>
    );
  }
}
