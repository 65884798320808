import React from "react";
import { CustomerAPI } from "../network/CustomerAPI";
import SystemAPI from "../network/SystemAPI";
let Barcode = require('react-barcode');

export default  class PatientCheckIn extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state={
            code:"",
            logo:""
        }
    }
    componentDidMount(): void {
        let currentURL = window.location.href;
        SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
            this.setState({logo: data.ProductAltLogoURL});
        })
        const query = new URLSearchParams(window.location.search);
        if(query.get('code') && query.get('code') !== "")
            this.setState({code:query.get('code') ? query.get('code') : ""})
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>
            <section className="CheckInWrapper">
            <img className="" width={"425px"} src={this.state.logo} alt="" />
            <Barcode
                value={this.state.code ? this.state.code : ""}
                options={
                    {width:2,
                        height:100,
                        displayValue:true,
                        format:"CODE128"

                    }
                }
            />
            <h4>Upon Arrival at Appointment:</h4>
            <h5>Please show this barcode to the staff at your appointment.</h5>
            </section>

        </React.Fragment>)
    }
}