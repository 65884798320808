import React, {useEffect, useContext} from 'react';
import './App.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ResultsForm from "./components/ResultsForm";
// import PatientForm from "./components/PatientForm";
import AdminPanel from "./components/admin/AdminPanel";
// import Confirmation from "./components/Confirmation";
// import PrivatePod from "./components/PrivatePod";
import Login from "./components/admin/Login"; //FC
// import PatientCheckIn from "./components/PatientCheckIn";
// import {RetrieveConfirmation} from "./components/RetrieveConfirmation";
// import {TestManagement} from "./components/admin/TestManagement";
// import Alert from "./../src/components/admin/Alert";
// import CheckIn from "./components/admin/CheckIn";
import SamplesReport from "./components/admin/SamplesReport";
// import WalkinForm from "./components/admin/WalkinForm";
import {Facility} from "./components/admin/Facility";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import LoggedOut from "./components/admin/LoggedOut";
// import RapidTestManagement from "./components/admin/RapidTestManagement";
// // import PodStats from "./components/admin/PodStats";
// import Schedules from "./components/admin/Schedules";
// import {AuditLogs} from "./components/admin/AuditLogs";
import {UserManagement} from "./components/admin/UserManagement";
// import {ServicesManagement} from "./components/admin/ServicesManagement";
// import {ResultsManagement} from "./components/admin/ResultsManagement";
import {CommunicationTemplates} from "./components/admin/CommunicationTemplates";
// import AdminHomePage from "./components/admin/AdminHomePage";
import AdminLanding from "./components/admin/AdminLanding";
// import {CustomFields} from "./components/admin/CustomFields";
// import { ReportSettings } from './components/admin/ReportSettings';
import { AuthContext } from './context/AuthContext';
import ForgotPassword from './components/admin/ForgotPassword';
import Home from './components/Home';
import SystemAPI from './network/SystemAPI';
import { ProfileManagement } from './components/admin/ProfileManagement';
import { Tasks } from './components/admin/Tasks';
import PrivatePod from './components/PrivatePod';
import PatientForm from './components/PatientForm';
import Confirmation from './components/Confirmation';
import PatientCheckIn from './components/PatientCheckIn';
import { RetrieveConfirmation } from './components/RetrieveConfirmation';
import { VaccineRecords } from './components/admin/VaccineRecords';
import { TreatmentRecords } from './components/admin/TreatmentRecords';
import { FamilyGroup } from './components/admin/FamilyGroup';
import PasswordReset from './components/admin/PasswordReset';
import SkipLink from './components/admin/SkipLink';
// import { ProfileManagement } from './components/admin/ProfileManagement';
// import { PasswordSettings } from './components/admin/PasswordSettings';
// import { FakeSampleGenerator } from './components/admin/FakeSampleGenerator';

const swal = withReactContent(Swal);

export const CAPTCHA = "6Lc4JUAcAAAAAGPcDlen-26x_KpyZUjY7acuP4Al";

//setting defaults for sweetalert
export let sweetalert = swal.mixin({
    showCloseButton:false, // hide the 'x' at the top of the modal
    confirmButtonColor: '#21212E',      // dark blue
    cancelButtonText: 'Cancel',
    cancelButtonColor: '#808080',       // grey 
    denyButtonColor: '#dc3741',         // standard red
    allowOutsideClick: false,           // enforce user interact with modal buttons
    allowEscapeKey: false,              // enforce user interact with modal buttons
    reverseButtons: true,               // cancel left, confirm right
    showClass: {
        backdrop: 'swal2-noanimation',  // disable backdrop animation
        popup: '',                      // disable popup animation
        icon: '',                       // disable icon animation
    },
    hideClass: {
        popup: '',                     // disable popup fade-out animation
    },
    customClass: {
        container: 'swal-on-top swal2-html-container'
    }
});

function App(){
    const userContext = useContext(AuthContext);

    useEffect(()=>{
        let currentURL = window.location.href;
        let title;
        const getProductBranding = async () => {
            let data = await SystemAPI.getProductBrandingFromURL(currentURL)
            title = data.Name === 'Logo' ? 'IMMYLabs' : data.Name;
            document.title = title
        }

        getProductBranding()

        
    },[]);// runs on load


    const getNavRoute = (comp:any)=>{
        return(
            <React.Fragment>
                <SkipLink targetId="main-content" label="Skip to Main Content" />
                <Nav />
                {comp}
                <Footer/>
            </React.Fragment>
        )
    }

    const getAdminNavRoute = (comp:any, props)=>{
        document.body.style.backgroundColor = 'white'
        document.body.style.backgroundImage = 'none'
        return (
            <> 
            { userContext && 
                <React.Fragment>
                    <SkipLink targetId="main-content" label="Skip to Main Content" />
                    <AdminPanel {...props} />
                    {comp}
                </React.Fragment>
            }
            </>
        )
    }

    const getLoginRoute = (comp:any)=>{
        return (
            <React.Fragment>
                {comp}
            </React.Fragment>
        )
    }
    return (
        <BrowserRouter>
            <React.Fragment>
                <Switch>
                    <Route path="/" render={(props) => getNavRoute(<Home />)} exact />
{/* PUBLIC PAGES */}
                    <Route path="/private" render={(props) => getNavRoute(<PrivatePod />)} exact />
                    <Route path="/appointment" render={(props) => userContext ?
                        <Redirect to={'/admin/AppointmentForm'} /> : 
                        getNavRoute(<PatientForm />)} 
                        exact />
                    <Route path="/results" render={(props) => getNavRoute(<ResultsForm />)} exact />
{/* ADMIN PAGES */}
                    <Route path="/admin" render={(props)=> getAdminNavRoute(<AdminLanding />, props)} exact /> 
                    {/* <Route path="/admin/CheckIn" render={(props) => getAdminNavRoute(<CheckIn />, props)} exact /> */}
                    <Route path="/admin/AppointmentForm" render={(props) => getAdminNavRoute(<PatientForm />, props)} exact />
                    {/* <Route path="/admin/RapidTestManagement" render={(props) => getAdminNavRoute(<RapidTestManagement />, props)} exact /> */}
                    {/* <Route path="/admin/Schedules" render={(props) => getAdminNavRoute(<PodStats />)} exact /> */}
                    {/* <Route path="/admin/Schedules" render={(props) => getAdminNavRoute(<Schedules />, props)} exact /> */}
                    {/* <Route path="/admin/Alert" render={(props) => getAdminNavRoute(<Alert />, props)} exact /> */}
                    <Route path="/admin/VaccineRecords" render={(props) => getAdminNavRoute(<VaccineRecords />, props)} exact />
                    <Route path="/admin/TreatmentRecords" render={(props) => getAdminNavRoute(<TreatmentRecords />, props)} exact />

                    <Route path="/admin/Tests" render={(props) => getAdminNavRoute(<SamplesReport />, props)} exact />
                    {/* <Route path="/admin/InternalSampleGenerator" render={(props) => getAdminNavRoute(<FakeSampleGenerator />, props)} exact /> */}
                    {/* <Route path="/admin/AuditLogs" render={(props) => getAdminNavRoute(<AuditLogs />, props)} exact /> */}
                    {/* <Route path="/admin/FacilityManagement" render={(props) => getAdminNavRoute(<Facility />, props)} exact /> */}
                    {/* <Route path="/admin/UserManagement" render={(props) => getAdminNavRoute(<UserManagement />, props)} exact /> */}
                    <Route path="/admin/ProfileManagement" render={(props) => getAdminNavRoute(<ProfileManagement />, props)} exact />
                    <Route path="/admin/Tasks" render={(props) => getAdminNavRoute(<Tasks />, props)} exact />
                    <Route path="/admin/FamilyGroup" render={(props) => getAdminNavRoute(<FamilyGroup />, props)} exact />
                    <Route path="/admin/PasswordReset" render={(props) => getAdminNavRoute(<PasswordReset />, props)} exact />
                    {/*<Route path="/admin/TestManagement" render={(props) => getAdminNavRoute(<TestManagement />, props)} exact />
                    <Route path="/admin/ServicesManagement" render={(props) => getAdminNavRoute(<ServicesManagement />, props)} exact />
                    <Route path="/admin/CustomFields" render={(props) =>  getAdminNavRoute(<CustomFields/>, props)} exact/>
                    <Route path="/admin/ResultsManagement" render={(props) =>  getAdminNavRoute(<ResultsManagement/>, props)} exact/> */}
                    {/* <Route path="/admin/CommunicationTemplates" render={(props) => getAdminNavRoute(<CommunicationTemplates />, props)} exact /> */}
                    {/* <Route path="/admin/ReportSettings" render={(props) => getAdminNavRoute(<ReportSettings />, props)} exact />
                    <Route path="/admin/PasswordRequirements" render={(props)=> getAdminNavRoute(<PasswordSettings />, props)} exact /> */}
                    <Route path="/admin/confirmation" render={(props) => getAdminNavRoute(<Confirmation />,props)} exact />
                    <Route path="/confirmation" render={(props) => getNavRoute(<Confirmation />)} exact />
                    <Route path="/checkin" render={(props) => <PatientCheckIn />} exact />
                    <Route path="/retrieve" render={(props) => getNavRoute(<RetrieveConfirmation />)} exact />
{/* AUTH PAGES */}
                    <Route path="/logout" render={(props)=> getNavRoute(<LoggedOut /> )} exact />
                    <Route path="/loggedout" render={(props) => getNavRoute(<LoggedOut />)} exact />
                    <Route path="/login" render={(props)=> getLoginRoute(<Login form_action={'login'} />) } exact />
                    <Route path="/register" render={(props) => getLoginRoute(<Login form_action={'register'}/>)} exact />
                    <Route path="/forgotPassword" render={(props) => getLoginRoute(<ForgotPassword />)} exact/>
                    <Route path="/__/auth/:action" render={(props)=> getLoginRoute(<Login form_action={props.match.params.action} />)} />
{/* REDIRECT */}
                    <Redirect to={"/admin"} />
                </Switch>
            </React.Fragment>
        </BrowserRouter>);
}

export default App;
