import React from "react";
import {
  hideModal,
} from "../../util/FormatUtil";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { FaCameraRetro } from "react-icons/fa";
import BarcodeScannerComponent from "../BarcodeScannerComponent";
import FilterCard, { FilterPart } from "../FilterCard";
import domain from "../../Domain";
import ReleaseConsent from "../modals/ReleaseConsent";
import SystemAPI from "../../network/SystemAPI";
import Parser from "html-react-parser";

interface ImportantInformationModalProps {
  services?;
  selectedServices?
  setAccepted?
}

interface ImportantInformationModalState {
  accepted: boolean;
  arrowClicked?

}

export class ImportantInformationModal extends React.Component<ImportantInformationModalProps, ImportantInformationModalState> {
  public static ID = "importantInformationModal";
  constructor(props) {
    super(props);
    this.state = {
      accepted: false,
      arrowClicked: -1
    };
  }

 
  render() {
    return (
      <React.Fragment>
        <div
          className="modal fade form_modal"
          id={ImportantInformationModal.ID}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="result_modal_label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-12 pt-2">
                      <div className="card mb-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4>
                                Important Information
                              </h4>
                            </div>
                            <div className="col-2">
                              <button style={{outline: 'none'}} type="button" className="close pr-4"
                                  aria-label="Close" onClick={() => hideModal(ImportantInformationModal.ID)}>
                                  <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                            <div className="accordion" id="importantInformation">
                            {this.props.selectedServices && this.props.selectedServices.map((ss, i) => (
                                    <div className="card mt-2">
                                        
                                                <button
                                                    className={"card-header collapsed accordionButton w-100 verlag-bold"}
                                                    data-toggle="collapse"
                                                    data-target={'#collapse'+i}
                                                    id={'heading'+i}
                                                    onClick={() =>{
                                                        if(this.state.arrowClicked === i){
                                                            this.setState({
                                                                arrowClicked: -1
                                                            })
                                                        }
                                                        else{
                                                            this.setState({
                                                                arrowClicked: i
                                                            })
                                                        }
                                                    
                                                    }}
                                                    aria-expanded={"false"}
                                                    aria-controls={'collapse'+i}
                                                >
                                                  <div className="row d-flex justify-content-between">
                                                    <div className="px-3">
                                                      <h4>
                                                        {ss.Name}
                                                      </h4>
                                                    </div>
                                                    <div className="col-2">
                                                      {this.state.arrowClicked == i ? (
                                                          <AiOutlineUp
                                                          className={"float-right"}
                                                          size={30}
                                                          data-toggle="collapse"
                                                          data-target={'#collapse'+i}
                                                          role="button"
                                                          aria-expanded={i == 0 ? "true" : "false"}
                                                          aria-controls={'collapse'+i}
                                                          />
                                                      ) : (
                                                          <AiOutlineDown
                                                          className={"float-right"}
                                                          size={30}
                                                          data-toggle="collapse"
                                                          data-target={'#collapse'+i}
                                                          role="button"
                                                          aria-expanded={i == 0 ? "true" : "false"}
                                                          aria-controls={'collapse'+i}
                                                          />
                                                      )}
                                                    </div>
                                                  </div>
                                                </button>
                                        

                                        <div
                                        className={"collapse show verlag-light responsive-p"} style={{borderBottom: '1px solid lightgrey'}}
                                            id={'collapse'+i} aria-labelledby={'heading'+i} data-parent="#importantInformation"
                                        >
                                            <div className="card-body">
                                                {ss.ImportantInformation && Parser(ss.ImportantInformation)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div
                    className="card-footer"
                    style={{
                      backgroundColor: "transparent",
                      borderTop: "none",
                    }}
                  >
                    <button
                      className={"btn btn-primary float-right mb-3"}
                      onClick={() => {this.props.setAccepted(); hideModal(ImportantInformationModal.ID)}}
                    >
                      I have read the above information
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
    );
  }
}
