import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getBoolSelectVal,
  getNumPages,
  getPageOptions,
  hideModal,
  parseDate,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import { Result } from "../../types/Result";
import { TasksModal } from "../modals/TasksModal";
import NetworkUtil from "../../network/NetworkUtil";
import ResultsAPI from "../../network/ResultsAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import SystemAPI from "../../network/SystemAPI";
import { AdminPages } from "./AdminNav";
import { FakeTasks } from "../../types/FakeTasks";

const ITEMS_PER_PAGE = 25;

interface TasksState {
  showLoading: boolean;
  allTasks
  prioritiesArray
  selectedTask;
  taskQueryResults;
  tableData: {}[];
  filter?: {
    Title;
    Priority;
  };
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  complete
//   resultsList: { label; value }[];
//   descList: { label; value }[];
//   testsList: { label; value }[];

}

export class Tasks extends React.Component<
  any,
  TasksState
> {
  
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      allTasks: [],
      prioritiesArray:  [{label: "High", value: "High"}, {label: "Medium", value: "Medium"}, {label: "Low", value: "Low"}, {label: "None", value: "None"}],
      selectedTask: null,
      tableData: [] as any,
      taskQueryResults: [] as any,
      filter: { Title: null, Priority: null},
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
      complete: []
    //   resultsList: [],
    //   descList: [],
    //   testsList: [],
    };
  }

  componentDidMount() {
    document.title = 'Tasks Page';
    this.queryTasksData(1)
  }

  queryTasksData(page: number) {
    this.setState({showLoading: true})
    let fakeTasks = FakeTasks;
    let temp = [];

    console.log('filter', this.state.filter)
    if(this.state.filter && this.state.filter.Title && this.state.filter.Title.length > 0){
        fakeTasks = fakeTasks.filter(f => f.Title === this.state.filter.Title)
    }
    if(this.state.filter && this.state.filter.Priority && this.state.filter.Priority.length > 0){
        fakeTasks = fakeTasks.filter(f => f.Priority === this.state.filter.Priority)
    }

    if(!fakeTasks || fakeTasks.length < 1){
        this.setState({tableData: null, showLoading: false})
        return sweetalert.fire({icon: 'error', title: '', text: 'No tasks returned'})
    }


    fakeTasks.map(f=> {
        if(f.CompletedDate){
            temp.push(f.ID)
        }
    })
    setTimeout(() => {
        this.setState({ showLoading: false, allTasks: FakeTasks, tableData: fakeTasks, complete: temp})
    }, 2000);
  }


  renderTasksFilterFields() {
    return (
      <FilterCard
        fields={[
          {
            label: "Title",
            key: "Title",
            type: FIELD_TYPE.SELECT,
            options: this.state.allTasks.map(t => {return {label: t.Title, value: t.Title}}),
            isMapped: true,
            textType: "text",
            isClearable: true
          },
          {
            label: "Priority",
            key: "Priority",
            type: FIELD_TYPE.SELECT,
            options: this.state.prioritiesArray,
            isMapped: true,
            textType: "text",
            isClearable: true
          },
        ]}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  onSubmit(e){
    this.setState({showLoading: true})
    console.log('onSubmit', e)
    let tempCompleted = JSON.parse(JSON.stringify(this.state.complete))
    tempCompleted.push(e.ID)

    const updatedTasks = [...this.state.tableData];

    console.log('updatedTasks', updatedTasks)

    const formattedDate = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    });

    for (let i = 0; i < updatedTasks.length; i++) {
            //@ts-ignore
            if(e.ID === updatedTasks[i].ID){
                //@ts-ignore
                updatedTasks[i].CompletedDate = formattedDate;
            }
    }

    this.setState({complete: tempCompleted, tableData: updatedTasks})
    hideModal(TasksModal.ID)
    sweetalert.fire({icon: 'success', title: '', text: 'Task Updated!'})
    setTimeout(() => {
        this.setState({showLoading: false})
    }, 2000);
  }

  render() {

    console.log('Tasks state', this.state)

    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <TasksModal
          key={this.state.selectedTask && this.state.selectedTask.ID ? this.state.selectedTask.ID : null}
          selectedTask={this.state.selectedTask}
          onSubmit={(e) => this.onSubmit(e)}
        />

        <div className="container-fluid ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Tasks">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h3>Tasks</h3>
                  </div>
                  <div className="card-body">
                    {this.renderTasksFilterFields()}
                  </div>
                  <div className="card-footer">
                    <button
                      className={"btn immySubmitButtonOutline"}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryTasksData(
                              this.state.selected_page.value
                            );
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </main>
            </div>
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2" style={{marginBottom: '4rem'}}>
                  <div className="card-header verlag-bold stickToTop">
                    <h4 className="text-center text-md-left">
                      Tasks
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                          Total: {this.state.allTasks.length}
                        </h4>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            aria-label="Table Page Number"
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e })
                            }
                            className={"state_select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      columns={[
                        {
                          label: "Title",
                          key: "Title",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#"}
                                className={'tableNameLinkColor'}
                                onClick={() => {
                                  this.setState({ selectedTask: val }, () =>
                                    showModalNoOutsideClick(
                                      TasksModal.ID
                                    )
                                  );
                                }}
                              >
                                {val.Title}
                              </a>
                            );
                          },
                        },
                        {
                          label: "Description",
                          key: "Description",
                        },
                        {
                          label: "Priority",
                          key: "Priority",
                          rawFormat: (val) => {
                            let string = this.state.prioritiesArray && this.state.prioritiesArray.length > 0 ?this.state.prioritiesArray.find(p => p.value === val.Priority) : null;
                            return <p>{string && string.label ? string.label : ''}</p>;
                          },
                        },
                        {
                          label: "Due Date",
                          key: "DueDate",
                          rawFormat: (val) => {
                            let string = val && val.DueDate ? val.DueDate.split(/[ ,]/)[0] : '';
                            return <p>{string}</p>;
                          },
                        },
                        {
                          label: "Completed Date",
                          key: "CompletedDate",
                          rawFormat: (val) => {
                            let string = val && val.CompletedDate ? val.CompletedDate.split(/[ ,]/)[0] : '';
                            return <p>{string}</p>;
                          },
                        },                      
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}